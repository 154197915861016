import styled from "styled-components/macro";

export default styled.div`
  .footer {
    background-color: var(--blue_1);
    padding: 32px 83px 24px 83px;
    column-gap: 178px;
    row-gap: 17px;
    margin-top: 138px;
    @media only screen and (max-width: ${({ styleProps }) =>
        styleProps?.screenSize?.mobile}) {
      padding: 32px 36px 24px 36px;
    }
  }

  .footer-logo {
    img {
      width: 126px;
    }
  }

  .footer-container {
    color: var(--white_1);
    text-align: left;
    font-weight: 400;
    p {
      line-height: var(--line_height_24px);
    }
    a {
      color: var(--white_1);
    }
  }
`;
