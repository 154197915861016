import React, { useEffect } from "react";
import Styled from "./styles";
import * as actions from "../../../redux/actions";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ScrollToTop from "../../Common/HOC/ScrollToTop";
import SubPage from "../../Common/SubPage";
import { getHelpFileData } from "../../../utils/ModuleActions";
import {
  convertTagsToJSX,
  handleSubmitRedirect,
  addBtnName,
} from "../../../utils/Utils";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton } from "@mui/material";

const OnlineHelpFile = () => {
  const { data: styleProps } = useSelector(
    ({ getStyleProps }) => getStyleProps
  );
  const { data: helpFileData } = useSelector(
    ({ onlineHelpFile }) => onlineHelpFile
  );
  const { data: navigationList } = useSelector(({ navigate }) => navigate);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    dispatch(actions.commonAction(getHelpFileData));
  }, [dispatch]);

  /**
   * This function is called when the user tries to go to the previous or next page with the arrow buttons.
   * This function uses the name passed in and appends it to the 'navObject' to make 'handleRedirect()' work generically.
   * Note: This is different than handleSave for other pages because e.target.name was always undefined and e.target.id was incosistent.
   * User had to click on the exact element in order for the correct id to be received.
   * @param {*} name Contains the name of the button clicked
   */
  const handleArrowBtns = (name) => {
    // addBtnName() not really needed but good to have this check anyway
    let navObject = { target: { name: name } };
    navObject = addBtnName(navObject, navigationList, location);

    // Do not need to submit values but we are calling a submit function since the functionality is identical (reusability)
    handleSubmitRedirect(navObject, navigate);
  };

  /* ********** FUNCTION COMPONENT RETURN ********** */
  if (!styleProps || !helpFileData) return null; // Needed so app does not crash on first render
  return (
    <Styled styleProps={styleProps} className="help-file-bg">
      <SubPage>
        <div id="main" className="space-between-text">
          {helpFileData?.map((page) => {
            if (page?.helpPath === location?.pathname) {
              const pageName = location?.pathname.split("/")[2];
              return (
                <div id={`${pageName}-help-file-container`} key={page?.id}>
                  <div id={`${pageName}-help-file-header`}>
                    {convertTagsToJSX(page?.title)}
                  </div>
                  <div id={`${pageName}-help-file-body`}>
                    {page?.content?.map((section, i) =>
                      convertTagsToJSX(section, i + 1)
                    )}
                  </div>
                  {pageName === "welcome" ? null : (
                    <div id="help-file-save-btns-final">
                    <img src="/help-file-save-btns-final.png" 
                    alt="<ul> 
                    <li>Save and Go Back: Saves your progress and brings you to the previous screen
                    <li>Save and Exit: Saves your progress and logs you out
                    <li>Save and Continue: Saves your progress and brings you to the next screen</li>
                    </ul>"
                    />
                    </div>
                  )}
                  <div id="page-nav-arrows">
                    <IconButton
                      id="help-file-back-btn"
                      className={`${
                        pageName === "welcome"
                          ? "disable-button"
                          : "enable-button"
                      }`}
                      onClick={(e) => handleArrowBtns("help_file_back_btn")}
                      disabled={pageName === "welcome" ? true : false}
                      component="label"
                      aria-label="Go Back Button"
                    >
                      <ArrowBackIcon />
                    </IconButton>
                    <IconButton
                      id="help-file-forward-btn"
                      className={`${
                        pageName === "statement"
                          ? "disable-button"
                          : "enable-button"
                      }`}
                      onClick={(e) => handleArrowBtns("help_file_forward_btn")}
                      disabled={pageName === "statement" ? true : false}
                      component="label"
                      aria-label="Go Forward Button"
                    >
                      <ArrowForwardIcon />
                    </IconButton>
                  </div>
                </div>
              );
            }
            return null;
          })}
        </div>
      </SubPage>
    </Styled>
  );
};

export default ScrollToTop(OnlineHelpFile);
