import React, { useState, useEffect } from "react";
import Styled from "./styles";
import { useSelector } from "react-redux";
import { Grid, TextField } from "@mui/material";
import { handleObjectChange, getRegex } from "../../../../utils/Utils";

const ContactInformation = ({
  contactInfoData,
  onContactInfoChange,
  isFieldEmptySubmit,
}) => {
  const [contactInfo, setContactInfo] = useState({
    phoneNumber: "",
    faxnumber: "",
    emailaddress: "",
  });
  const [errorMsg, setErrorMsg] = useState({
    phoneNumber: "",
    email: "",
    faxNumber: "",
  });

  const { data: styleProps } = useSelector(
    ({ getStyleProps }) => getStyleProps
  );

  useEffect(() => {
    if (contactInfo) {
      const phonePattern = getRegex("phoneNumber");
      const emailPattern = getRegex("email");
      let updatedContactInfo = { ...contactInfo };

      // The reason the boolean logic for the following error messages look confusing is because the logic is trying to do the expected behavior while also preventing re-renders
      // The boolean logic has only been split into variables for the required fields to make reading the code easier. So fax number does not need this since it is simpler.

      /* SETTING ERROR MESSAGES */

      // When there is no match found (invalid entry) and the number is at least one char. We do not want an error to display when it is an empty string, for the most part
      let phoneExp1 =
        !phonePattern.test(contactInfo?.phoneNumber) &&
        contactInfo?.phoneNumber;

      // When there is no match found (invalid entry) and the phone number is an empty string upon clicking submit
      let phoneExp2 =
        !phonePattern.test(contactInfo?.phoneNumber) &&
        isFieldEmptySubmit?.phoneNumber;

      // When there is no match found (invalid entry) and the email is at least one char. We do not want an error to display when it is an empty string, for the most part
      let emailExp1 =
        !emailPattern.test(contactInfo?.emailaddress) &&
        contactInfo?.emailaddress;

      // When there is no match found (invalid entry) and the email is an empty string upon clicking submit
      let emailExp2 =
        !emailPattern.test(contactInfo?.emailaddress) &&
        isFieldEmptySubmit?.email;

      // Both expressions need to check if the errorMsg for phone number has already been set to avoid infinite loop
      if ((phoneExp1 || phoneExp2) && errorMsg?.phoneNumber === "") {
        // for consitensy, change it to !errorMsg?.phoneNumber ?
        handleObjectChange(
          "You must enter a valid 10 digit phone number",
          "phoneNumber",
          errorMsg,
          setErrorMsg
        );
      }

      // Both expressions need to check if the errorMsg for email has already been set to avoid infinite loop
      if ((emailExp1 || emailExp2) && errorMsg?.email === "") {
        handleObjectChange(
          "You must enter a valid email address",
          "email",
          errorMsg,
          setErrorMsg
        );
      }

      if (
        !phonePattern.test(contactInfo?.faxnumber) &&
        contactInfo?.faxnumber &&
        errorMsg?.faxNumber === ""
      ) {
        handleObjectChange(
          "You must enter a valid 10 digit fax number",
          "faxNumber",
          errorMsg,
          setErrorMsg
        );
      }

      /* REMOVING ERROR MESSAGES */

      // When there is a match or when the phone number string is empty
      let phoneExp3 =
        phonePattern.test(contactInfo?.phoneNumber) ||
        !contactInfo?.phoneNumber;

      // When there is a match and the phone number field is empty on submit. Uused when the user tries submitting the field empty and then types a valid phone number.
      // The second part is needed since this var from the parent still says true, even after typing a valid phone number, in this sequence of events)
      let phoneExp4 =
        phonePattern.test(contactInfo?.phoneNumber) &&
        isFieldEmptySubmit?.phoneNumber;

      // When there is a match or when the email string is empty
      let emailExp3 =
        emailPattern.test(contactInfo?.emailaddress) ||
        !contactInfo?.emailaddress;

      // When there is a match and the phone number field is empty on submit. Uused when the user tries submitting the field empty and then types a valid phone number.
      // The second part is needed since this var from the parent still says true, even after typing a valid phone number, in this sequence of events)
      let emailExp4 =
        emailPattern.test(contactInfo?.emailaddress) &&
        isFieldEmptySubmit?.email;

      // Both expressions need to check if the errorMsg for phone number has already been cleared to avoid infinite loop
      if (
        ((phoneExp3 && !isFieldEmptySubmit?.phoneNumber) || phoneExp4) &&
        errorMsg?.phoneNumber !== ""
      ) {
        setErrorMsg({
          ...errorMsg,
          phoneNumber: "",
        });
      }

      // Both expressions need to check if the errorMsg for email has already been cleared to avoid infinite loop
      if (
        ((emailExp3 && !isFieldEmptySubmit?.email) || emailExp4) &&
        errorMsg?.email !== ""
      ) {
        setErrorMsg({
          ...errorMsg,
          email: "",
        });
      }

      if (
        (phonePattern.test(contactInfo?.faxnumber) ||
          !contactInfo?.faxnumber) &&
        errorMsg?.faxNumber !== ""
      ) {
        setErrorMsg({ ...errorMsg, faxNumber: "" });
      }

      /* 
      SETTING TEMP VAR FOR PARENT TO RECEIVE
      
      Need this section as it is because if we try putting the logic above, the var contactInfoData will contain the invalid value, meaning the parent will end up saving it.
      The reason for this logic is to ensure that all valid formats are submited. So if phone number is invalid but email and fax are valid, we should submit the valid ones
      and resubmit the initial value for the invalid field(s)

      Second part of condition needed so that if phone number or email are empty strings, they can be sent to the parent as empty strings and then isFieldEmptySubmit can be triggered.
      Without this, we would override the empty string to the initial value of the field(s) since the "test()" method considers an empty string as no match. We only want to override 
      with invalid formats greater than 0 chars. contactInfo?.phoneNumber means we update the parent only when the string is greater than 0 chars. Only needed for phone number and email 
      since they are required. Fax number does not need this part of the condition.
      */

      // further validation will be done in backend. So no need to revert to older value if invalid email or phone

      /*if (
        !phonePattern.test(contactInfo?.phoneNumber) &&
        contactInfo?.phoneNumber
      ) {
        updatedContactInfo = {
          ...updatedContactInfo,
          phoneNumber: contactInfoData?.phoneNumber,
        };
      }

      if (
        !emailPattern.test(contactInfo?.emailaddress) &&
        contactInfo?.emailaddress
      ) {
        updatedContactInfo = {
          ...updatedContactInfo,
          emailaddress: contactInfoData?.emailaddress,
        };
      }*/

      if (!phonePattern.test(contactInfo?.faxnumber)) {
        updatedContactInfo = {
          ...updatedContactInfo,
          faxnumber: contactInfoData?.faxnumber,
        };
      }

      onContactInfoChange(updatedContactInfo);
    }
  }, [
    contactInfo,
    onContactInfoChange,
    errorMsg,
    isFieldEmptySubmit?.phoneNumber,
    isFieldEmptySubmit?.email,
    contactInfoData?.phoneNumber,
    contactInfoData?.emailaddress,
    contactInfoData?.faxnumber,
  ]);

  useEffect(() => {
    // Triggered when this changes and if it is defined
    if (contactInfoData) {
      setContactInfo(contactInfoData);
    }
  }, [contactInfoData]);

  return (
    <Styled styleProps={styleProps}>
      <div id="contact-information-container">
        <div id="contact-information-header">
          <h4>Contact information</h4>
          <p>
            This information is for contact purposes and will not be made
            available to the public. Any change will be effective immediately.
          </p>
        </div>
        <Grid
          id="contact-information-body"
          className="sm-space-between-containers"
          container
          columnSpacing={3}
          rowSpacing={0}
        >
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              id="phone-number"
              label="Phone Number"
              placeholder="Enter phone number including area code"
              value={contactInfo?.phoneNumber}
              error={errorMsg?.phoneNumber ? true : false}
              helperText={errorMsg?.phoneNumber}
              InputLabelProps={{ shrink: true }}
              onChange={(e) =>
                handleObjectChange(
                  e.target.value,
                  "phoneNumber",
                  contactInfo,
                  setContactInfo
                )
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="fax-number"
              label="Fax Number"
              placeholder="Enter phone number including area code"
              value={contactInfo?.faxnumber}
              error={errorMsg?.faxNumber ? true : false}
              helperText={errorMsg?.faxNumber}
              InputLabelProps={{ shrink: true }}
              onChange={(e) =>
                handleObjectChange(
                  e.target.value,
                  "faxnumber",
                  contactInfo,
                  setContactInfo
                )
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              id="email"
              label="Email"
              placeholder="Enter email address"
              value={contactInfo?.emailaddress}
              error={errorMsg?.email ? true : false}
              helperText={errorMsg?.email}
              InputLabelProps={{ shrink: true }}
              onChange={(e) =>
                handleObjectChange(
                  e.target.value,
                  "emailaddress",
                  contactInfo,
                  setContactInfo
                )
              }
            />
          </Grid>
        </Grid>
      </div>
    </Styled>
  );
};

export default ContactInformation;
