import { useState, useEffect } from "react";
import Styled from "./styles";
import TextField from "@mui/material/TextField";
import { Autocomplete, Grid } from "@mui/material";
import { handleDropdownList } from "../../../../utils/Utils";
import CustomPopper from "../../../Common/CustomPopper";
import { debounce } from "lodash";
import { searchHospital } from "../../../../utils/ModuleActions";
import * as actions from "../../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";

export default function Hospital({
  fieldNumber,
  onHospitalChange,
  hospitalData = undefined,
}) {
  const [hospital, setHospital] = useState({
    hospitalName: null,
    hospitalCode: null,
    fieldNumber: fieldNumber,
  });
  const dispatch = useDispatch();
  const { data: foundHospitals } = useSelector(
    ({ searchHospital }) => searchHospital
  );
  const { data: servedIndc } = useSelector(
    ({ updatedIndicator }) => updatedIndicator
  );
  const [hospitalOptions, setHospitalOptions] = useState([]);

  /**
   * This function is used to load existed form data in the form
   */
  const setHospitalData = () => {
    if (hospitalData?.hospitalCode) {
      setHospitalOptions([
        {
          hospitalCode: hospitalData?.hospitalCode,
          hospitalName: hospitalData?.hospitalName,
        },
      ]);
      setHospital({
        ...hospital,
        hospitalName: hospitalData?.hospitalName,
        hospitalCode: hospitalData?.hospitalCode,
        fieldNumber: fieldNumber,
      });
    }
  };

  useEffect(() => {
    if (hospitalData) setHospitalData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hospitalData]);

  useEffect(() => {
    if (hospital) onHospitalChange(hospital);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hospital]);

  useEffect(() => {
    if (foundHospitals)
      setHospitalOptions([
        ...hospitalOptions,
        ...foundHospitals?.data?.admitHospitals,
      ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [foundHospitals]);

  /**
   * This function is used to search the hospital by name
   * @param {*} e
   */
  const searchHospitalFs = (e) => {
    setHospitalOptions([]);
    dispatch(actions.commonAction(searchHospital, { q: e?.target?.value }));
  };
  const callHospitalDebounced = debounce(searchHospitalFs, 500);

  return (
    <Styled>
      <div id={`hospital-item-${fieldNumber}`} className="no-divider-item">
        <div
          id={`hospital-item-header-${fieldNumber}`}
          className="space-between-text"
        >
          <h4>Hospital Name Item {fieldNumber}</h4>
        </div>
        <Grid
          id={`hospital-item-body-${fieldNumber}`}
          container
          columnSpacing={3}
          rowSpacing={0}
        >
          <Grid item xs={12} sm={12}>
            <Autocomplete
              id={`hospital-${fieldNumber}`}
              name={`hospital-${fieldNumber}`}
              onChange={(e, value) =>
                setHospital({
                  ...hospital,
                  hospitalCode: value?.hospitalCode,
                  hospitalName: value?.hospitalName,
                })
              }
              disabled={servedIndc === "N"}
              value={
                hospital?.hospitalCode
                  ? {
                      hospitalCode: hospital?.hospitalCode,
                      hospitalName: hospital?.hospitalName,
                    }
                  : null
              }
              options={hospitalOptions}
              getOptionLabel={(option) => option?.hospitalName || option}
              renderOption={(props, option) =>
                handleDropdownList(
                  props,
                  option.hospitalCode,
                  option.hospitalName
                )
              }
              isOptionEqualToValue={(option, value) =>
                option?.hospitalCode === value?.hospitalCode
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Hospital Name"
                  InputLabelProps={{ shrink: true }}
                  placeholder="Enter hospital name"
                  onChange={callHospitalDebounced}
                />
              )}
              PopperComponent={(props) => <CustomPopper {...props} />}
            />
          </Grid>
        </Grid>
      </div>
    </Styled>
  );
}
