import React, { useEffect, useRef, useState } from "react";
import Styled from "./styles";
import { useDispatch, useSelector } from "react-redux";
import ScrollToTop from "../../Common/HOC/ScrollToTop";
import SubPage from "../../Common/SubPage";
import AddRemoveFieldButtons from "../../Common/AddRemoveField";
import SaveButtons from "../../Common/SaveButtons";
import CustomRadio from "../../Common/CustomRadio";
import * as actions from "../../../redux/actions";
import {
  getHospitalPrivilegesData,
  postHospitalPrivilegesData,
} from "../../../utils/ModuleActions";
import Hospital from "./Hospital";
import { Grid } from "@mui/material";
import ShowResponse from "../../Common/Messages";
import { useNavigate } from "react-router";
import {
  addBtnName,
  sendPayload,
  handleCancelPopUpBtn,
  handleOkayPopUpBtn,
} from "../../../utils/Utils";

const HospitalPrivileges = () => {
  const properties = {
    titlePage: "HOSPITAL PRIVILEGES",
    showInstructions: true,
  };

  const MIN_FIELDS = 1;
  const MAX_FIELDS = 20;

  const [hospitalCount, setHospitalCount] = useState(1);
  const [additionalFields, setAdditionalFields] = useState([]);
  const [isThereHPrivileges, setIsThereHPrivileges] = useState("Y");
  const [response, setResponse] = useState({
    headerText: "Error message",
    message: "",
    show: false,
  });

  const hospitalPrivilegesData = useRef([]);
  const navDataObj = useRef({});

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { data: navigationList } = useSelector(({ navigate }) => navigate);
  const { data: styleProps } = useSelector(
    ({ getStyleProps }) => getStyleProps
  );
  const { data: hospitalArr } = useSelector(
    ({ hospitalPrivileges }) => hospitalPrivileges
  );

  useEffect(() => {
    dispatch(actions.commonAction(getHospitalPrivilegesData));
    dispatch(
      actions.updateWithoutPost({
        type: "UPDATE_SERVED_AS_FACULTY_INDC",
        data: {},
      })
    );

    return () => {
      dispatch(
        actions.updateWithoutPost({
          type: "CLEAR_HOSPITAL_PRIVILEGES_DATA",
          data: {},
        })
      );
    };
  }, [dispatch]);

  const updatePrivilegesIndc = (IndcVal) => {
    setIsThereHPrivileges(IndcVal);

    let actionData = {
      type: "UPDATE_SERVED_AS_FACULTY_INDC",
      data: IndcVal,
    };
    dispatch(actions.updateWithoutPost(actionData));
  };

  /**
   * This function is used to update the hospital data when user click on add field button
   * or change field value
   * @param {*} hospitalObj Updated object
   */
  const updateHospitalData = (hospitalObj) => {
    if (hospitalObj?.fieldNumber) {
      let index = hospitalPrivilegesData.current?.findIndex(
        (data) => data?.fieldNumber === hospitalObj?.fieldNumber
      );
      if (index === -1) {
        hospitalPrivilegesData.current?.push(hospitalObj);
      } else {
        hospitalPrivilegesData.current[index] = hospitalObj;
      }
      setHospitalCount(hospitalPrivilegesData.current?.length);
    }
  };

  /**
   * This function us use to load existed data or load first blank form.
   */
  const loadHospitalPrivilegesWithData = () => {
    let additionalFieldsClone = [];
    if (hospitalArr?.data?.hospitalPrivilege?.length > 0) {
      hospitalArr?.data?.hospitalPrivilege.forEach((data, index) => {
        additionalFieldsClone.push(
          <Hospital
            key={index + 1}
            onHospitalChange={updateHospitalData}
            fieldNumber={index + 1}
            hospitalData={data}
          />
        );
      });
      setHospitalCount(hospitalArr?.data?.hospitalPrivilege?.length);
    } else {
      additionalFieldsClone.push(
        <Hospital
          key={1}
          onHospitalChange={updateHospitalData}
          fieldNumber={1}
        />
      );
    }
    setAdditionalFields(additionalFieldsClone);
  };

  useEffect(() => {
    if (hospitalArr) loadHospitalPrivilegesWithData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hospitalArr]);

  /**
   * This function calls when user click on remove a field button and it is used to
   * update the hospital privileges data when user clicks on remove button
   */
  const handleRemoveField = () => {
    if (additionalFields.length === MIN_FIELDS) return;

    let hospitalObj = hospitalPrivilegesData.current[hospitalCount - 1];
    if (hospitalObj !== undefined) {
      if (hospitalObj?.fieldNumber === hospitalCount) {
        if (
          additionalFields.length > hospitalArr?.data?.hospitalPrivilege?.length
        ) {
          hospitalPrivilegesData.current?.pop();
        } else {
          hospitalPrivilegesData.current[hospitalCount - 1].hospitalName = "";
        }

        let currentCount =
          hospitalCount > 1 ? hospitalCount - 1 : hospitalCount;
        setHospitalCount(currentCount);
        setAdditionalFields(
          additionalFields.slice(0, additionalFields.length - 1)
        );
      }
    }
  };

  const handleAddField = () => {
    setHospitalCount(hospitalCount + 1);
    setAdditionalFields([
      ...additionalFields,
      <Hospital
        fieldNumber={hospitalCount + 1}
        onHospitalChange={updateHospitalData}
        key={hospitalCount + 1}
      />,
    ]);
  };

  /**
   * This function is called when the user tries to save, either by clicking on a side menu item
   * or by clicking one of the three save buttons. Calls addBtnName(). Read that function description for more details.
   * Calls the sendPayload() function afterwards.
   * @param {*} navObject An object from navigate.json or an event object from clicking the save buttons
   * @param {*} location Location object from useLocation(), either from Sidebar or SaveButtons component
   */
  const handleSubmit = (navObject, location) => {
    let payload = {
      privilege: null,
      hospitalPrivilege: [],
    };
    payload.privilege = isThereHPrivileges;
    payload.hospitalPrivilege = hospitalPrivilegesData.current;

    navDataObj.current = addBtnName(navObject, navigationList, location);

    let params = {
      dispatch: dispatch,
      actionData: postHospitalPrivilegesData,
      requestBody: payload,
      response: response,
      setResponse: setResponse,
      navObject: navDataObj.current,
      navigate: navigate,
    };

    sendPayload(params);
  };

  if (!styleProps) return null; // Needed so app does not crash on first render
  return (
    <Styled styleProps={styleProps}>
      {response?.show && (
        <ShowResponse
          headerText={response?.headerText}
          message={response?.message}
          show={response?.show}
          handleCancel={() =>
            handleCancelPopUpBtn(
              response,
              setResponse,
              navDataObj.current,
              navigate
            )
          }
          handleOkay={() => handleOkayPopUpBtn(response, setResponse)}
        />
      )}
      <SubPage properties={properties} handleSideMenuSave={handleSubmit}>
        <div
          id="hospital-privileges-form-body"
          className="form-body-container space-between-text"
        >
          <div id="hospital-privileges-header">
            <h4>Hospital Privileges</h4>
          </div>
          <div
            id="hospital-privileges-body"
            className="md-space-between-containers"
          >
            <Grid container columnSpacing={3} rowSpacing={0}>
              <Grid item xs={12} sm={12}>
                <CustomRadio
                  value={isThereHPrivileges}
                  formLabel="Do you have hospital privileges at Hospitals in the
                      State of New York?"
                  onChangeCustom={(e) => updatePrivilegesIndc(e?.target?.value)}
                  ariaLabel="hospital-privileges"
                />
              </Grid>
            </Grid>
            <p>
              If you have hospital privileges at New York Hospitals, list the
              hospitals where you have privileges. Use the Select button(s) to
              display a list of hospitals. Any change to previously entered
              information will be effective immediately. Click the{" "}
              <strong>Add</strong> button below if you need to enter additional
              Hospitals. If you have privileges at Hospitals outside the State
              of New York you may list these in your Concise Statement on the
              Finish page (the last page of the survey).
            </p>
          </div>
          <div
            id="hospital-privileges-items"
            className="md-space-between-containers"
          >
            {additionalFields}
            <AddRemoveFieldButtons
              handleAddField={handleAddField}
              handleRemoveField={handleRemoveField}
              disableAddButton={
                hospitalCount >= MAX_FIELDS || isThereHPrivileges === "N"
              }
              disableRemoveButton={
                hospitalCount <= MIN_FIELDS || isThereHPrivileges === "N"
              }
            />
          </div>
          <SaveButtons
            saveGoBack={handleSubmit}
            saveExit={handleSubmit}
            saveContinue={handleSubmit}
          />
        </div>
      </SubPage>
    </Styled>
  );
};

export default ScrollToTop(HospitalPrivileges);
