import styled from "styled-components/macro";

export default styled.div`
  .multiline-textbox {
    margin: 6px 0;
  }
  .question-text-body {
    margin-top: 15px;
  }
`;
