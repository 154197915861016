import React, { useEffect, useRef, useState } from "react";
import Styled from "./styles";
import { useDispatch, useSelector } from "react-redux";
import ScrollToTop from "../../Common/HOC/ScrollToTop";
import SubPage from "../../Common/SubPage";
import AddRemoveFieldButtons from "../../Common/AddRemoveField";
import SaveButtons from "../../Common/SaveButtons";
import * as actions from "../../../redux/actions";
import {
  getLicenseeActionData,
  getMasterData,
  postLicenseeActionData,
} from "../../../utils/ModuleActions";
import { useNavigate } from "react-router";
import ShowResponse from "../../Common/Messages";

import {
  addBtnName,
  sendPayload,
  handleCancelPopUpBtn,
  handleOkayPopUpBtn,
} from "../../../utils/Utils";
import OutOfState from "./OutOfState";
import Actions from "./Actions";

const LicenseeAction = () => {
  const properties = {
    titlePage: "LICENSEE ACTIONS",
    showInstructions: true,
  };

  const MIN_FIELDS = 1;
  const MAX_FIELDS = 20;

  const [outOfStateCount, setOutOfStateCount] = useState(1);
  const [additionalFields, setAdditionalFields] = useState([]);
  const [anyAction, setAnyAction] = useState("");
  const [noNewItem, setNoNewItem] = useState(true);

  const [response, setResponse] = useState({
    headerText: "Error message",
    message: "",
    show: false,
  });

  const { data: navigationList } = useSelector(({ navigate }) => navigate);
  const { data: licenseeActionList } = useSelector(
    ({ licenseeAction }) => licenseeAction
  );

  const outOfStateData = useRef([]);
  const navDataObj = useRef({});
  const initialItemCount = useRef(0);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(actions.commonAction(getLicenseeActionData));
    dispatch(actions.commonAction(getMasterData));
    dispatch(
      actions.updateWithoutPost({
        type: "UPDATE_ANY_ACTION_INDC",
        data: "",
      })
    );

    return () => {
      dispatch(
        actions.updateWithoutPost({
          type: "CLEAR_LICENSEE_ACTION_DATA",
          data: {},
        })
      );
    };
  }, [dispatch]);

  /**
   * This function is used to update the Licensee Action data when user click on add field button
   * or change some field value
   * @param {*} LAObj Updated Licensee Action object
   */
  const updateLAData = (LAObj) => {
    if (LAObj?.fieldNumber) {
      let index = outOfStateData.current?.findIndex(
        (data) => data?.fieldNumber === LAObj?.fieldNumber
      );
      if (index === -1) {
        outOfStateData.current?.push(LAObj);
      } else {
        outOfStateData.current[index] = LAObj;
      }
      setOutOfStateCount(outOfStateData.current?.length);
    }
  };

  const updateActionData = (anyTakenAction) => {
    setAnyAction(anyTakenAction);

    let actionData = {
      type: "UPDATE_ANY_ACTION_INDC",
      data: anyTakenAction,
    };
    dispatch(actions.updateWithoutPost(actionData));
  };

  /**
   * This function is used to load existed data or load first blank form.
   */
  const loadLAWithData = () => {
    const { data: LAArr } = licenseeActionList;
    let additionalFieldsClone = [];
    if (LAArr?.outOfStateSanctions?.length > 0) {
      LAArr?.outOfStateSanctions.forEach((data, index) => {
        additionalFieldsClone.push(
          <OutOfState
            key={index + 1}
            onOutOfStateChange={updateLAData}
            fieldNumber={index + 1}
            outOfStateData={data}
          />
        );
      });
      setOutOfStateCount(LAArr?.outOfStateSanctions?.length);
      initialItemCount.current = LAArr?.outOfStateSanctions?.length;
    } else {
      additionalFieldsClone.push(
        <OutOfState key={1} onOutOfStateChange={updateLAData} fieldNumber={1} />
      );
    }
    setAdditionalFields(additionalFieldsClone);
  };

  useEffect(() => {
    if (licenseeActionList) {
      loadLAWithData();
      setAnyAction(licenseeActionList?.data?.phyResp);
      dispatch(
        actions.updateWithoutPost({
          type: "UPDATE_ANY_ACTION_INDC",
          data: licenseeActionList?.data?.phyResp,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [licenseeActionList]);

  /**
   * This function calls when user click on remove a field button and it is used to
   * update the data when user clicks on remove button
   */
  const handleRemoveField = () => {
    if (additionalFields.length === MIN_FIELDS) return;

    let LAObj = outOfStateData.current[outOfStateCount - 1];
    if (LAObj !== undefined) {
      if (LAObj?.fieldNumber === outOfStateCount) {
        outOfStateData.current?.pop();
        let currentCount =
          outOfStateCount > 1 ? outOfStateCount - 1 : outOfStateCount;
        setOutOfStateCount(currentCount);
        if (initialItemCount.current === currentCount) {
          setNoNewItem(true);
        }
        setAdditionalFields(
          additionalFields.slice(0, additionalFields.length - 1)
        );
      }
    }
  };

  const handleAddField = () => {
    setOutOfStateCount(outOfStateCount + 1);
    setNoNewItem(false);
    setAdditionalFields([
      ...additionalFields,
      <OutOfState
        fieldNumber={outOfStateCount + 1}
        onOutOfStateChange={updateLAData}
        key={outOfStateCount + 1}
      />,
    ]);
  };

  /**
   * This function is called when the user tries to save, either by clicking on a side menu item
   * or by clicking one of the three save buttons. Calls addBtnName(). Read that function description for more details.
   * Calls the sendPayload() function afterwards.
   * @param {*} navObject An object from navigate.json or an event object from clicking the save buttons
   * @param {*} location Location object from useLocation(), either from Sidebar or SaveButtons component
   */
  const handleSubmit = (navObject, location) => {
    let payload = {
      phyResp: "",
      outOfStateSanctions: [],
    };
    payload.phyResp = anyAction;
    payload.outOfStateSanctions = outOfStateData.current;

    navDataObj.current = addBtnName(navObject, navigationList, location);

    let dateErrMsgsFlag = dateValidation(payload, setResponse);

    if (dateErrMsgsFlag === false) {

      let params = {
        dispatch: dispatch,
        actionData: postLicenseeActionData,
        requestBody: payload,
        response: response,
        setResponse: setResponse,
        navObject: navDataObj.current,
        navigate: navigate,
      };

      sendPayload(params);
    }
  };

  function dateValidation(payload, setResponse) {

    let dateErrMsgs = [];
    let dateErrMsgsFlag = false;

    let OSActionsArray = payload.outOfStateSanctions;
    let actionDateErrMsg = "";

    for (let i = 0; i < OSActionsArray.length; i++) {

      if (OSActionsArray[i].actionDate === "Invalid Date") {
        actionDateErrMsg = "Please enter a valid Action Date in Out of State Licensee Action " + (i + 1) + ".";
        dateErrMsgs.push(actionDateErrMsg);
        dateErrMsgsFlag = true;
      }

    }

    if (dateErrMsgsFlag === true) {
      let userStepsMsg = "Do you wish to remain on this page to correct your data and resubmit your changes? If so click the 'OK' button below. If you prefer you may discard your changes and proceed to the page indicated by the button you clicked. To do this click on the 'Cancel' button to Cancel your updates instead."
      dateErrMsgs.push(userStepsMsg);
      setResponse({
        ...response,
        show: true,
        message: dateErrMsgs.join("\r\n\n"),
      });
    }

    return dateErrMsgsFlag;
  }

  return (
    <Styled>
      {response?.show && (
        <ShowResponse
          headerText={response?.headerText}
          message={response?.message}
          show={response?.show}
          handleCancel={() =>
            handleCancelPopUpBtn(
              response,
              setResponse,
              navDataObj.current,
              navigate
            )
          }
          handleOkay={() => handleOkayPopUpBtn(response, setResponse)}
        />
      )}
      <SubPage properties={properties} handleSideMenuSave={handleSubmit}>
        <div id="licensee-action-form-body" className="form-body-container">
          <div id="licensee-action-container" className="form-body-section">
            <Actions
              onActionsChange={updateActionData}
              oldAction={licenseeActionList?.data?.phyResp}
              OPMCActions={licenseeActionList?.data?.OPMCActions}
            />
            <hr />
            <div
              id="licensee-action-header-container"
              className="form-body-section space-between-text"
            >
              {additionalFields}
              <AddRemoveFieldButtons
                handleAddField={handleAddField}
                handleRemoveField={handleRemoveField}
                disableAddButton={
                  outOfStateCount === MAX_FIELDS || anyAction === "N"
                }
                disableRemoveButton={
                  outOfStateCount === MIN_FIELDS ||
                  anyAction === "N" ||
                  noNewItem
                }
              />
            </div>
            <SaveButtons
              saveGoBack={handleSubmit}
              saveExit={handleSubmit}
              saveContinue={handleSubmit}
            />
          </div>
        </div>
      </SubPage>
    </Styled>
  );
};

export default ScrollToTop(LicenseeAction);
