import React, { useState, useEffect, useRef } from "react";
import Styled from "./styles";
import * as actions from "../../../redux/actions";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ScrollToTop from "../../Common/HOC/ScrollToTop";
import SubPage from "../../Common/SubPage";
import AddRemoveFieldButtons from "../../Common/AddRemoveField";
import ShowResponse from "../../Common/Messages";
import CustomRadio from "../../Common/CustomRadio";
import {
  getConvictionsData,
  postConvictionsData,
  getMasterData,
} from "../../../utils/ModuleActions";
import ConvictionItem from "./ConvictionItem";
import SaveButtons from "../../Common/SaveButtons";
import {
  addBtnName,
  sendPayload,
  handleCancelPopUpBtn,
  handleOkayPopUpBtn,
} from "../../../utils/Utils";

const Convictions = () => {
  /* ********** STATE VARIABLES ********** */
  const [additionalFields, setAdditionalFields] = useState([]);
  const [convictionItemsCount, setConvictionItemsCount] = useState(1);
  const [convictionIndc, setConvictionIndc] = useState("");
  const [noNewItem, setNoNewItem] = useState(true);
  const [response, setResponse] = useState({
    headerText: "Error message",
    message: "",
    show: false,
  });

  /* ********** REF VARIABLES ********** */
  const convictionItems = useRef([]);
  const navDataObj = useRef({});
  const initialItemCount = useRef(0);

  const properties = {
    titlePage: "CRIMINAL CONVICTIONS",
    showInstructions: true,
  };
  const { data: styleProps } = useSelector(
    ({ getStyleProps }) => getStyleProps
  );
  const { data: navigationList } = useSelector(({ navigate }) => navigate);
  const { data: convictionsData } = useSelector(
    ({ convictions }) => convictions
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const MIN_FIELDS = 1;
  const MAX_FIELDS = 20;

  useEffect(() => {
    dispatch(actions.commonAction(getConvictionsData));
    dispatch(actions.commonAction(getMasterData));
    dispatch(
      actions.updateWithoutPost({
        type: "UPDATE_CONVICTION_INDC",
        data: "",
      })
    );

    return () => {
      dispatch(
        actions.updateWithoutPost({
          type: "CLEAR_COMM_SERVICE_DATA",
          data: {},
        })
      );
    };
  }, [dispatch]);

  /**
   * Used to set up additionalFields when the Convictions page first renders.
   * Handles the use cases of data and no data returning from the server.
   */
  const loadInitialAdditionalFields = () => {
    let additionalFieldsClone = [];
    if (convictionsData?.data?.convictions?.length > 0) {
      convictionsData?.data?.convictions.forEach((data, index) => {
        additionalFieldsClone.push(
          <ConvictionItem
            key={data?.convictionKey}
            fieldNumber={index + 1}
            onConvictionItemChange={updateConvictionItemData}
            convictionItemData={data}
          />
        );
      });
      setConvictionItemsCount(convictionsData?.data?.convictions?.length);
      initialItemCount.current = convictionsData?.data?.convictions?.length;
    } else {
      additionalFieldsClone.push(
        <ConvictionItem
          fieldNumber={1}
          key={1}
          onConvictionItemChange={updateConvictionItemData}
        />
      );
    }
    setAdditionalFields(additionalFieldsClone);
  };

  useEffect(() => {
    // If we call this when there are errorMessages, then all Conviction Item fields reset to empty
    if (convictionsData && !convictionsData?.errorMessages) {
      loadInitialAdditionalFields();
      setConvictionIndc(convictionsData?.data?.conviction);
      dispatch(
        actions.updateWithoutPost({
          type: "UPDATE_CONVICTION_INDC",
          data: convictionsData?.data?.conviction,
        })
      );
    }
    // Adding loadInitialAdditionalFields to the dependency array will cause infinite loop and make Add/Remove stop working
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [convictionsData]);

  /**
   * This function is used to update the Conviction Items when the user clicks on the
   * Add Field button and changes a field value
   * @param {*} convictionItemObject Updated Conviction Item object
   */
  const updateConvictionItemData = (convictionItemObject) => {
    if (convictionItemObject?.fieldNumber) {
      let index = convictionItems.current.findIndex(
        (data) => data?.fieldNumber === convictionItemObject?.fieldNumber
      );
      if (index === -1) {
        convictionItems.current.push(convictionItemObject);
      } else {
        convictionItems.current[index] = convictionItemObject;
      }
      setConvictionItemsCount(convictionItems.current.length);
    }
  };

  /**
   * This function is used to update the conviction indicator when the user changes a field value.
   * It also updates the Redux store with the current value of convictionIndc so the Convictions
   * component can determine whether or not to disable its fields.
   * @param {*} convictionIndc Updated convictionIndc value
   */
  const updateConvictionIndc = (convictionIndc) => {
    setConvictionIndc(convictionIndc);

    let actionData = {
      type: "UPDATE_CONVICTION_INDC",
      data: convictionIndc,
    };
    dispatch(actions.updateWithoutPost(actionData));
  };

  /**
   * This function is called when the user clicks on the Remove Field button and it updates the Conviction Items
   */
  const handleRemoveField = () => {
    if (convictionItemsCount === MIN_FIELDS) return;

    setConvictionItemsCount(convictionItemsCount - 1);
    if (initialItemCount.current === convictionItemsCount - 1) {
      setNoNewItem(true);
    }
    convictionItems.current.pop(); // remove last item
    setAdditionalFields(additionalFields.slice(0, additionalFields.length - 1));
  };

  /**
   * This function is called when the user clicks on the Add Field button and it updates the Conviction Items
   */
  const handleAddField = () => {
    if (convictionItemsCount === MAX_FIELDS) return;

    setConvictionItemsCount(convictionItemsCount + 1);
    setNoNewItem(false);
    setAdditionalFields([
      ...additionalFields,
      <ConvictionItem
        fieldNumber={convictionItemsCount + 1}
        key={convictionItemsCount + 1}
        onConvictionItemChange={updateConvictionItemData}
      />,
    ]);
  };

  /**
   * This function is called when the user tries to save, either by clicking on a side menu item
   * or by clicking one of the three save buttons. Calls addBtnName(). Read that function description for more details.
   * Calls the sendPayload() function afterwards.
   * @param {*} navObject An object from navigate.json or an event object from clicking the save buttons
   * @param {*} location Location object from useLocation(), either from Sidebar or SaveButtons component
   */
  const handleSubmit = (navObject, location) => {
    let payload = {
      convictions: [],
      conviction: "",
    };
    payload.convictions = convictionItems.current;
    payload.conviction = convictionIndc;

    navDataObj.current = addBtnName(navObject, navigationList, location);

    let dateErrMsgsFlag = dateValidation(payload, setResponse);

    if (dateErrMsgsFlag === false) {

      let params = {
        dispatch: dispatch,
        actionData: postConvictionsData,
        requestBody: payload,
        response: response,
        setResponse: setResponse,
        navObject: navDataObj.current,
        navigate: navigate,
      };

      sendPayload(params);
    }

  };

  function dateValidation(payload, setResponse) {

    let dateErrMsgs = [];
    let dateErrMsgsFlag = false;

    let convictionsArray = payload.convictions;
    let convictionDateErrMsg = "";

    for (let i = 0; i < convictionsArray.length; i++) {

      if (convictionsArray[i].convictionDate === "Invalid Date") {
        convictionDateErrMsg = "Please enter a valid Conviction Date in Conviction Item " + (i + 1) + ".";
        dateErrMsgs.push(convictionDateErrMsg);
        dateErrMsgsFlag = true;
      }

    }

    if (dateErrMsgsFlag === true) {
      let userStepsMsg = "Do you wish to remain on this page to correct your data and resubmit your changes? If so click the 'OK' button below. If you prefer you may discard your changes and proceed to the page indicated by the button you clicked. To do this click on the 'Cancel' button to Cancel your updates instead."
      dateErrMsgs.push(userStepsMsg);
      setResponse({
        ...response,
        show: true,
        message: dateErrMsgs.join("\r\n\n"),
      });
    }

    return dateErrMsgsFlag;
  }

  /* ********** FUNCTION COMPONENT RETURN ********** */
  if (!styleProps) return null; // Needed so app does not crash on first render
  return (
    <Styled styleProps={styleProps}>
      {response?.show && (
        <ShowResponse
          headerText={response?.headerText}
          message={response?.message}
          show={response?.show}
          handleCancel={() =>
            handleCancelPopUpBtn(
              response,
              setResponse,
              navDataObj.current,
              navigate
            )
          }
          handleOkay={() => handleOkayPopUpBtn(response, setResponse)}
        />
      )}
      <SubPage properties={properties} handleSideMenuSave={handleSubmit}>
        <div
          id="convictions-form-body"
          className="form-body-container space-between-text"
        >
          <div id="convictions-header">
            <h4>Criminal Convictions</h4>
            <p>
              Any change to previously entered information will be effective
              immediately.
            </p>
            <p>
              Please contact the Physician Help Desk at{" "}
              <strong className="help-desk-number">1-888-338-6998</strong> to
              modify or dispute a conviction.
            </p>
          </div>
          <div id="convictions-body" className="md-space-between-containers">
            <CustomRadio
              value={convictionIndc}
              formLabel="Have you been convicted of a crime (felony or misdemeanor) in any
                state, province or county within the past 10 years?"
              onChangeCustom={(e) => updateConvictionIndc(e.target.value)}
              ariaLabel="convicted-of-crime"
            />
            <p>
              If so, list the offense and date of conviction. Click the Add
              button below if you need to enter additional convictions. (Use
              this format for the Date, MM-DD-YYYY)
            </p>
            <div
              id="conviction-items-container"
              className="md-space-between-containers"
            >
              {additionalFields}
              <AddRemoveFieldButtons
                handleAddField={handleAddField}
                handleRemoveField={handleRemoveField}
                disableAddButton={
                  convictionItemsCount === MAX_FIELDS || convictionIndc === "N"
                }
                disableRemoveButton={
                  convictionItemsCount === MIN_FIELDS ||
                  convictionIndc === "N" ||
                  noNewItem
                }
              />
            </div>
          </div>
          <SaveButtons
            saveGoBack={handleSubmit}
            saveExit={handleSubmit}
            saveContinue={handleSubmit}
          />
        </div>
      </SubPage>
    </Styled>
  );
};

export default ScrollToTop(Convictions);
