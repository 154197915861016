import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import { useSelector } from "react-redux";
import Styled from "./styles";

const ShowResponse = ({
  headerText,
  dialogBody = "Regular",
  message,
  show = false,
  handleCancel = () => {},
  handleOkay = () => {},
}) => {
  const { data: styleProps } = useSelector(
    ({ getStyleProps }) => getStyleProps
  );

  if (!styleProps) return null; // Needed so app does not crash on first render
  return (
    <Styled styleProps={styleProps}>
      <Dialog
        open={show}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="error-dialog"
      >
        <DialogTitle id="alert-dialog-title" className="error-dialog-title">
          <ErrorIcon />
          {headerText}
        </DialogTitle>
        <DialogContent className="error-dialog-content">
          {dialogBody === "Regular" && (
            <DialogContentText id="alert-dialog-description">
              {message}
            </DialogContentText>
          )}
          {dialogBody === "HTMLComp" && message}
        </DialogContent>
        <DialogActions className="error-dialog-actions">
          <Grid
            container
            className="error-dialog-buttons-container"
            justifyContent="center"
            alignItems="center"
            columnSpacing={6}
            rowSpacing={2}
          >
            <Grid item>
              <Button
                className="btn-no-fill error-dialog-button"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                className="btn-fill error-dialog-button"
                onClick={handleOkay}
                autoFocus
              >
                Ok
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </Styled>
  );
};

export default ShowResponse;
