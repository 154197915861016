export const GET_NAVIGATE_LIST_REQUEST = "GET_NAVIGATE_LIST_REQUEST";
export const GET_NAVIGATE_LIST_SUCCESS = "GET_NAVIGATE_LIST_SUCCESS";
export const GET_NAVIGATE_LIST_ERROR = "GET_NAVIGATE_LIST_ERROR";

export const GET_NAVIGATE_COMPLETED_REQUEST = "GET_NAVIGATE_COMPLETED_REQUEST";
export const GET_NAVIGATE_COMPLETED_SUCCESS = "GET_NAVIGATE_COMPLETED_SUCCESS";
export const GET_NAVIGATE_COMPLETED_ERROR = "GET_NAVIGATE_COMPLETED_ERROR";

export const GET_STYLE_PROPS_REQUEST = "GET_STYLE_PROPS_REQUEST";
export const GET_STYLE_PROPS_SUCCESS = "GET_STYLE_PROPS_SUCCESS";
export const GET_STYLE_PROPS_ERROR = "GET_STYLE_PROPS_ERROR";

export const GET_PHYSICIAN_INFO_REQUEST = "GET_PHYSICIAN_INFO_REQUEST";
export const GET_PHYSICIAN_INFO_SUCCESS = "GET_PHYSICIAN_INFO_SUCCESS";
export const GET_PHYSICIAN_INFO_ERROR = "GET_PHYSICIAN_INFO_ERROR";

export const CLEAR_PHYSICIAN_INFO_DATA = "CLEAR_PHYSICIAN_INFO_DATA";

export const PUT_PHYSICIAN_INFO_REQUEST = "PUT_PHYSICIAN_INFO_REQUEST";
export const PUT_PHYSICIAN_INFO_SUCCESS = "PUT_PHYSICIAN_INFO_SUCCESS";
export const PUT_PHYSICIAN_INFO_ERROR = "PUT_PHYSICIAN_INFO_ERROR";

export const SEARCH_SPONSOR_REQUEST = "SEARCH_SPONSOR_REQUEST";
export const SEARCH_SPONSOR_SUCCESS = "SEARCH_SPONSOR_SUCCESS";
export const SEARCH_SPONSOR_ERROR = "SEARCH_SPONSOR_ERROR";

export const GET_SPECIALITIES_DATA_REQUEST = "GET_SPECIALITIES_DATA_REQUEST";
export const GET_SPECIALITIES_DATA_SUCCESS = "GET_SPECIALITIES_DATA_SUCCESS";
export const GET_SPECIALITIES_DATA_ERROR = "GET_SPECIALITIES_DATA_ERROR";

export const GET_GME_DATA_REQUEST = "GET_GME_DATA_REQUEST";
export const GET_GME_DATA_SUCCESS = "GET_GME_DATA_SUCCESS";
export const GET_GME_DATA_ERROR = "GET_GME_DATA_ERROR";

export const POST_GME_DATA_REQUEST = "POST_GME_DATA_REQUEST";
export const POST_GME_DATA_SUCCESS = "POST_GME_DATA_SUCCESS";
export const POST_GME_DATA_ERROR = "POST_GME_DATA_ERROR";

export const CLEAR_GME_DATA = "CLEAR_GME_DATA";

export const GET_PRACTICE_AREAS_REQUEST = "GET_PRACTICE_AREAS_REQUEST";
export const GET_PRACTICE_AREAS_SUCCESS = "GET_PRACTICE_AREAS_SUCCESS";
export const GET_PRACTICE_AREAS_ERROR = "GET_PRACTICE_AREAS_ERROR";

export const GET_EDUCATION_DATA_REQUEST = "GET_EDUCATION_DATA_REQUEST";
export const GET_EDUCATION_DATA_SUCCESS = "GET_EDUCATION_DATA_SUCCESS";
export const GET_EDUCATION_DATA_ERROR = "GET_EDUCATION_DATA_ERROR";

export const CLEAR_EDUCATION_DATA = "CLEAR_EDUCATION_DATA";

export const POST_EDUCATION_DATA_REQUEST = "POST_EDUCATION_DATA_REQUEST";
export const POST_EDUCATION_DATA_SUCCESS = "POST_EDUCATION_DATA_SUCCESS";
export const POST_EDUCATION_DATA_ERROR = "POST_EDUCATION_DATA_ERROR";

export const GET_CERTIFICATION_DATA_REQUEST = "GET_CERTIFICATION_DATA_REQUEST";
export const GET_CERTIFICATION_DATA_SUCCESS = "GET_CERTIFICATION_DATA_SUCCESS";
export const GET_CERTIFICATION_DATA_ERROR = "GET_CERTIFICATION_DATA_ERROR";

export const GET_PHYSICIAN_CERTIFICATES_DATA_REQUEST =
  "GET_PHYSICIAN_CERTIFICATES_DATA_REQUEST";
export const GET_PHYSICIAN_CERTIFICATES_DATA_SUCCESS =
  "GET_PHYSICIAN_CERTIFICATES_DATA_SUCCESS";
export const GET_PHYSICIAN_CERTIFICATES_DATA_ERROR =
  "GET_PHYSICIAN_CERTIFICATES_DATA_ERROR";

export const POST_PHYSICIAN_CERTIFICATES_DATA_REQUEST =
  "POST_PHYSICIAN_CERTIFICATES_DATA_REQUEST";
export const POST_PHYSICIAN_CERTIFICATES_DATA_SUCCESS =
  "POST_PHYSICIAN_CERTIFICATES_DATA_SUCCESS";
export const POST_PHYSICIAN_CERTIFICATES_DATA_ERROR =
  "POST_PHYSICIAN_CERTIFICATES_DATA_ERROR";

export const CLEAR_PHYSICIAN_CERTIFICATES_DATA =
  "CLEAR_PHYSICIAN_CERTIFICATES_DATA";

export const SEARCH_MEDICAL_SCHOOLS_REQUEST = "GET_MEDICAL_SCHOOLS_REQUEST";
export const SEARCH_MEDICAL_SCHOOLS_SUCCESS = "GET_MEDICAL_SCHOOLS_SUCCESS";
export const SEARCH_MEDICAL_SCHOOLS_ERROR = "GET_MEDICAL_SCHOOLS_ERROR";

export const GET_PROF_MEMBERSHIP_DATA_REQUEST =
  "GET_PROF_MEMBERSHIP_DATA_REQUEST";
export const GET_PROF_MEMBERSHIP_DATA_SUCCESS =
  "GET_PROF_MEMBERSHIP_DATA_SUCCESS";
export const GET_PROF_MEMBERSHIP_DATA_ERROR = "GET_PROF_MEMBERSHIP_DATA_ERROR";

export const POST_PROF_MEMBERSHIP_DATA_REQUEST =
  "POST_PROF_MEMBERSHIP_DATA_REQUEST";
export const POST_PROF_MEMBERSHIP_DATA_SUCCESS =
  "POST_PROF_MEMBERSHIP_DATA_SUCCESS";
export const POST_PROF_MEMBERSHIP_DATA_ERROR =
  "POST_PROF_MEMBERSHIP_DATA_ERROR";

export const CLEAR_PROF_MEMBERSHIP_DATA = "CLEAR_PROF_MEMBERSHIP_DATA";

export const GET_TEACHING_DATA_REQUEST = "GET_TEACHING_DATA_REQUEST";
export const GET_TEACHING_DATA_SUCCESS = "GET_TEACHING_DATA_SUCCESS";
export const GET_TEACHING_DATA_ERROR = "GET_TEACHING_DATA_ERROR";

export const UPDATE_SERVED_AS_FACULTY_INDC = "UPDATE_SERVED_AS_FACULTY_INDC";
export const CLEAR_TEACHING_DATA = "CLEAR_TEACHING_DATA";

export const POST_TEACHING_DATA_REQUEST = "POST_TEACHING_DATA_REQUEST";
export const POST_TEACHING_DATA_SUCCESS = "POST_TEACHING_DATA_SUCCESS";
export const POST_TEACHING_DATA_ERROR = "POST_TEACHING_DATA_ERROR";

export const GET_HOSPITAL_PRIVILEGES_DATA_REQUEST =
  "GET_HOSPITAL_PRIVILEGES_DATA_REQUEST";
export const GET_HOSPITAL_PRIVILEGES_DATA_SUCCESS =
  "GET_HOSPITAL_PRIVILEGES_DATA_SUCCESS";
export const GET_HOSPITAL_PRIVILEGES_DATA_ERROR =
  "GET_HOSPITAL_PRIVILEGES_DATA_ERROR";

export const POST_HOSPITAL_PRIVILEGES_DATA_REQUEST =
  "POST_HOSPITAL_PRIVILEGES_DATA_REQUEST";
export const POST_HOSPITAL_PRIVILEGES_DATA_SUCCESS =
  "POST_HOSPITAL_PRIVILEGES_DATA_SUCCESS";
export const POST_HOSPITAL_PRIVILEGES_DATA_ERROR =
  "POST_HOSPITAL_PRIVILEGES_DATA_ERROR";

export const CLEAR_HOSPITAL_PRIVILEGES_DATA = "CLEAR_HOSPITAL_PRIVILEGES_DATA";

export const SEARCH_HOSPITAL_REQUEST = "SEARCH_HOSPITAL_REQUEST";
export const SEARCH_HOSPITAL_SUCCESS = "SEARCH_HOSPITAL_SUCCESS";
export const SEARCH_HOSPITAL_ERROR = "SEARCH_HOSPITAL_ERROR";

export const GET_INSURANCE_DATA_REQUEST = "GET_INSURANCE_DATA_REQUEST";
export const GET_INSURANCE_DATA_SUCCESS = "GET_INSURANCE_DATA_SUCCESS";
export const GET_INSURANCE_DATA_ERROR = "GET_INSURANCE_DATA_ERROR";

export const CLEAR_INSURANCE_DATA = "CLEAR_INSURANCE_DATA";

export const POST_INSURANCE_DATA_REQUEST = "POST_INSURANCE_DATA_REQUEST";
export const POST_INSURANCE_DATA_SUCCESS = "POST_INSURANCE_DATA_SUCCESS";
export const POST_INSURANCE_DATA_ERROR = "POST_INSURANCE_DATA_ERROR";

export const GET_TRANSLATION_SERVICES_DATA_REQUEST =
  "GET_TRANSLATION_SERVICES_DATA_REQUEST";
export const GET_TRANSLATION_SERVICES_DATA_SUCCESS =
  "GET_TRANSLATION_SERVICES_DATA_SUCCESS";
export const GET_TRANSLATION_SERVICES_DATA_ERROR =
  "GET_TRANSLATION_SERVICES_DATA_ERROR";

export const POST_TRANSLATION_SERVICES_DATA_REQUEST =
  "POST_TRANSLATION_SERVICES_DATA_REQUEST";
export const POST_TRANSLATION_SERVICES_DATA_SUCCESS =
  "POST_TRANSLATION_SERVICES_DATA_SUCCESS";
export const POST_TRANSLATION_SERVICES_DATA_ERROR =
  "POST_TRANSLATION_SERVICES_DATA_ERROR";

export const CLEAR_TRANSLATION_SERVICES_DATA =
  "CLEAR_TRANSLATION_SERVICES_DATA";

export const GET_MALPRACTICE_DATA_REQUEST = "GET_MALPRACTICE_DATA_REQUEST";
export const GET_MALPRACTICE_DATA_SUCCESS = "GET_MALPRACTICE_DATA_SUCCESS";
export const GET_MALPRACTICE_DATA_ERROR = "GET_MALPRACTICE_DATA_ERROR";

export const CLEAR_MALPRACTICE_DATA = "CLEAR_MALPRACTICE_DATA";

export const POST_MALPRACTICE_DATA_REQUEST = "POST_MALPRACTICE_DATA_REQUEST";
export const POST_MALPRACTICE_DATA_SUCCESS = "POST_MALPRACTICE_DATA_SUCCESS";
export const POST_MALPRACTICE_DATA_ERROR = "POST_MALPRACTICE_DATA_ERROR";

export const GET_LIMITATIONS_DATA_REQUEST = "GET_LIMITATIONS_DATA_REQUEST";
export const GET_LIMITATIONS_DATA_SUCCESS = "GET_LIMITATIONS_DATA_SUCCESS";
export const GET_LIMITATIONS_DATA_ERROR = "GET_LIMITATIONS_DATA_ERROR";

export const UPDATE_LIMITATIONS_INDC = "UPDATE_LIMITATIONS_INDC";
export const CLEAR_LIMITATIONS_DATA = "CLEAR_LIMITATIONS_DATA";

export const POST_LIMITATIONS_DATA_REQUEST = "POST_LIMITATIONS_DATA_REQUEST";
export const POST_LIMITATIONS_DATA_SUCCESS = "POST_LIMITATIONS_DATA_SUCCESS";
export const POST_LIMITATIONS_DATA_ERROR = "POST_LIMITATIONS_DATA_ERROR";

export const GET_MASTER_DATA_REQUEST = "GET_MASTER_DATA_REQUEST";
export const GET_MASTER_DATA_SUCCESS = "GET_MASTER_DATA_SUCCESS";
export const GET_MASTER_DATA_ERROR = "GET_MASTER_DATA_ERROR";

export const GET_LICENSEE_ACTION_DATA_REQUEST =
  "GET_LICENSEE_ACTION_DATA_REQUEST";
export const GET_LICENSEE_ACTION_DATA_SUCCESS =
  "GET_LICENSEE_ACTION_DATA_SUCCESS";
export const GET_LICENSEE_ACTION_DATA_ERROR = "GET_LICENSEE_ACTION_DATA_ERROR";

export const UPDATE_ANY_ACTION_INDC = "UPDATE_ANY_ACTION_INDC";

export const POST_LICENSEE_ACTION_DATA_REQUEST =
  "POST_LICENSEE_ACTION_DATA_REQUEST";
export const POST_LICENSEE_ACTION_DATA_SUCCESS =
  "POST_LICENSEE_ACTION_DATA_SUCCESS";
export const POST_LICENSEE_ACTION_DATA_ERROR =
  "POST_LICENSEE_ACTION_DATA_ERROR";

export const CLEAR_LICENSEE_ACTION_DATA = "CLEAR_LICENSEE_ACTION_DATA";

export const GET_FINISH_DATA_REQUEST = "GET_FINISH_DATA_REQUEST";
export const GET_FINISH_DATA_SUCCESS = "GET_FINISH_DATA_SUCCESS";
export const GET_FINISH_DATA_ERROR = "GET_FINISH_DATA_ERROR";

export const POST_FINISH_DATA_REQUEST = "POST_FINISH_DATA_REQUEST";
export const POST_FINISH_DATA_SUCCESS = "POST_FINISH_DATA_SUCCESS";
export const POST_FINISH_DATA_ERROR = "POST_FINISH_DATA_ERROR";

export const CLEAR_FINISH_DATA = "CLEAR_FINISH_DATA";

export const GET_RESTRICTIONS_DATA_REQUEST = "GET_RESTRICTIONS_DATA_REQUEST";
export const GET_RESTRICTIONS_DATA_SUCCESS = "GET_RESTRICTIONS_DATA_SUCCESS";
export const GET_RESTRICTIONS_DATA_ERROR = "GET_RESTRICTIONS_DATA_ERROR";

export const UPDATE_RESTRICTIONS_INDC = "UPDATE_RESTRICTIONS_INDC";
export const UPDATE_FAILURE_TO_RENEW_INDC = "UPDATE_FAILURE_TO_RENEW_INDC";
export const CLEAR_RESTRICTIONS_DATA = "CLEAR_RESTRICTIONS_DATA";

export const POST_RESTRICTIONS_DATA_REQUEST = "POST_RESTRICTIONS_DATA_REQUEST";
export const POST_RESTRICTIONS_DATA_SUCCESS = "POST_RESTRICTIONS_DATA_SUCCESS";
export const POST_RESTRICTIONS_DATA_ERROR = "POST_RESTRICTIONS_DATA_ERROR";

export const GET_CONVICTIONS_DATA_REQUEST = "GET_CONVICTIONS_DATA_REQUEST";
export const GET_CONVICTIONS_DATA_SUCCESS = "GET_CONVICTIONS_DATA_SUCCESS";
export const GET_CONVICTIONS_DATA_ERROR = "GET_CONVICTIONS_DATA_ERROR";

export const UPDATE_CONVICTION_INDC = "UPDATE_CONVICTION_INDC";
export const CLEAR_CONVICTIONS_DATA = "CLEAR_CONVICTIONS_DATA";

export const POST_CONVICTIONS_DATA_REQUEST = "POST_CONVICTIONS_DATA_REQUEST";
export const POST_CONVICTIONS_DATA_SUCCESS = "POST_CONVICTIONS_DATA_SUCCESS";
export const POST_CONVICTIONS_DATA_ERROR = "POST_CONVICTIONS_DATA_ERROR";

export const GET_COMM_SERVICE_DATA_REQUEST = "GET_COMM_SERVICE_DATA_REQUEST";
export const GET_COMM_SERVICE_DATA_SUCCESS = "GET_COMM_SERVICE_DATA_SUCCESS";
export const GET_COMM_SERVICE_DATA_ERROR = "GET_COMM_SERVICE_DATA_ERROR";

export const CLEAR_COMM_SERVICE_DATA = "CLEAR_COMM_SERVICE_DATA";

export const POST_COMM_SERVICE_DATA_REQUEST = "POST_COMM_SERVICE_DATA_REQUEST";
export const POST_COMM_SERVICE_DATA_SUCCESS = "POST_COMM_SERVICE_DATA_SUCCESS";
export const POST_COMM_SERVICE_DATA_ERROR = "POST_COMM_SERVICE_DATA_ERROR";

export const GET_PUBLICATIONS_DATA_REQUEST = "GET_PUBLICATIONS_DATA_REQUEST";
export const GET_PUBLICATIONS_DATA_SUCCESS = "GET_PUBLICATIONS_DATA_SUCCESS";
export const GET_PUBLICATIONS_DATA_ERROR = "GET_PUBLICATIONS_DATA_ERROR";

export const POST_PUBLICATIONS_DATA_REQUEST = "POST_PUBLICATIONS_DATA_REQUEST";
export const POST_PUBLICATIONS_DATA_SUCCESS = "POST_PUBLICATIONS_DATA_SUCCESS";
export const POST_PUBLICATIONS_DATA_ERROR = "POST_PUBLICATIONS_DATA_ERROR";

export const CLEAR_PUBLICATIONS_DATA = "CLEAR_PUBLICATIONS_DATA";

export const GET_PRACTICE_DATA_REQUEST = "GET_PRACTICE_DATA_REQUEST";
export const GET_PRACTICE_DATA_SUCCESS = "GET_PRACTICE_DATA_SUCCESS";
export const GET_PRACTICE_DATA_ERROR = "GET_PRACTICE_DATA_ERROR";

export const CLEAR_PRACTICE_DATA = "CLEAR_PRACTICE_DATA";

export const POST_PRACTICE_DATA_REQUEST = "POST_PRACTICE_DATA_REQUEST";
export const POST_PRACTICE_DATA_SUCCESS = "POST_PRACTICE_DATA_SUCCESS";
export const POST_PRACTICE_DATA_ERROR = "POST_PRACTICE_DATA_ERROR";

export const GET_HELP_FILE_DATA_REQUEST = "GET_HELP_FILE_DATA_REQUEST";
export const GET_HELP_FILE_DATA_SUCCESS = "GET_HELP_FILE_DATA_SUCCESS";
export const GET_HELP_FILE_DATA_ERROR = "GET_HELP_FILE_DATA_ERROR";

export const GET_HEALTH_PLANS_DATA_REQUEST = "GET_HEALTH_PLANS_DATA_REQUEST";
export const GET_HEALTH_PLANS_DATA_SUCCESS = "GET_HEALTH_PLANS_DATA_SUCCESS";
export const GET_HEALTH_PLANS_DATA_ERROR = "GET_HEALTH_PLANS_DATA_ERROR";

export const POST_HEALTH_PLANS_DATA_REQUEST = "POST_HEALTH_PLANS_DATA_REQUEST";
export const POST_HEALTH_PLANS_DATA_SUCCESS = "POST_HEALTH_PLANS_DATA_SUCCESS";
export const POST_HEALTH_PLANS_DATA_ERROR = "POST_HEALTH_PLANS_DATA_ERROR";

export const GET_MAILING_KEY = "GET_MAILING_KEY";

export const GET_LOGIN_REQUEST = "GET_LOGIN_REQUEST";
export const GET_LOGIN_REQUEST_SUCCESS = "GET_LOGIN_REQUEST_SUCCESS";
export const GET_LOGIN_REQUEST_ERROR = "GET_LOGIN_REQUEST_ERROR";
export const UNAUTHORIZED_USER = "UNAUTHORIZED_USER";

export const POST_QUESTIONNAIRE_REQUEST = "POST_QUESTIONNAIRE_REQUEST";
export const POST_QUESTIONNAIRE_SUCCESS = "POST_QUESTIONNAIRE_SUCCESS";
export const POST_QUESTIONNAIRE_ERROR = "POST_QUESTIONNAIRE_ERROR";

export const GET_ANALYTICS_URL_DATA_REQUEST = "GET_ANALYTICS_URL_DATA_REQUEST"
export const GET_ANALYTICS_URL_DATA_SUCCESS = "GET_ANALYTICS_URL_DATA_SUCCESS"
export const GET_ANALYTICS_URL_DATA_ERROR = "GET_ANALYTICS_URL_DATA_ERROR"