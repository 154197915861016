import React, { useEffect, useRef, useState } from "react";
import Styled from "./styles";
import { useDispatch, useSelector } from "react-redux";
import ScrollToTop from "../../Common/HOC/ScrollToTop";
import SubPage from "../../Common/SubPage";
import AddRemoveFieldButtons from "../../Common/AddRemoveField";
import SaveButtons from "../../Common/SaveButtons";
import * as actions from "../../../redux/actions";
import {
  getPublicationsData,
  postPublicationsData,
} from "../../../utils/ModuleActions";
import { useNavigate } from "react-router";
import ShowResponse from "../../Common/Messages";
import {
  addBtnName,
  sendPayload,
  handleCancelPopUpBtn,
  handleOkayPopUpBtn,
} from "../../../utils/Utils";
import Publication from "./Publication";

const Publications = () => {
  const properties = {
    titlePage: "PUBLICATION (OPTIONAL)",
    showInstructions: true,
  };

  const MIN_FIELDS = 1;
  const MAX_FIELDS = 20;

  const [publicationCount, setPublicationCount] = useState(1);
  const [publicationsFields, setPublicationsFields] = useState([]);
  const [response, setResponse] = useState({
    headerText: "Error message",
    message: "",
    show: false,
  });

  const { data: navigationList } = useSelector(({ navigate }) => navigate);
  const { data: styleProps } = useSelector(
    ({ getStyleProps }) => getStyleProps
  );
  const { data: publicationsData } = useSelector(
    ({ publications }) => publications
  );

  const publicationArray = useRef([]);
  const navDataObj = useRef({});

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(actions.commonAction(getPublicationsData));

    return () => {
      dispatch(
        actions.updateWithoutPost({
          type: "CLEAR_PUBLICATIONS_DATA",
          data: {},
        })
      );
    };
  }, [dispatch]);

  /**
   * This function us use to load existed data or load first spinser blank form.
   */
  const loadExistedPublicationData = (publicationDataArr) => {
    let publicationsFieldsClone = [];
    if (publicationDataArr?.length > 0) {
      publicationDataArr.forEach((data, index) => {
        publicationsFieldsClone.push(
          <Publication
            key={index + 1}
            fieldNumber={index + 1}
            onPublicationChage={updatePublicationArray}
            publicationData={data}
          />
        );
      });
      setPublicationCount(publicationDataArr?.length);
    } else {
      publicationsFieldsClone.push(
        <Publication
          onPublicationChage={updatePublicationArray}
          fieldNumber={1}
          key={1}
        />
      );
    }
    setPublicationsFields(publicationsFieldsClone);
  };

  useEffect(() => {
    if (publicationsData?.data) {
      const { publications = [] } = publicationsData?.data;
      loadExistedPublicationData(publications);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicationsData]);

  /**
   * This function call when user change any value from publication form or add new publication
   * @param {*} publicationObj Updated publication object
   */
  const updatePublicationArray = (publicationObj) => {
    if (publicationObj?.fieldNumber) {
      let index = publicationArray.current?.findIndex(
        (data) => data?.fieldNumber === publicationObj?.fieldNumber
      );
      if (index === -1) {
        publicationArray.current?.push(publicationObj);
      } else {
        publicationArray.current[index] = publicationObj;
      }
      setPublicationCount(publicationArray.current?.length);
    }
  };

  /**
   * This function is used to remove only additional fields.
   */
  const removePublication = () => {
    if (publicationsFields.length === MIN_FIELDS) return;
    let CObj = publicationArray.current[publicationCount - 1];
    if (CObj !== undefined) {
      if (CObj?.fieldNumber === publicationCount) {
        publicationArray.current?.pop();
        let currentCount =
          publicationCount > 1 ? publicationCount - 1 : publicationCount;
        setPublicationCount(currentCount);
        setPublicationsFields(
          publicationsFields.slice(0, publicationsFields.length - 1)
        );
      }
    }
  };

  /**
   * This function is used to add more fields in the form
   */
  const addOneMorePublication = () => {
    setPublicationCount(publicationCount + 1);
    setPublicationsFields([
      ...publicationsFields,
      <Publication
        onPublicationChage={updatePublicationArray}
        fieldNumber={publicationCount + 1}
        key={publicationCount + 1}
      />,
    ]);
  };

  /**
   * This function is called when the user tries to save, either by clicking on a side menu item
   * or by clicking one of the three save buttons. Calls addBtnName(). Read that function description for more details.
   * Calls the sendPayload() function afterwards.
   * @param {*} navObject An object from navigate.json or an event object from clicking the save buttons
   * @param {*} location Location object from useLocation(), either from Sidebar or SaveButtons component
   */
  const handleSubmit = (navObject, location) => {
    let payload = {
      publications: [],
    };
    payload.publications = publicationArray.current;

    navDataObj.current = addBtnName(navObject, navigationList, location);

    let params = {
      dispatch: dispatch,
      actionData: postPublicationsData,
      requestBody: payload,
      response: response,
      setResponse: setResponse,
      navObject: navDataObj.current,
      navigate: navigate,
    };

    sendPayload(params);
  };

  if (!styleProps) return null; // Needed so app does not crash on first render
  return (
    <Styled styleProps={styleProps}>
      {response?.show && (
        <ShowResponse
          headerText={response?.headerText}
          message={response?.message}
          show={response?.show}
          handleCancel={() =>
            handleCancelPopUpBtn(
              response,
              setResponse,
              navDataObj.current,
              navigate
            )
          }
          handleOkay={() => handleOkayPopUpBtn(response, setResponse)}
        />
      )}
      <SubPage properties={properties} handleSideMenuSave={handleSubmit}>
        <div
          id="publication-form-body"
          className="form-body-container space-between-text"
        >
          <div id="publication-container">
            <div id="publication-header">
              <h4>Publications</h4>
              <p>
                Any change to previously entered information will be effective
                immediately.
              </p>
              <p>
                List articles or research papers you have published in
                peer-reviewed medical literature within the past 10 years.
                Include article name, journal name and year.
              </p>
              <p>
                Click the <strong>Add</strong> button below if you need to enter
                additional Publications.
              </p>
            </div>
            <div id="publication-items" className="md-space-between-containers">
              {publicationsFields}
              <AddRemoveFieldButtons
                handleAddField={addOneMorePublication}
                handleRemoveField={removePublication}
                disableAddButton={publicationCount >= MAX_FIELDS}
                disableRemoveButton={publicationCount <= MIN_FIELDS}
              />
            </div>
          </div>
          <SaveButtons
            saveGoBack={handleSubmit}
            saveExit={handleSubmit}
            saveContinue={handleSubmit}
          />
        </div>
      </SubPage>
    </Styled>
  );
};

export default ScrollToTop(Publications);
