import React, { useState, useEffect, useRef } from "react";
import Styled from "./styles";
import * as actions from "../../../redux/actions";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ScrollToTop from "../../Common/HOC/ScrollToTop";
import SubPage from "../../Common/SubPage";
import AddRemoveFieldButtons from "../../Common/AddRemoveField";
import ShowResponse from "../../Common/Messages";
import CustomRadio from "../../Common/CustomRadio";
import {
  getLimitationsData,
  postLimitationsData,
  getMasterData,
} from "../../../utils/ModuleActions";
import LimitationItem from "./LimitationItem";
import SaveButtons from "../../Common/SaveButtons";
import {
  addBtnName,
  sendPayload,
  handleCancelPopUpBtn,
  handleOkayPopUpBtn,
} from "../../../utils/Utils";

const Limitations = () => {
  /* ********** STATE VARIABLES ********** */
  const [additionalFields, setAdditionalFields] = useState([]);
  const [limitationItemsCount, setLimitationItemsCount] = useState(1);
  const [limitationsIndc, setLimitationsIndc] = useState(""); // used as state instead of ref since re-render needed to disable Add/Remove buttons
  const [noNewItem, setNoNewItem] = useState(true);
  const [response, setResponse] = useState({
    headerText: "Error message",
    message: "",
    show: false,
  });

  /* ********** REF VARIABLES ********** */
  const limitationItems = useRef([]);
  const navDataObj = useRef({});
  const initialItemCount = useRef(0);

  const properties = {
    titlePage: "CURRENT LIMITATIONS OR CONDITIONS",
    showInstructions: true,
  };
  const { data: styleProps } = useSelector(
    ({ getStyleProps }) => getStyleProps
  );
  const { data: navigationList } = useSelector(({ navigate }) => navigate);
  const { data: limitationsData } = useSelector(
    ({ limitations }) => limitations
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const MIN_FIELDS = 1;
  const MAX_FIELDS = 20;

  useEffect(() => {
    dispatch(actions.commonAction(getLimitationsData));
    dispatch(actions.commonAction(getMasterData));
    dispatch(
      actions.updateWithoutPost({
        type: "UPDATE_LIMITATIONS_INDC",
        data: "",
      })
    );

    /*
    Needed so we do not have incorrect count upon revisit. Only happens if physician adds 2 or more items with the last one being empty.
    UI used to save the POST response into redux. The useEffect would pick that up as a change upon revist and set the count incorrectly.
    Solution would either be to cleanup the effect and clear data or change the backend response to the POST to not have the empty record and save what is actually expected.
    Would have done the second one if we had more time. Since this logic has existed for some of the UI pages already, we will keep using it. Only providing this explaination here.
    */
    return () => {
      dispatch(
        actions.updateWithoutPost({
          type: "CLEAR_COMM_SERVICE_DATA",
          data: {},
        })
      );
    };
  }, [dispatch]);

  /**
   * Used to set up additionalFields when the Limitations page first renders.
   * Handles the use cases of data and no data returning from the server.
   */
  const loadInitialAdditionalFields = () => {
    let additionalFieldsClone = [];
    if (limitationsData?.data?.licenseeLimitations?.length > 0) {
      limitationsData?.data?.licenseeLimitations.forEach((data, index) => {
        additionalFieldsClone.push(
          <LimitationItem
            key={data?.limitationKey}
            fieldNumber={index + 1}
            onLimitationItemChange={updateLimitationItemData}
            limitationItemData={data}
          />
        );
      });
      setLimitationItemsCount(
        limitationsData?.data?.licenseeLimitations?.length
      );
      initialItemCount.current =
        limitationsData?.data?.licenseeLimitations?.length;
    } else {
      additionalFieldsClone.push(
        <LimitationItem
          fieldNumber={1}
          key={1}
          onLimitationItemChange={updateLimitationItemData}
        />
      );
    }
    setAdditionalFields(additionalFieldsClone);
  };

  useEffect(() => {
    // If we call this when there are errorMessages, then all Limitation Item fields reset to empty
    if (limitationsData && !limitationsData?.errorMessages) {
      loadInitialAdditionalFields();
      setLimitationsIndc(limitationsData?.data?.limitation);
      dispatch(
        actions.updateWithoutPost({
          type: "UPDATE_LIMITATIONS_INDC",
          data: limitationsData?.data?.limitation,
        })
      );
    }
    // Adding loadInitialAdditionalFields to the dependency array will cause infinite loop and make Add/Remove stop working
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limitationsData]);

  /**
   * This function is used to update the Limitation Items when the user clicks on the
   * Add Field button and changes a field value
   * @param {*} limitationItemObject Updated Limitation Item object
   */
  const updateLimitationItemData = (limitationItemObject) => {
    if (limitationItemObject?.fieldNumber) {
      let index = limitationItems.current.findIndex(
        (data) => data?.fieldNumber === limitationItemObject?.fieldNumber
      );
      if (index === -1) {
        limitationItems.current.push(limitationItemObject);
      } else {
        limitationItems.current[index] = limitationItemObject;
      }
      setLimitationItemsCount(limitationItems.current.length);
    }
  };

  /**
   * This function is used to update the limitations indicator when the user changes a field value.
   * It also updates the Redux store with the current value of limitationsIndc so the Limitations
   * component can determine whether or not to disable its fields.
   * @param {*} limitationsIndc Updated limitationsIndc value
   */
  const updateLimitationsIndc = (limitationsIndc) => {
    setLimitationsIndc(limitationsIndc);

    let actionData = {
      type: "UPDATE_LIMITATIONS_INDC",
      data: limitationsIndc,
    };
    dispatch(actions.updateWithoutPost(actionData));
  };

  /**
   * This function is called when the user clicks on the Remove Field button and it updates the Limitation Items
   */
  const handleRemoveField = () => {
    if (limitationItemsCount === MIN_FIELDS) return;

    setLimitationItemsCount(limitationItemsCount - 1);
    if (initialItemCount.current === limitationItemsCount - 1) {
      setNoNewItem(true);
    }
    limitationItems.current.pop(); // remove last item
    setAdditionalFields(additionalFields.slice(0, additionalFields.length - 1));
  };

  /**
   * This function is called when the user clicks on the Add Field button and it updates the Limitation Items
   */
  const handleAddField = () => {
    if (limitationItemsCount === MAX_FIELDS) return;

    setLimitationItemsCount(limitationItemsCount + 1);
    setNoNewItem(false);
    setAdditionalFields([
      ...additionalFields,
      <LimitationItem
        fieldNumber={limitationItemsCount + 1}
        key={limitationItemsCount + 1}
        onLimitationItemChange={updateLimitationItemData}
      />,
    ]);
  };

  /**
   * This function is called when the user tries to save, either by clicking on a side menu item
   * or by clicking one of the three save buttons. Calls addBtnName(). Read that function description for more details.
   * Calls the sendPayload() function afterwards.
   * @param {*} navObject An object from navigate.json or an event object from clicking the save buttons
   * @param {*} location Location object from useLocation(), either from Sidebar or SaveButtons component
   */
  const handleSubmit = (navObject, location) => {
    let payload = {
      licenseeLimitations: [],
      limitation: "",
    };
    payload.licenseeLimitations = limitationItems.current;
    payload.limitation = limitationsIndc;

    navDataObj.current = addBtnName(navObject, navigationList, location);

    let dateErrMsgsFlag = dateValidation(payload, setResponse);

    if (dateErrMsgsFlag === false) {

      let params = {
        dispatch: dispatch,
        actionData: postLimitationsData,
        requestBody: payload,
        response: response,
        setResponse: setResponse,
        navObject: navDataObj.current,
        navigate: navigate,
      };

      sendPayload(params);
    }
  };

  function dateValidation(payload, setResponse) {

    let dateErrMsgs = [];
    let dateErrMsgsFlag = false;

    let limitationsArray = payload.licenseeLimitations;
    let effectiveDateErrMsg = "";

    for (let i = 0; i < limitationsArray.length; i++) {

      if (limitationsArray[i].effectiveDate === "Invalid Date") {
        effectiveDateErrMsg = "Please enter a valid Effective Date in Limitation Item " + (i + 1) + ".";
        dateErrMsgs.push(effectiveDateErrMsg);
        dateErrMsgsFlag = true;
      }

    }

    if (dateErrMsgsFlag === true) {
      let userStepsMsg = "Do you wish to remain on this page to correct your data and resubmit your changes? If so click the 'OK' button below. If you prefer you may discard your changes and proceed to the page indicated by the button you clicked. To do this click on the 'Cancel' button to Cancel your updates instead."
      dateErrMsgs.push(userStepsMsg);
      setResponse({
        ...response,
        show: true,
        message: dateErrMsgs.join("\r\n\n"),
      });
    }

    return dateErrMsgsFlag;
  }

  /* ********** FUNCTION COMPONENT RETURN ********** */
  if (!styleProps) return null; // Needed so app does not crash on first render
  return (
    <Styled styleProps={styleProps}>
      {response?.show && (
        <ShowResponse
          headerText={response?.headerText}
          message={response?.message}
          show={response?.show}
          handleCancel={() =>
            handleCancelPopUpBtn(
              response,
              setResponse,
              navDataObj.current,
              navigate
            )
          }
          handleOkay={() => handleOkayPopUpBtn(response, setResponse)}
        />
      )}
      <SubPage properties={properties} handleSideMenuSave={handleSubmit}>
        <div
          id="limitations-form-body"
          className="form-body-container space-between-text"
        >
          <div id="limitations-header">
            <h4>Current Limitations or Conditions</h4>
            <p>
              Any change to previously entered information will be effective
              immediately.
            </p>
            <p>
              Please contact the Physician Help Desk at{" "}
              <strong className="help-desk-number">1-888-338-6998</strong> to
              modify or dispute a limitation.
            </p>
          </div>
          <div id="limitations-body" className="md-space-between-containers">
            <CustomRadio
              value={limitationsIndc}
              formLabel="Are there any current restrictions/limitations or conditions against pursuant to
                Section 230 of the NYS Public Health Law (actions taken by the
                Board of Professional Medical Conduct) or any similar actions
                pursuant to any State, Province or County to a specified area,
                type, scope or condition of practice within the past 10 years?"
              onChangeCustom={(e) => updateLimitationsIndc(e.target.value)}
              ariaLabel="current-limitations"
            />
            <p>
              If "Yes", list the state, province or county, and describe the
              restrictions or limitations:
            </p>
            <div
              id="limitation-items-container"
              className="md-space-between-containers"
            >
              {additionalFields}
              <AddRemoveFieldButtons
                handleAddField={handleAddField}
                handleRemoveField={handleRemoveField}
                disableAddButton={
                  limitationItemsCount >= MAX_FIELDS || limitationsIndc === "N"
                }
                disableRemoveButton={
                  limitationItemsCount <= MIN_FIELDS ||
                  limitationsIndc === "N" ||
                  noNewItem
                }
              />
            </div>
          </div>
          <SaveButtons
            saveGoBack={handleSubmit}
            saveExit={handleSubmit}
            saveContinue={handleSubmit}
          />
        </div>
      </SubPage>
    </Styled>
  );
};

export default ScrollToTop(Limitations);
