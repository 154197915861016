import styled from "styled-components/macro";

export default styled.div`
  .sidebar > h2 {
    color: var(--blue_1);
  }

  li.nav-item {
    font-weight: 600;
    color: var(--blue_1);
    background-color: ${({ isHelpFile }) =>
      isHelpFile ? "transparent" : "var(--white_2)"};
    margin-bottom: ${({ isHelpFile }) => (isHelpFile ? "4px" : "8px")};
    cursor: pointer;
    white-space: nowrap;
    padding: ${({ isHelpFile }) => (isHelpFile ? "0px" : "")};
    .arrow-right-icon {
      visibility: hidden;
      width: 0px;
      height: 0px;
    }
  }

  li.active {
    font-weight: 600;
    background-color: ${({ isHelpFile }) =>
      isHelpFile ? "transparent" : "var(--yellow_1)"};
    color: ${({ isHelpFile }) => (isHelpFile ? "" : "var(--black_1)")};
    .arrow-right-icon {
      visibility: ${({ isHelpFile }) => (isHelpFile ? "visible" : "hidden")};
      width: ${({ isHelpFile }) => (isHelpFile ? "1.5rem" : "0px")};
      height: ${({ isHelpFile }) => (isHelpFile ? "1.5rem" : "0px")};
    }
  }

  .check-icon-clr {
    color: var(--green_1);
  }

  @media only screen and (max-width: ${({ styleProps }) =>
      styleProps?.screenSize?.tablet}) {
    .sidebar {
      width: 13.75rem;
      > h2 {
        color: var(--white_1);
        font-weight: 700;
        border-bottom: solid 3px;
        padding-bottom: 5px;
      }
      ul {
        padding-bottom: 0px;
      }
      li.nav-item {
        color: var(--white_1);
        background-color: transparent;
        border-bottom: solid 1px;
        padding-left: 0px;
        padding-top: 0px;
        padding-bottom: 0px;
        line-height: var(--line_height_36px);
        font-weight: 400;
        margin-bottom: 0px;
      }
      li.active {
        border-color: var(--white_1);
        font-weight: 400;
        padding-top: 0px;
        padding-bottom: 0px;
        .arrow-right-icon {
          visibility: visible;
          width: 1.5rem;
          height: 2rem;
        }
      }
      li svg {
        color: var(--white_1);
      }
      background-color: var(--blue_1);
    }
  }
`;
