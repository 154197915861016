import React, { useState, useEffect, useCallback } from "react";
import Styled from "./styles";
import { useSelector } from "react-redux";
import { Grid, TextField } from "@mui/material";
import { handleObjectChange } from "../../../../utils/Utils";

const CommServiceItem = ({
  commServiceItemData = undefined,
  fieldNumber,
  onCommServiceItemChange,
}) => {
  const [commServiceItem, setCommServiceItem] = useState({
    text: "",
    fieldNumber: fieldNumber,
  });
  const MAX_CHAR_LENGTH = 254;
  const [charCount, setCharCount] = useState(MAX_CHAR_LENGTH);

  const { data: styleProps } = useSelector(
    ({ getStyleProps }) => getStyleProps
  );

  /**
   * Used to set the initial local state for the current Community Service Item being rendered.
   * It uses the data passed in from the parent and adds one more prop called fieldNumber.
   * fieldNumber is needed for the logic of adding, removing, and updating of that data in the field.
   * Note: Initial fields will have all the column attributes from the initial GET request. New fields will have filtered list since that is all that is needed.
   */
  const setDefaultData = useCallback(() => {
    setCommServiceItem({
      ...commServiceItemData,
      fieldNumber: fieldNumber,
    });
    setCharCount(MAX_CHAR_LENGTH - commServiceItemData?.text?.length);
  }, [commServiceItemData, fieldNumber]);

  useEffect(() => {
    if (commServiceItem) {
      onCommServiceItemChange(commServiceItem);
    }
  }, [commServiceItem, onCommServiceItemChange]);

  useEffect(() => {
    if (commServiceItemData) setDefaultData();
  }, [commServiceItemData, setDefaultData]);

  return (
    <Styled styleProps={styleProps}>
      {fieldNumber > 1 && <hr />}
      <div id={`comm-service-item-${fieldNumber}`}>
        <div id={`comm-service-item-header-${fieldNumber}`}>
          <h4>Community Service Item {fieldNumber}</h4>
        </div>
        <Grid
          id={`comm-service-item-body-${fieldNumber}`}
          container
          columnSpacing={3}
          rowSpacing={0}
        >
          <Grid item xs={12} sm={12}>
            <TextField
              id={`org-and-service-desc-${fieldNumber}`}
              className="multiline-textbox"
              label="Organization and Service Description"
              placeholder="Describe the organization and service activity"
              fullWidth
              multiline
              rows={6}
              value={commServiceItem?.text}
              InputLabelProps={{ shrink: true }}
              inputProps={{ maxLength: MAX_CHAR_LENGTH }}
              onChange={(e) => {
                setCharCount(MAX_CHAR_LENGTH - e.target?.value?.length);
                handleObjectChange(
                  e.target.value,
                  "text",
                  commServiceItem,
                  setCommServiceItem
                );
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} container justifyContent="flex-end">
            <p>{charCount} characters</p>
          </Grid>
        </Grid>
      </div>
    </Styled>
  );
};

export default CommServiceItem;
