import { useState, useRef, useEffect } from "react";
import Styled from "./styles";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
} from "@mui/material";
import CustomRadio from "../../../Common/CustomRadio";
import { useSelector } from "react-redux";
import { handleObjectChange } from "../../../../utils/Utils";

export default function Services({ onServiceChange = () => {} }) {
  const [services, setServices] = useState({
    providesTranslationService: null,
    otherLanguage: "",
    translationServices: [],
  });
  const updatedLanguageArr = useRef([]);
  const languageArr = useRef([]);

  /**
   * This function is used to load existed form data in the form
   */
  const setServiceData = (dataArr) => {
    if (dataArr?.languagesCodes?.length > 0 && dataArr?.translationServices) {
      let oLanguage = dataArr?.translationServices.find(
        (tsObj) => tsObj?.languageCode === "OT"
      );
      let existedLangArr = dataArr?.translationServices?.map((obj) => {
        return { languageCode: obj?.languageCode };
      });
      setServices({
        ...services,
        providesTranslationService:
          dataArr?.translationServices[0]?.physicianResponseINDC,
        otherLanguage: oLanguage?.other || "",
        translationServices: existedLangArr,
      });
      languageArr.current = [...dataArr?.languagesCodes];
      updatedLanguageArr.current = existedLangArr;
    }
  };

  const { data: tsArr } = useSelector(
    ({ translationServices }) => translationServices
  );

  useEffect(() => {
    if (tsArr) setServiceData(tsArr?.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tsArr]);

  useEffect(() => {
    if (services) onServiceChange(services);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [services]);

  /**
   * This function is used to handle other languages
   * @param {*} e | event
   */
  const handleOtherLanguages = (e) => {
    if (e?.target?.value !== "") {
      onLanguageChange(true, { value: "OT" });
    } else if (e?.target?.value === "") {
      onLanguageChange(false, { value: "OT" });
    }

    handleObjectChange(
      e?.target?.value,
      "otherLanguage",
      services,
      setServices
    );
  };

  /**
   * This function is used update language Arr
   * @param {*} e element value
   * @param {*} obj Language object
   */
  const onLanguageChange = (isChecked, obj) => {
    let langIndex = updatedLanguageArr.current?.findIndex(
      (data) => data?.languageCode === obj?.value
    );
    if (isChecked === false) {
      if (langIndex > -1) updatedLanguageArr.current.splice(langIndex, 1);
    } else {
      if (langIndex === -1)
        updatedLanguageArr.current.push({
          languageCode: obj?.value,
        });
    }
    setServices({
      ...services,
      translationServices: updatedLanguageArr.current,
    });
  };

  return (
    <Styled>
      <div id="services-container">
        <div id="services-header">
          <h4>Translation or Interpretation Services</h4>
          <p>
            Any change to previously entered information will be effective
            immediately.
          </p>
        </div>
        <div
          id="services-body"
          className="md-space-between-containers services-body space-between-text"
        >
          <Grid container columnSpacing={3} rowSpacing={0}>
            <Grid item xs={12} sm={12}>
              <CustomRadio
                value={services?.providesTranslationService}
                formLabel="Do you have translation or interpretation services available at your primary
                  practice location? Your primary location is where you practice
                  most often regardless of whether it is in private or group
                  practice."
                onChangeCustom={(e) =>
                  handleObjectChange(
                    e?.target?.value,
                    "providesTranslationService",
                    services,
                    setServices
                  )
                }
                ariaLabel="translation-services-available"
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <p className="before-checkboxes">
                If yes, select the languages that are available (Click on the
                box next to the languages that apply)
              </p>
            </Grid>
            {languageArr.current?.length > 0 &&
              languageArr.current.map((obj, index) => {
                if (obj?.value === "OT") return false;
                let langFound = tsArr?.data?.translationServices?.find(
                  (langObj) => langObj?.languageCode === obj?.value
                );
                return (
                  <Grid item xs={12} md={4} sm={6} key={index}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            className="checkbox"
                            name="language"
                            defaultChecked={
                              langFound?.languageCode ? true : null
                            }
                            value={obj?.value}
                            onChange={(e) =>
                              onLanguageChange(e?.target?.checked, obj)
                            }
                          />
                        }
                        className="checkbox-label colorBlack"
                        label={obj?.description}
                      />
                    </FormGroup>
                  </Grid>
                );
              })}
            <Grid item xs={12} sm={12}>
              <p>
                Other languages? Please list in the box below and select other
                above:
              </p>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                className="textfield"
                label="Languages Optional"
                placeholder="Enter other languages not listed above"
                InputLabelProps={{ shrink: true }}
                fullWidth
                onChange={handleOtherLanguages}
                value={services?.otherLanguage}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </Styled>
  );
}
