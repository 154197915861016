import { Popper } from "@mui/material";

const CustomPopper = (props) => {
  return (
    <Popper
      {...props}
      modifiers={[
        {
          name: "flip",
          options: {
            fallbackPlacements: [],
          },
        },
      ]}
      popperOptions={{
        placement: "bottom",
      }}
    />
  );
};

export default CustomPopper;
