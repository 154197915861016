import styled from "styled-components/macro";

export default styled.div`
  .form-body-container hr {
    margin-top: 20px;
  }

  .add-remove-buttons-container {
    margin-top: 20px !important;
  }

  .md-space-between-containers {
    margin-top: 20px !important;
  }
`;
