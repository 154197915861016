import styled from "styled-components/macro";

export default styled.div`
  p > strong {
    white-space: nowrap;
  }

  .form-body-container hr {
    margin-top: 12px;
  }

  .sub-box-info-container table tr td {
    white-space: nowrap;
  }
`;
