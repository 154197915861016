import React, { useState, useEffect, useRef } from "react";
import Styled from "./styles";
import * as actions from "../../../redux/actions";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ScrollToTop from "../../Common/HOC/ScrollToTop";
import SubPage from "../../Common/SubPage";
import AddRemoveFieldButtons from "../../Common/AddRemoveField";
import ShowResponse from "../../Common/Messages";
import CustomRadio from "../../Common/CustomRadio";
import {
  getTeachingData,
  postTeachingData,
} from "../../../utils/ModuleActions";
import MedicalSchoolAppointment from "./MedicalSchoolAppointment";
import SaveButtons from "../../Common/SaveButtons";
import {
  addBtnName,
  sendPayload,
  handleCancelPopUpBtn,
  handleOkayPopUpBtn,
} from "../../../utils/Utils";

const Teaching = () => {
  /* ********** STATE VARIABLES ********** */
  const [additionalFields, setAdditionalFields] = useState([]);
  const [medSchoolApptCount, setMedSchoolApptCount] = useState(1);
  const [servedAsFacultyIndc, setServedAsFacultyIndc] = useState(""); // used as state instead of ref since re-render needed to disable Add/Remove buttons
  const [response, setResponse] = useState({
    headerText: "Error message",
    message: "",
    show: false,
  });

  /* ********** REF VARIABLES ********** */
  const medSchoolApptData = useRef([]);
  const medSchoolRespIndc = useRef("N");
  const navDataObj = useRef({});

  const properties = {
    titlePage: "TEACHING",
    showInstructions: true,
  };
  const { data: styleProps } = useSelector(
    ({ getStyleProps }) => getStyleProps
  );
  const { data: navigationList } = useSelector(({ navigate }) => navigate);
  const { data: teachingData } = useSelector(({ teaching }) => teaching);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const MIN_FIELDS = 1;
  const MAX_FIELDS = 20;

  useEffect(() => {
    dispatch(actions.commonAction(getTeachingData));
    dispatch(
      actions.updateWithoutPost({
        type: "UPDATE_SERVED_AS_FACULTY_INDC",
        data: "",
      })
    );

    return () => {
      dispatch(
        actions.updateWithoutPost({
          type: "CLEAR_TEACHING_DATA",
          data: {},
        })
      );
    };
  }, [dispatch]);

  /**
   * Used to set up additionalFields when the Teaching page first renders.
   * Handles the use cases of data and no data returning from the server.
   */
  const loadInitialAdditionalFields = () => {
    let additionalFieldsClone = [];
    if (teachingData?.data?.medicalSchoolAppointment?.length > 0) {
      teachingData?.data?.medicalSchoolAppointment.forEach((data, index) => {
        additionalFieldsClone.push(
          <MedicalSchoolAppointment
            key={data?.appointmentKey}
            fieldNumber={index + 1}
            onMedSchoolApptChange={updateMedSchoolApptData}
            medSchoolApptData={data}
            nyMedicalSchools={teachingData?.data?.medicalSchools}
          />
        );
      });
      setMedSchoolApptCount(
        teachingData?.data?.medicalSchoolAppointment?.length
      );
    } else {
      additionalFieldsClone.push(
        <MedicalSchoolAppointment
          fieldNumber={1}
          key={1}
          onMedSchoolApptChange={updateMedSchoolApptData}
          nyMedicalSchools={teachingData?.data?.medicalSchools}
        />
      );
    }
    setAdditionalFields(additionalFieldsClone);
  };

  useEffect(() => {
    // If we call this when there are errorMessages, then all Medical School Item fields reset to empty
    if (teachingData && !teachingData?.errorMessages) {
      loadInitialAdditionalFields();
      setServedAsFacultyIndc(teachingData?.data?.servedAsFaculty);
      dispatch(
        actions.updateWithoutPost({
          type: "UPDATE_SERVED_AS_FACULTY_INDC",
          data: teachingData?.data?.servedAsFaculty,
        })
      );
      medSchoolRespIndc.current =
        teachingData?.data?.medicalSchoolResponsibility?.responsibilityIndc;
    }
    // Adding loadInitialAdditionalFields to the dependency array will cause infinite loop and make Add/Remove stop working
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teachingData]);

  /**
   * This function is used to update the Medical School Appointment data when the user clicks on the
   * Add Field button and changes a field value
   * @param {*} medSchoolApptObject Updated Medical School Appointment object
   */
  const updateMedSchoolApptData = (medSchoolApptObject) => {
    if (medSchoolApptObject?.fieldNumber) {
      let index = medSchoolApptData.current.findIndex(
        (data) => data?.fieldNumber === medSchoolApptObject?.fieldNumber
      );
      if (index === -1) {
        medSchoolApptData.current.push(medSchoolApptObject);
      } else {
        medSchoolApptData.current[index] = medSchoolApptObject;
      }
      setMedSchoolApptCount(medSchoolApptData.current.length);
    }
  };

  /**
   * This function is used to update the served as faculty indicator when the user changes a field value.
   * It also updates the Redux store with the current value of servedAsFacultyIndc so the MedicalSchoolAppoinment
   * component can determine whether or not to disable its fields.
   * @param {*} servedAsFacultyIndc Updated served as faculty indicator value
   */
  const updateServedAsFacultyIndc = (servedAsFacultyIndc) => {
    setServedAsFacultyIndc(servedAsFacultyIndc);

    let actionData = {
      type: "UPDATE_SERVED_AS_FACULTY_INDC",
      data: servedAsFacultyIndc,
    };
    dispatch(actions.updateWithoutPost(actionData));
  };

  /**
   * This function is used to update the medical school responsibility indicator when the user changes a field value.
   * @param {*} mediSchoolRespIndc Updated medical school responsibility indicator value
   */
  const updateMedSchoolRespIndc = (mediSchoolRespIndc) => {
    medSchoolRespIndc.current = mediSchoolRespIndc;
  };

  /**
   * This function is called when the user clicks on the Remove Field button and it updates the Medical School Appointment data
   */
  const handleRemoveField = () => {
    if (medSchoolApptCount === MIN_FIELDS) return;

    setMedSchoolApptCount(medSchoolApptCount - 1);
    medSchoolApptData.current.pop(); // remove last item
    setAdditionalFields(additionalFields.slice(0, additionalFields.length - 1));
  };

  /**
   * This function is called when the user clicks on the Add Field button and it updates the Medical School Appointment data
   */
  const handleAddField = () => {
    if (medSchoolApptCount === MAX_FIELDS) return;

    setMedSchoolApptCount(medSchoolApptCount + 1);
    setAdditionalFields([
      ...additionalFields,
      <MedicalSchoolAppointment
        fieldNumber={medSchoolApptCount + 1}
        key={medSchoolApptCount + 1}
        onMedSchoolApptChange={updateMedSchoolApptData}
        nyMedicalSchools={teachingData?.data?.medicalSchools}
      />,
    ]);
  };

  /**
   * This function is called when the user tries to save, either by clicking on a side menu item
   * or by clicking one of the three save buttons. Calls addBtnName(). Read that function description for more details.
   * Calls the sendPayload() function afterwards.
   * @param {*} navObject An object from navigate.json or an event object from clicking the save buttons
   * @param {*} location Location object from useLocation(), either from Sidebar or SaveButtons component
   */
  const handleSubmit = (navObject, location) => {
    let payload = {
      medicalSchoolAppointment: [],
      servedAsFaculty: "",
      medicalSchoolResponsibility: {
        responsibilityIndc: "",
      },
    };
    payload.medicalSchoolAppointment = medSchoolApptData.current;
    payload.servedAsFaculty = servedAsFacultyIndc;
    payload.medicalSchoolResponsibility.responsibilityIndc =
      medSchoolRespIndc.current;

    navDataObj.current = addBtnName(navObject, navigationList, location);

    let params = {
      dispatch: dispatch,
      actionData: postTeachingData,
      requestBody: payload,
      response: response,
      setResponse: setResponse,
      navObject: navDataObj.current,
      navigate: navigate,
    };

    sendPayload(params);
  };

  /* ********** FUNCTION COMPONENT RETURN ********** */
  if (!styleProps) return null; // Needed so app does not crash on first render
  return (
    <Styled styleProps={styleProps}>
      {response?.show && (
        <ShowResponse
          headerText={response?.headerText}
          message={response?.message}
          show={response?.show}
          handleCancel={() =>
            handleCancelPopUpBtn(
              response,
              setResponse,
              navDataObj.current,
              navigate
            )
          }
          handleOkay={() => handleOkayPopUpBtn(response, setResponse)}
        />
      )}
      <SubPage properties={properties} handleSideMenuSave={handleSubmit}>
        <div
          id="teaching-form-body"
          className="form-body-container space-between-text"
        >
          <div id="teaching-header">
            <h4>Teaching</h4>
            <p>
              Any change to previously entered information will be effective
              immediately.
            </p>
          </div>
          <div id="teaching-body" className="md-space-between-containers">
            <CustomRadio
              value={servedAsFacultyIndc}
              formLabel="Have you served as a full-time, part-time or adjunct faculty
                member of a medical school within the past 10 years?"
              onChangeCustom={(e) => updateServedAsFacultyIndc(e.target.value)}
              ariaLabel="served-as-faculty"
            />
            <p>
              If "Yes", list the medical schools and beginning and end dates of
              your appointments.
            </p>
            <hr />
            <div id="medical-school-appointment-container">
              {additionalFields}
              <AddRemoveFieldButtons
                handleAddField={handleAddField}
                handleRemoveField={handleRemoveField}
                disableAddButton={
                  medSchoolApptCount === MAX_FIELDS ||
                  servedAsFacultyIndc === "N"
                }
                disableRemoveButton={
                  medSchoolApptCount === MIN_FIELDS ||
                  servedAsFacultyIndc === "N"
                }
              />
            </div>
            <CustomRadio
              value={medSchoolRespIndc.current}
              formLabel="Were you responsible for teaching residents during your
                appointments?"
              onValueChange={updateMedSchoolRespIndc}
              className="md-space-between-containers"
              ariaLabel="were-you-responsible"
            />
          </div>
          <SaveButtons
            saveGoBack={handleSubmit}
            saveExit={handleSubmit}
            saveContinue={handleSubmit}
          />
        </div>
      </SubPage>
    </Styled>
  );
};

export default ScrollToTop(Teaching);
