import {
  GET_NAVIGATE_LIST_REQUEST,
  GET_NAVIGATE_LIST_SUCCESS,
  GET_NAVIGATE_LIST_ERROR,
} from "../actions/type";

const initialState = {
  loading: null,
  error: null,
  data: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_NAVIGATE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_NAVIGATE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case GET_NAVIGATE_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
