import React, { useEffect, useRef, useState } from "react";
import Styled from "./styles";
import { useDispatch, useSelector } from "react-redux";
import ScrollToTop from "../../Common/HOC/ScrollToTop";
import SubPage from "../../Common/SubPage";
import Sponsor from "./Sponsor";
import AddRemoveFieldButtons from "../../Common/AddRemoveField";
import SaveButtons from "../../Common/SaveButtons";
import * as actions from "../../../redux/actions";
import {
  getGMEData,
  postGMEData,
  getSpecialitiesData,
} from "../../../utils/ModuleActions";
import {
  addBtnName,
  sendPayload,
  handleCancelPopUpBtn,
  handleOkayPopUpBtn,
} from "../../../utils/Utils";
import { useNavigate } from "react-router-dom";
import ShowResponse from "../../Common/Messages";

const GME = () => {
  const properties = {
    titlePage: "GRADUATE MEDICAL EDUCATION",
    showInstructions: true,
  };

  const MIN_FIELDS = 1;
  const MAX_FIELDS = 20;

  const [sponsorCount, setSponsorCount] = useState(1);
  const [additionalFields, setAdditionalFields] = useState([]);
  const [response, setResponse] = useState({
    headerText: "Error message",
    message: "",
    show: false,
  });

  const { data: GMEDataList } = useSelector(({ GMEData }) => GMEData);
  const { data: navigationList } = useSelector(({ navigate }) => navigate);
  const { data: styleProps } = useSelector(
    ({ getStyleProps }) => getStyleProps
  );

  const GMEData = useRef([]);
  const navDataObj = useRef({});

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(actions.commonAction(getGMEData));
    dispatch(actions.commonAction(getSpecialitiesData));

    return () => {
      dispatch(
        actions.updateWithoutPost({
          type: "CLEAR_GME_DATA",
          data: {},
        })
      );
    };
  }, [dispatch]);

  /**
   * This function is used to update the sponsor data when user click on add sponsor button
   * and change some field value
   * @param {*} sponsorObj Updated sponsor object
   */
  const updateGMEData = (sponsorObj) => {
    if (sponsorObj?.sponsorNumber) {
      let index = GMEData.current?.findIndex(
        (data) => data?.sponsorNumber === sponsorObj?.sponsorNumber
      );
      if (index === -1) {
        GMEData.current?.push(sponsorObj);
      } else {
        GMEData.current[index] = sponsorObj;
      }
      setSponsorCount(GMEData.current?.length);
    }
  };

  /**
   * This function us use to load existed sponsor data or load first spinser blank form.
   */
  const loadGMEFormWithData = () => {
    const { data: GMEList } = GMEDataList;
    let additionalFieldsClone = [];
    if (GMEList?.graduateMedicalEducation?.length > 0) {
      GMEList?.graduateMedicalEducation.forEach((data, index) => {
        additionalFieldsClone.push(
          <Sponsor
            key={data?.gmekey}
            onGMEChange={updateGMEData}
            fieldNumber={index + 1}
            GMEData={data}
          />
        );
      });
    } else {
      additionalFieldsClone.push(
        <Sponsor key={1} onGMEChange={updateGMEData} fieldNumber={1} />
      );
    }
    setAdditionalFields(additionalFieldsClone);
  };

  useEffect(() => {
    if (GMEDataList) loadGMEFormWithData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [GMEDataList]);

  /**
   * This function calls when user click on remove a field button and it is used to
   * update the sponsor data when user clicks on remove button
   */
  const handleRemoveField = () => {
    if (additionalFields.length === MIN_FIELDS) return;

    let GMEObj = GMEData.current[sponsorCount - 1];
    if (GMEObj !== undefined) {
      if (GMEObj?.sponsorNumber === sponsorCount) {
        GMEData.current?.pop();
        let currentCount = sponsorCount > 1 ? sponsorCount - 1 : sponsorCount;
        setSponsorCount(currentCount);
        setAdditionalFields(
          additionalFields.slice(0, additionalFields.length - 1)
        );
      }
    }
  };

  const handleAddField = () => {
    setSponsorCount(sponsorCount + 1);
    setAdditionalFields([
      ...additionalFields,
      <Sponsor
        fieldNumber={sponsorCount + 1}
        onGMEChange={updateGMEData}
        key={sponsorCount + 1}
      />,
    ]);
  };

  /**
   * This function is called when the user tries to save, either by clicking on a side menu item
   * or by clicking one of the three save buttons. Calls addBtnName(). Read that function description for more details.
   * Calls the sendPayload() function afterwards.
   * @param {*} navObject An object from navigate.json or an event object from clicking the save buttons
   * @param {*} location Location object from useLocation(), either from Sidebar or SaveButtons component
   */
  const handleSubmit = (navObject, location) => {
    let payload = {
      graduateMedicalEducation: [],
    };
    payload.graduateMedicalEducation = GMEData.current;

    navDataObj.current = addBtnName(navObject, navigationList, location);
  
    let dateErrMsgsFlag = dateValidation(payload, setResponse);

    if (dateErrMsgsFlag === false) {

      let params = {
        dispatch: dispatch,
        actionData: postGMEData,
        requestBody: payload,
        response: response,
        setResponse: setResponse,
        navObject: navDataObj.current,
        navigate: navigate,
      };

      sendPayload(params);
    }

  };

  function dateValidation(payload, setResponse) {

    let dateErrMsgs = [];
    let dateErrMsgsFlag = false;

    let gmeArray = payload.graduateMedicalEducation;
    let startDateErrMsg = "";
    let endDateErrMsg = "";

    for (let i = 0; i < gmeArray.length; i++) {

      if (gmeArray[i].gmestartDate === "Invalid Date") {
        startDateErrMsg = "Please enter a valid Start Date in GME Section " + (i + 1) + ".";
        dateErrMsgs.push(startDateErrMsg);
        dateErrMsgsFlag = true;
      }

      if (gmeArray[i].gmeendDate === "Invalid Date") {
        endDateErrMsg = "Please enter a valid Completion Date in GME Section " + (i + 1) + ".";
        dateErrMsgs.push(endDateErrMsg);
        dateErrMsgsFlag = true;
      }
    }

    if (dateErrMsgsFlag === true) {
      let userStepsMsg = "Do you wish to remain on this page to correct your data and resubmit your changes? If so click the 'OK' button below. If you prefer you may discard your changes and proceed to the page indicated by the button you clicked. To do this click on the 'Cancel' button to Cancel your updates instead."
      dateErrMsgs.push(userStepsMsg);
      setResponse({
        ...response,
        show: true,
        message: dateErrMsgs.join("\r\n\n"),
      });
    }

    return dateErrMsgsFlag;
  }

  if (!styleProps) return null; // Needed so app does not crash on first render
  return (
    <Styled styleProps={styleProps}>
      {response?.show && (
        <ShowResponse
          headerText={response?.headerText}
          message={response?.message}
          show={response?.show}
          handleCancel={() =>
            handleCancelPopUpBtn(
              response,
              setResponse,
              navDataObj.current,
              navigate
            )
          }
          handleOkay={() => handleOkayPopUpBtn(response, setResponse)}
        />
      )}
      <SubPage properties={properties} handleSideMenuSave={handleSubmit}>
        <div
          id="gme-form-body"
          className="form-body-container space-between-text"
        >
          <div id="gme-container">
            <div id="gme-header">
              <h4>Graduate Medical Education</h4>
              <p>
                <strong>(ACGME, AOA, or RCPSC only)</strong>
              </p>
              <p>Enter requested change in the space provided.</p>
              <p>
                Click the <strong>Add</strong> button below if you need to enter
                addtional GME Sponsors.
              </p>
            </div>
            <div id="sponser-items" className="md-space-between-containers">
              {additionalFields}
              <AddRemoveFieldButtons
                handleAddField={handleAddField}
                handleRemoveField={handleRemoveField}
                disableAddButton={sponsorCount >= MAX_FIELDS}
                disableRemoveButton={sponsorCount <= MIN_FIELDS}
              />
            </div>
          </div>
          <SaveButtons
            saveGoBack={handleSubmit}
            saveExit={handleSubmit}
            saveContinue={handleSubmit}
          />
        </div>
      </SubPage>
    </Styled>
  );
};

export default ScrollToTop(GME);
