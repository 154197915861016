import styled from "styled-components/macro";

export default styled.div`
  #restriction-header p:first-of-type,
  #failure-to-renew-header h4:first-of-type {
    margin-bottom: 16px;
  }

  .form-body-container {
    > hr {
      margin-top: 20px;
    }
  }
`;
