import { useState, useEffect } from "react";
import Styled from "./styles";
import TextField from "@mui/material/TextField";
import { Grid } from "@mui/material";
import { handleObjectChange } from "../../../../utils/Utils";

export default function TextQuestion({
  fieldNumber = "",
  questionLabel = "",
  placeholder = "",
  onDataChange = () => {},
  questionData = undefined,
}) {
  const MAX_CHAR_LENGTH = 254;
  const [questionObj, setQuestionObj] = useState({
    fieldNumber: fieldNumber,
    textValue: "",
  });

  /**
   * This function is used to load existed form data in the form
   */
  const setQuestionData = () => {
    if (questionData?.questionKey) {
      // setQuestionObj({
      //   ...questionObj,
      //   ...questionData,
      // });
    }
  };

  useEffect(() => {
    if (questionData) setQuestionData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionData]);

  useEffect(() => {
    if (questionObj) onDataChange(questionObj);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionObj]);

  return (
    <Styled>
      <div className={`question-text-item`}>
        <Grid
          className={`question-text-body`}
          container
          columnSpacing={3}
          rowSpacing={0}
        >
          <Grid item xs={12} sm={12}>
            <p>{`${fieldNumber}. ${questionLabel}`}</p>
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              placeholder={placeholder}
              className="multiline-textbox"
              fullWidth
              inputProps={{ maxLength: MAX_CHAR_LENGTH }}
              value={questionObj?.textValue}
              onChange={(e) => {
                handleObjectChange(
                  e.target?.value,
                  "textValue",
                  questionObj,
                  setQuestionObj
                );
              }}
            />
          </Grid>
        </Grid>
      </div>
    </Styled>
  );
}
