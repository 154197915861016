import { useState, useEffect } from "react";
import Styled from "./styles";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import {
  handleObjectChange,
  handleDropdownList,
  disableField,
} from "../../../../utils/Utils";
import CustomPopper from "../../../Common/CustomPopper";

export default function FailureToRenew({
  fieldNumber,
  onFailureToRenewChange = () => {},
  failureToRenewData = undefined,
}) {
  const MAX_CHAR_FACILITY_LENGTH = 100;
  const MAX_CHAR_ACTION_LENGTH = 500;

  const [stateOptions, setStateOptions] = useState([]);
  const [failureToRenew, setFailureToRenew] = useState({
    state: null,
    renewDate: null,
    facility: "",
    actionTaken: "",
    fieldNumber: fieldNumber,
  });

  const { data: styleProps } = useSelector(
    ({ getStyleProps }) => getStyleProps
  );
  const { data: stateList } = useSelector(({ getMaster }) => getMaster);
  const { failureToRenewIndc } = useSelector(
    ({ updatedIndicator }) => updatedIndicator?.data
  );

  /**
   * This function is used to load existed form data in the form
   */
  const setExistedData = () => {
    if (failureToRenewData?.state) {
      setFailureToRenew({
        ...failureToRenew,
        ...failureToRenewData,
      });
    }
  };

  useEffect(() => {
    if (failureToRenewData) setExistedData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [failureToRenewData]);

  useEffect(() => {
    if (failureToRenew) onFailureToRenewChange(failureToRenew);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [failureToRenew]);

  useEffect(() => {
    if (stateList?.data?.states) setStateOptions(stateList?.data?.states);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateList]);

  return (
    <Styled styleProps={styleProps}>
      {fieldNumber > 1 && <hr />}
      <div id={`failure-to-renew-item-${fieldNumber}`}>
        <div id={`failure-to-renew-item-header-${fieldNumber}`}>
          <h4>Failure to Renew Item {fieldNumber}</h4>
        </div>
        <Grid
          id={`failure-to-renew-item-body-${fieldNumber}`}
          container
          columnSpacing={3}
          rowSpacing={0}
        >
          <Grid item xs={12} sm={6} md={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Date (MM-DD-YYYY)"
                inputFormat="MM-DD-YYYY"
                name={`failure-to-renew-date-${fieldNumber}`}
                disabled={disableField(
                  !failureToRenew?.elementkey,
                  fieldNumber,
                  failureToRenewIndc
                )}
                value={
                  failureToRenew?.renewDate
                    ? dayjs(failureToRenew?.renewDate)
                    : null
                }
                onChange={(date) =>
                  handleObjectChange(
                    date ? dayjs(date).format("YYYY-MM-DD") : null,
                    "renewDate",
                    failureToRenew,
                    setFailureToRenew
                  )
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: "MM-DD-YYYY",
                    }}
                  />
                )}
                className="datepicker"
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Autocomplete
              id={`failure-to-renew-state-${fieldNumber}`}
              name={`failure_to_renew_state_${fieldNumber}`}
              options={stateOptions}
              getOptionLabel={(option) => option?.description}
              disabled={disableField(
                !failureToRenew?.elementkey,
                fieldNumber,
                failureToRenewIndc
              )}
              value={
                failureToRenew?.state
                  ? stateOptions.find(
                      (state) => state?.value === failureToRenew?.state
                    )
                  : null
              }
              onChange={(e, newValue) => {
                setFailureToRenew({
                  ...failureToRenew,
                  state: newValue?.value,
                });
              }}
              isOptionEqualToValue={(option, currValue) =>
                option?.value === currValue?.value
              }
              renderOption={(props, option) =>
                handleDropdownList(props, option.value, option.description)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="State"
                  placeholder="Select a state"
                  InputLabelProps={{ shrink: true }}
                />
              )}
              PopperComponent={(props) => <CustomPopper {...props} />}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              label="Enter the facility"
              className="multiline-textbox"
              fullWidth
              InputLabelProps={{ shrink: true }}
              inputProps={{ maxLength: MAX_CHAR_FACILITY_LENGTH }}
              value={failureToRenew?.facility}
              disabled={disableField(
                !failureToRenew?.elementkey,
                fieldNumber,
                failureToRenewIndc
              )}
              onChange={(e) => {
                handleObjectChange(
                  e.target?.value,
                  "facility",
                  failureToRenew,
                  setFailureToRenew
                );
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} container justifyContent="flex-end">
            <p>
              {MAX_CHAR_FACILITY_LENGTH - failureToRenew?.facility?.length}{" "}
              characters
            </p>
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              label="Describe the action taken"
              className="multiline-textbox"
              multiline
              fullWidth
              rows={6}
              InputLabelProps={{ shrink: true }}
              inputProps={{ maxLength: MAX_CHAR_ACTION_LENGTH }}
              value={failureToRenew?.actionTaken}
              disabled={disableField(
                !failureToRenew?.elementkey,
                fieldNumber,
                failureToRenewIndc
              )}
              onChange={(e) => {
                handleObjectChange(
                  e.target?.value,
                  "actionTaken",
                  failureToRenew,
                  setFailureToRenew
                );
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} container justifyContent="flex-end">
            <p>
              {MAX_CHAR_ACTION_LENGTH - failureToRenew?.actionTaken?.length}{" "}
              characters
            </p>
          </Grid>
        </Grid>
      </div>
    </Styled>
  );
}
