import styled from "styled-components/macro";

export default styled.div`
  table {
    border-collapse: collapse;
  }

  tr th {
    color: var(--blue_1);
    border-bottom: solid 1px;
    padding: 0 5px 5px 5px;
    line-height: var(--line_height_20_25px);
  }

  tr td:first-of-type {
    width: 22.2222rem;
  }

  .td-padding {
    padding-bottom: 10px;
  }
`;
