import React from "react";
import Styled from "./styles";
import { useSelector } from "react-redux";
import ScrollToTop from "../../Common/HOC/ScrollToTop";
import SubPage from "../../Common/SubPage";
import SaveButtons from "../../Common/SaveButtons";

const Logout = () => {
  const properties = {
    titlePage: "YOU ARE LOGGED OUT",
    showInstructions: false,
  };

  const { data: styleProps } = useSelector(
    ({ getStyleProps }) => getStyleProps
  );

  let logoutUrl = localStorage.getItem("logoutUrl");
  localStorage.removeItem("authToken");
  localStorage.removeItem("code_verifier");
  localStorage.removeItem("state");
  localStorage.removeItem("logoutUrl");

  if (logoutUrl) {
    console.log("logoutUrl", logoutUrl);
    window.location.replace(logoutUrl);
  }
  
  if (!styleProps) return null; // Needed so app does not crash on first render
  return (
    <Styled styleProps={styleProps}>
      <SubPage properties={properties} isLogout={true}>
        <div
          id="logout-form-body"
          className="form-body-container space-between-text"
        >
          <div id="logout-container">
            <div id="logout-header">
              <p>
                <strong>Your changes have been processed.</strong>
              </p>
              <p>
                You are now logged out of the Physician Profile System. You may{" "}
                <strong>log in</strong> again at any time to Finish your survey.
              </p>
              <p>
                If you have logged out accidentally and wish to log in again,
                please use the button below to return to the HPN log in page.
              </p>
              <SaveButtons
                saveExit={() =>
                  (window.location.href =
                    "https://commerce.health.state.ny.us/public/hcs_login.html")
                }
                buttonsProp={{
                  saveExit: {
                    show: true,
                    class: "min-width-200 btn-fill",
                  },
                }}
                exitButtonName="LOG IN"
              />
            </div>
          </div>
        </div>
      </SubPage>
    </Styled>
  );
};

export default ScrollToTop(Logout);
