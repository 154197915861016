import { useSelector } from "react-redux";
import Styled from "./styles";
import dayjs from "dayjs";

const SubBoxInfo = ({ data, tableName='' }) => {
  const { data: styleProps } = useSelector(
    ({ getStyleProps }) => getStyleProps
  );

  return (
    <Styled styleProps={styleProps}>
      <div className="sub-box-info-container">
        <table aria-label={tableName}>
          <tbody>
            {data.map((fieldObj, index) => (
              <tr key={index}>
                <th scope="row">{`${fieldObj?.displayName}:`}</th>
                <td>
                  {fieldObj?.isDate
                    ? dayjs(fieldObj?.fieldValue).format("MM-DD-YYYY")
                    : fieldObj?.fieldValue}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Styled>
  );
};

export default SubBoxInfo;
