import React, { useState, useEffect, useRef } from "react";
import Styled from "./styles";
import * as actions from "../../../redux/actions";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ScrollToTop from "../../Common/HOC/ScrollToTop";
import SubPage from "../../Common/SubPage";
import AddRemoveFieldButtons from "../../Common/AddRemoveField";
import ShowResponse from "../../Common/Messages";
import {
  getPracticeData,
  postPracticeData,
  getMasterData,
} from "../../../utils/ModuleActions";
import PracticeItem from "./PracticeItem";
import SaveButtons from "../../Common/SaveButtons";
import {
  addBtnName,
  sendPayload,
  handleCancelPopUpBtn,
  handleOkayPopUpBtn,
} from "../../../utils/Utils";

const Practice = () => {
  /* ********** STATE VARIABLES ********** */
  const [additionalFields, setAdditionalFields] = useState([]);
  const [practiceItemsCount, setPracticeItemsCount] = useState(1);
  const [response, setResponse] = useState({
    headerText: "Error message",
    message: "",
    show: false,
  });

  /* ********** REF VARIABLES ********** */
  const practiceItems = useRef([]);
  const navDataObj = useRef({});

  const properties = {
    titlePage: "PRACTICE INFORMATION (OPTIONAL)",
    showInstructions: true,
  };
  const { data: styleProps } = useSelector(
    ({ getStyleProps }) => getStyleProps
  );
  const { data: navigationList } = useSelector(({ navigate }) => navigate);
  const { data: practiceData } = useSelector(({ practice }) => practice);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const MIN_FIELDS = 1;
  const MAX_FIELDS = 20;

  useEffect(() => {
    dispatch(actions.commonAction(getPracticeData));
    dispatch(actions.commonAction(getMasterData, { type: "county" })); // backend returns both state and counties

    return () => {
      dispatch(
        actions.updateWithoutPost({
          type: "CLEAR_PRACTICE_DATA",
          data: {},
        })
      );
    };
  }, [dispatch]);

  /**
   * Used to set up additionalFields when the Practice page first renders.
   * Handles the use cases of data and no data returning from the server.
   */
  const loadInitialAdditionalFields = () => {
    let additionalFieldsClone = [];
    if (practiceData?.data?.practices?.length > 0) {
      practiceData?.data?.practices.forEach((data, index) => {
        additionalFieldsClone.push(
          <PracticeItem
            key={index + 1}
            fieldNumber={index + 1}
            onPracticeItemChange={updatePracticeItemData}
            practiceItemData={data}
          />
        );
      });
      setPracticeItemsCount(practiceData?.data?.practices?.length);
    } else {
      additionalFieldsClone.push(
        <PracticeItem
          fieldNumber={1}
          key={1}
          onPracticeItemChange={updatePracticeItemData}
        />
      );
    }
    setAdditionalFields(additionalFieldsClone);
  };

  useEffect(() => {
    // If we call this when there are errorMessages, then all Practice Item fields reset to empty
    if (practiceData && !practiceData?.errorMessages) {
      loadInitialAdditionalFields();
    }
    // Adding loadInitialAdditionalFields to the dependency array will cause infinite loop and make Add/Remove stop working
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [practiceData]);

  /**
   * This function is used to update the Practice Items when the user clicks on the
   * Add Field button and changes a field value
   * @param {*} practiceItemObject | Updated Practice Item object
   */
  const updatePracticeItemData = (practiceItemObject) => {
    if (practiceItemObject?.fieldNumber) {
      let index = practiceItems.current.findIndex(
        (data) => data?.fieldNumber === practiceItemObject?.fieldNumber
      );
      if (index === -1) {
        practiceItems.current.push(practiceItemObject);
      } else {
        practiceItems.current[index] = practiceItemObject;
      }
      setPracticeItemsCount(practiceItems.current.length);
    }
  };

  /**
   * This function is called when the user clicks on the Remove Field button and it updates the Practice Items
   */
  const handleRemoveField = () => {
    if (practiceItemsCount === MIN_FIELDS) return;

    setPracticeItemsCount(practiceItemsCount - 1);
    practiceItems.current.pop(); // remove last item
    setAdditionalFields(additionalFields.slice(0, additionalFields.length - 1));
  };

  /**
   * This function is called when the user clicks on the Add Field button and it updates the Practice Items
   */
  const handleAddField = () => {
    if (practiceItemsCount === MAX_FIELDS) return;

    setPracticeItemsCount(practiceItemsCount + 1);
    setAdditionalFields([
      ...additionalFields,
      <PracticeItem
        fieldNumber={practiceItemsCount + 1}
        key={practiceItemsCount + 1}
        onPracticeItemChange={updatePracticeItemData}
      />,
    ]);
  };

  /**
   * This function is called when the user tries to save, either by clicking on a side menu item
   * or by clicking one of the three save buttons. Calls addBtnName(). Read that function description for more details.
   * Calls the sendPayload() function afterwards.
   * @param {*} navObject An object from navigate.json or an event object from clicking the save buttons
   * @param {*} location Location object from useLocation(), either from Sidebar or SaveButtons component
   */
  const handleSubmit = (navObject, location) => {
    let payload = {
      practices: [],
    };
    payload.practices = practiceItems.current;

    navDataObj.current = addBtnName(navObject, navigationList, location);

    let params = {
      dispatch: dispatch,
      actionData: postPracticeData,
      requestBody: payload,
      response: response,
      setResponse: setResponse,
      navObject: navDataObj.current,
      navigate: navigate,
    };

    sendPayload(params);
  };

  /* ********** FUNCTION COMPONENT RETURN ********** */
  if (!styleProps) return null; // Needed so app does not crash on first render
  return (
    <Styled styleProps={styleProps}>
      {response?.show && (
        <ShowResponse
          headerText={response?.headerText}
          message={response?.message}
          show={response?.show}
          handleCancel={() =>
            handleCancelPopUpBtn(
              response,
              setResponse,
              navDataObj.current,
              navigate
            )
          }
          handleOkay={() => handleOkayPopUpBtn(response, setResponse)}
        />
      )}
      <SubPage properties={properties} handleSideMenuSave={handleSubmit}>
        <div
          id="practice-form-body"
          className="form-body-container space-between-text"
        >
          <div id="practice-header">
            <h4>Practice Information</h4>
            <p>
              Any change to previously entered information will be effective
              immediately.
            </p>
            <p>
              Enter information about your practice(s). If you practice at more
              than one office, list in order of where you practice most often.
              The <strong>Add field(s)</strong> button will create the fields
              needed for you to enter the additional information.
            </p>
            <p>
              If you wish to add an additional practice location see the{" "}
              <strong>Add</strong> button below.
            </p>
          </div>
          <div
            id="practice-items-container"
            className="md-space-between-containers"
          >
            {additionalFields}
            <hr />
            <AddRemoveFieldButtons
              handleAddField={handleAddField}
              handleRemoveField={handleRemoveField}
              disableAddButton={practiceItemsCount === MAX_FIELDS}
              disableRemoveButton={practiceItemsCount === MIN_FIELDS}
            />
          </div>
          <SaveButtons
            saveGoBack={handleSubmit}
            saveExit={handleSubmit}
            saveContinue={handleSubmit}
          />
        </div>
      </SubPage>
    </Styled>
  );
};

export default ScrollToTop(Practice);
