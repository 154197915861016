import styled from "styled-components/macro";

export default styled.div`
  .form-instructions-container {
    margin-top: 30px;
    font-size: var(--font_size_16px);
  }
  .form-instructions-container .marginBottom8 {
    margin-bottom: 8px;
  }
`;
