import styled from "styled-components/macro";

/* 
This file replaces index.css for the most part but index.css is still used for the HTML elements that come before this Styled div in the DOM Tree.
index.css also needed for Dialog and Drawer components due to where it is rendered in the DOM tree.
*/
export default styled.div`
  /* Common Classes */

  .colorBlue {
    color: var(--blue_1) !important;
  }
  .colorBlack {
    color: var(--black_1);
  }
  .colorGrey {
    color: var(--gray_1) !important;
  }
  .colorGrey4 {
    color: var(--gray_4);
  }

  .paddingTop30px {
    padding-top: 30px;
  }
  .paddingTop20px {
    padding-top: 20px;
  }
  .paddingTop10px {
    padding-top: 10px;
  }
  .padding0px {
    padding: 0px !important;
  }

  .form-body-container {
    background-color: var(--blue_3);
    padding: 26px 30px 20px 30px;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    hr {
      border-top: solid 1px;
      margin: 25px 0;
      color: var(--blue_1);
    }
    @media only screen and (max-width: 600px) {
      padding: 26px 15px 20px 15px;
    }
  }

  .MuiTextField-root {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .MuiButtonBase-root.MuiRadio-root.Mui-checked,
  .MuiButtonBase-root.MuiCheckbox-root.Mui-checked,
  .MuiFormLabel-root.MuiInputLabel-root.Mui-focused,
  .Mui-focused .MuiOutlinedInput-notchedOutline {
    color: var(--blue_1) !important;
    border-color: var(--blue_1) !important;
  }
  .MuiFormLabel-root,
  .MuiFormLabel-root.Mui-focused {
    color: var(--black_1);
  }
  .MuiFormLabel-root.MuiInputLabel-root.Mui-error,
  .MuiInputBase-root.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
    color: var(--red_1) !important;
    border-color: var(--red_1) !important;
  }
  .MuiFormGroup-root > .MuiFormControlLabel-root {
    margin-right: 30px;
  }

  .space-between-text {
    h4,
    p {
      margin-bottom: 8px;
      }
    }
    p:last-of-type {
      margin-bottom: 0px;
    }
  }

  .no-divider-item {
    // no divider item spacing
    // Use to distinguish next item from previous item. Override with custom margin-bottom if desired.
    margin-bottom: 15px;
  }

  .sm-space-between-containers {
    margin-top: 8px;
    // Use this class to add spacing between containers that should have a small space between a header and it's body
    // Only happens when header has more than an h4 tag inside. If it ends in a p tag, the margin bottom will sometimes be 8px or sometimes 0px
    // If there is only an h4 tag in the header, the h4 tag has a margin bottom of 8px always. Therefore, this class is not needed in that case
  }

  .md-space-between-containers {
    // Use this class to add spacing between containers that should have a medium space between a header and it's body
    margin-top: 30px !important;
  }

  .multiline-textbox {
    margin-bottom: 6px;
  }

  .datepicker {
    width: 100%;
  }

  .datepicker .MuiButtonBase-root {
    color: var(--gray_5);
  }

  .help-file-bg {
    background-color: var(--blue_3);
  }

  .disable-button {
    color: var(--gray_2)
  }

  .enable-button {
    color: var(--blue_1)
  }

  .help-desk-number {
    white-space: pre;
  }
`;
