import styled from "styled-components/macro";

export default styled.div`
  .sub-box-info-container {
    padding: 10px 20px 20px 20px;
    background-color: var(--blue_2);
    margin-top: 14px;
    @media only screen and (max-width: ${({ styleProps }) =>
        styleProps?.screenSize?.mobile}) {
      padding: 10px 15px 20px 15px;
    }
    table {
      width: 100%;
      border-collapse: collapse;
      tr {
        border-bottom: solid 1px;
        border-color: var(--blue_1);
        :last-of-type {
          border-bottom: 0;
        }
        th {
          text-align: left;
        }
        th p {
          margin-bottom: 15px !important;
        }
        td {
          vertical-align: top;
          padding: 5px 0;
          :first-child {
            text-align: start;
            font-weight: 700;
            width: 12.5rem;
            padding-right: 28px;
            white-space: pre-line;
          }
        }
      }
    }
  }
  .instruction-text {
    font-weight: 700;
    padding-left: 25px;
  }
`;
