import React, { useEffect, useRef, useState } from "react";
import Styled from "./styles";
import { useDispatch, useSelector } from "react-redux";
import ScrollToTop from "../../Common/HOC/ScrollToTop";
import SubPage from "../../Common/SubPage";
import AddRemoveFieldButtons from "../../Common/AddRemoveField";
import SaveButtons from "../../Common/SaveButtons";
import * as actions from "../../../redux/actions";
import {
  getProfMembershipData,
  postProfMembershipData,
} from "../../../utils/ModuleActions";
import Membership from "./Membership";
import { useNavigate } from "react-router";
import ShowResponse from "../../Common/Messages";
import {
  addBtnName,
  sendPayload,
  handleCancelPopUpBtn,
  handleOkayPopUpBtn,
} from "../../../utils/Utils";

const ProfMemberships = () => {
  const properties = {
    titlePage: "PROFESSIONAL MEMBERSHIPS (OPTIONAL)",
    showInstructions: true,
  };

  const MIN_FIELDS = 1;
  const MAX_FIELDS = 20;

  const [membershipCount, SetMembershipCount] = useState(1);
  const [additionalFields, setAdditionalFields] = useState([]);

  const [response, setResponse] = useState({
    headerText: "Error message",
    message: "",
    show: false,
  });

  const { data: navigationList } = useSelector(({ navigate }) => navigate);
  const { data: styleProps } = useSelector(
    ({ getStyleProps }) => getStyleProps
  );
  const { data: membershipList } = useSelector(
    ({ profMembership }) => profMembership
  );

  const membershipData = useRef([]);
  const navDataObj = useRef({});

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(actions.commonAction(getProfMembershipData));

    return () => {
      dispatch(
        actions.updateWithoutPost({
          type: "CLEAR_PROF_MEMBERSHIP_DATA",
          data: {},
        })
      );
    };
  }, [dispatch]);

  /**
   * This function is used to update the membership data when user click on add field button
   * or change some field value
   * @param {*} membershipObj Updated membership object
   */
  const updateMembershipData = (membershipObj) => {
    if (membershipObj?.fieldNumber) {
      let index = membershipData.current?.findIndex(
        (data) => data?.fieldNumber === membershipObj?.fieldNumber
      );
      if (index === -1) {
        membershipData.current?.push(membershipObj);
      } else {
        membershipData.current[index] = membershipObj;
      }
      SetMembershipCount(membershipData.current?.length);
    }
  };

  /**
   * This function us use to load existed data or load first blank form.
   */
  const loadMembershipWithData = () => {
    const { data: membershipArr } = membershipList;
    let additionalFieldsClone = [];
    if (membershipArr?.professionalMemberships?.length > 0) {
      membershipArr?.professionalMemberships.forEach((data, index) => {
        additionalFieldsClone.push(
          <Membership
            key={index + 1}
            onMembershipChange={updateMembershipData}
            fieldNumber={index + 1}
            membershipData={data}
          />
        );
      });
      SetMembershipCount(membershipArr?.professionalMemberships?.length);
    } else {
      additionalFieldsClone.push(
        <Membership
          key={1}
          onMembershipChange={updateMembershipData}
          fieldNumber={1}
        />
      );
    }
    setAdditionalFields(additionalFieldsClone);
  };

  useEffect(() => {
    if (membershipList) loadMembershipWithData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [membershipList]);

  /**
   * This function calls when user click on remove a field button and it is used to
   * update the membership data when user clicks on remove button
   */
  const handleRemoveField = () => {
    if (additionalFields.length === MIN_FIELDS) return;

    let membershipObj = membershipData.current[membershipCount - 1];
    if (membershipObj !== undefined) {
      if (membershipObj?.fieldNumber === membershipCount) {
        if (
          additionalFields.length <=
          membershipList?.data?.professionalMemberships?.length
        ) {
          membershipData.current[membershipCount - 1].organization = "";
        } else {
          membershipData.current?.pop();
        }

        let currentCount =
          membershipCount > 1 ? membershipCount - 1 : membershipCount;
        SetMembershipCount(currentCount);
        setAdditionalFields(
          additionalFields.slice(0, additionalFields.length - 1)
        );
      }
    }
  };

  const handleAddField = () => {
    SetMembershipCount(membershipCount + 1);
    setAdditionalFields([
      ...additionalFields,
      <Membership
        fieldNumber={membershipCount + 1}
        onMembershipChange={updateMembershipData}
        key={membershipCount + 1}
      />,
    ]);
  };

  /**
   * This function is called when the user tries to save, either by clicking on a side menu item
   * or by clicking one of the three save buttons. Calls addBtnName(). Read that function description for more details.
   * Calls the sendPayload() function afterwards.
   * @param {*} navObject An object from navigate.json or an event object from clicking the save buttons
   * @param {*} location Location object from useLocation(), either from Sidebar or SaveButtons component
   */
  const handleSubmit = (navObject, location) => {
    let payload = {
      professionalMemberships: [],
    };
    payload.professionalMemberships = membershipData.current;

    navDataObj.current = addBtnName(navObject, navigationList, location);

    let params = {
      dispatch: dispatch,
      actionData: postProfMembershipData,
      requestBody: payload,
      response: response,
      setResponse: setResponse,
      navObject: navDataObj.current,
      navigate: navigate,
    };

    sendPayload(params);
  };

  if (!styleProps) return null; // Needed so app does not crash on first render
  return (
    <Styled styleProps={styleProps}>
      {response?.show && (
        <ShowResponse
          headerText={response?.headerText}
          message={response?.message}
          show={response?.show}
          handleCancel={() =>
            handleCancelPopUpBtn(
              response,
              setResponse,
              navDataObj.current,
              navigate
            )
          }
          handleOkay={() => handleOkayPopUpBtn(response, setResponse)}
        />
      )}
      <SubPage properties={properties} handleSideMenuSave={handleSubmit}>
        <div
          id="prof-membership-form-body"
          className="form-body-container space-between-text"
        >
          <div id="prof-membership-header">
            <h4>Professional Memberships</h4>
            <p>
              Any change to previously entered information will be effective
              immediately.
            </p>
            <p>List your professional memberships.</p>
            <p>
              Click the <strong>Add</strong> button below if you need to enter
              additional Activities.
            </p>
          </div>
          <div
            id="prof-membership-items-container"
            className="md-space-between-containers"
          >
            {additionalFields}
            <AddRemoveFieldButtons
              handleAddField={handleAddField}
              handleRemoveField={handleRemoveField}
              disableAddButton={membershipCount >= MAX_FIELDS}
              disableRemoveButton={membershipCount <= MIN_FIELDS}
            />
          </div>
          <SaveButtons
            saveGoBack={handleSubmit}
            saveExit={handleSubmit}
            saveContinue={handleSubmit}
          />
        </div>
      </SubPage>
    </Styled>
  );
};

export default ScrollToTop(ProfMemberships);
