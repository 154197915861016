import { useState, useEffect } from "react";
import Styled from "./styles";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { Checkbox, FormControlLabel, FormGroup, Grid } from "@mui/material";
//import * as actions from "../../../../redux/actions";
import { useSelector } from "react-redux";
import {
  handleDropdownList,
  handleObjectChange,
} from "../../../../utils/Utils";
import CustomPopper from "../../../Common/CustomPopper";

export default function Certification({
  fieldNumber,
  onCertificateChage = () => {},
  certificateData = undefined,
}) {
  const [loading, setLoading] = useState(true);

  const [newCertificate, setNewCertificate] = useState({
    description: null,
    certificationCode: null,
    statusCode: null,
    certificationDate: null,
    expirationDate: null,
    deleteIndc: null,
    fieldNumber: fieldNumber,
  });

  const [boardOptions, setBoardOptions] = useState([]);
  const certiStatusOptions = [
    {
      value: "A",
      description: "Active",
      defaultInd: "N",
    },
    {
      value: "I",
      description: "Inactive",
      defaultInd: "N",
    },
    {
      value: "E",
      description: "Expired",
      defaultInd: "N",
    },
  ];
  const { data: certiDataList } = useSelector(
    ({ getCertification }) => getCertification
  );
  const { data: styleProps } = useSelector(
    ({ getStyleProps }) => getStyleProps
  );

  /**
   * This function is used to load existed form data in the form
   */
  const setExistedData = () => {
    if (certificateData?.certificationCode) {
      let certificateClone = { ...newCertificate, ...certificateData };
      setNewCertificate(certificateClone);
    }
  };

  useEffect(() => {
    if (certificateData) setExistedData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [certificateData]);

  useEffect(() => {
    if (newCertificate) onCertificateChage(newCertificate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newCertificate]);

  useEffect(() => {
    if (certiDataList?.data) {
      const { certifications: boardNameOptions } = certiDataList?.data;
      if (Array.isArray(boardNameOptions)) {
        setBoardOptions(boardNameOptions);
      }
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [certiDataList]);

  return (
    <Styled styleProps={styleProps}>
      {fieldNumber > 1 && <hr />}
      <div id={`cert-item-${fieldNumber}`}>
        <div id={`cert-item-header-${fieldNumber}`}>
          <h4>Board Certification Item {fieldNumber}</h4>
        </div>
        <Grid
          id={`cert-item-body-${fieldNumber}`}
          container
          columnSpacing={3}
          rowSpacing={0}
        >
          <Grid item xs={12} sm={12}>
            <FormGroup>
              <FormControlLabel
                style={{ pointerEvents: "none" }}
                control={
                  <Checkbox
                    className="checkbox"
                    style={{ pointerEvents: "auto" }}
                    value="Y"
                    onChange={(e) =>
                      handleObjectChange(
                        e?.target?.value,
                        "deleteIndc",
                        newCertificate,
                        setNewCertificate
                      )
                    }
                  />
                }
                className="checkbox-label error-label "
                label="Erroneous Report, please call 1-888-338-6998"
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Autocomplete
              id={`board-name-${fieldNumber}`}
              name={`board-name-${fieldNumber}`}
              options={boardOptions}
              getOptionLabel={(option) => option?.description}
              renderOption={(props, option) =>
                handleDropdownList(props, option.value, option.description)
              }
              isOptionEqualToValue={(option, value) =>
                option?.value === value?.value
              }
              onChange={(e, value) =>
                setNewCertificate({
                  ...newCertificate,
                  description: value?.description,
                  certificationCode: value?.value,
                })
              }
              value={
                newCertificate?.certificationCode && boardOptions?.length > 0
                  ? boardOptions.find(
                      (option) =>
                        option?.value === newCertificate?.certificationCode
                    )
                  : null
              }
              loading={loading}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Name of Board"
                  placeholder="Select a board"
                  InputLabelProps={{ shrink: true }}
                />
              )}
              PopperComponent={(props) => <CustomPopper {...props} />}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Certification Date (MM-DD-YYYY)"
                inputFormat="MM-DD-YYYY"
                name={`cert-date-${fieldNumber}`}
                value={
                  newCertificate?.certificationDate
                    ? dayjs(newCertificate?.certificationDate)
                    : null
                }
                onChange={(date) =>
                  handleObjectChange(
                    date ? dayjs(date).format("YYYY-MM-DD") : null,
                    "certificationDate",
                    newCertificate,
                    setNewCertificate
                  )
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: "MM-DD-YYYY",
                    }}
                  />
                )}
                className="datepicker"
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Expiration Date (MM-DD-YYYY)"
                inputFormat="MM-DD-YYYY"
                name={`cert-expiration-date-${fieldNumber}`}
                value={
                  newCertificate?.expirationDate
                    ? dayjs(newCertificate?.expirationDate)
                    : null
                }
                onChange={(date) =>
                  handleObjectChange(
                    date ? dayjs(date).format("YYYY-MM-DD") : null,
                    "expirationDate",
                    newCertificate,
                    setNewCertificate
                  )
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: "MM-DD-YYYY",
                    }}
                  />
                )}
                className="datepicker"
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              id={`cert-status-${fieldNumber}`}
              name={`cert-status-${fieldNumber}`}
              onChange={(e, value) =>
                handleObjectChange(
                  value?.value,
                  "statusCode",
                  newCertificate,
                  setNewCertificate
                )
              }
              isOptionEqualToValue={(option, value) =>
                option?.value === value?.value
              }
              value={
                newCertificate?.statusCode
                  ? certiStatusOptions.find(
                      (option) => option.value === newCertificate?.statusCode
                    )
                  : null
              }
              options={certiStatusOptions}
              getOptionLabel={(option) => {
                return option?.description || option;
              }}
              renderOption={(props, option) =>
                handleDropdownList(props, option.value, option.description)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Certification Status"
                  placeholder="(none)"
                  InputLabelProps={{ shrink: true }}
                />
              )}
              PopperComponent={(props) => <CustomPopper {...props} />}
            />
          </Grid>
          <Grid item xs={12} sm={6}></Grid>
        </Grid>
      </div>
    </Styled>
  );
}
