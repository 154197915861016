import { useState, useEffect } from "react";
import Styled from "./styles";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { Checkbox, FormControlLabel, FormGroup, Grid } from "@mui/material";
import debounce from "lodash.debounce";
import CustomPopper from "../../../Common/CustomPopper";
import CustomRadio from "../../../Common/CustomRadio";
import * as actions from "../../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { searchSponser } from "../../../../utils/ModuleActions";
import {
  handleDropdownList,
  handleObjectChange,
} from "../../../../utils/Utils";

export default function Sponsor({ fieldNumber, onGMEChange, GMEData = [] }) {
  const [loading, setLoading] = useState(true);
  const [sponsorData, setSponsorData] = useState({
    amahospitalCode: null,
    primarySpecialty: "",
    edited: false,
    gmestartDate: null,
    gmeendDate: null,
    specialtyName: null,
    gmecompleted: "Y",
    hospitalName: null,
    deleteIndc: "",
    specialtyCode: null,
    sponsorNumber: fieldNumber,
  });

  const [sponsorOptions, setSponsorOptions] = useState([]);
  const [specialityOptions, setSpecialityOptions] = useState([]);
  const dispatch = useDispatch();
  const { data: styleProps } = useSelector(
    ({ getStyleProps }) => getStyleProps
  );
  const { data: sponsorSearchList } = useSelector(
    ({ searchSponsor }) => searchSponsor
  );
  const { data: speciallitiesList } = useSelector(
    ({ getSpecialities }) => getSpecialities
  );

  /**
   * This function is used to load existed form data in the spoonser form
   */
  const setsponsorDefaultData = () => {
    if (GMEData?.amahospitalCode) {
      // const sponsorD = {
      //   hospitalCode: GMEData?.amahospitalCode,
      //   description: GMEData?.hospitalName,
      // };
      // setSponsorOptions([sponsorD]);
      setSponsorData({
        ...sponsorData,
        ...GMEData,
        sponsorNumber: fieldNumber,
      });
    }
  };

  useEffect(() => {
    if (GMEData) setsponsorDefaultData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [GMEData]);

  useEffect(() => {
    if (sponsorData) onGMEChange(sponsorData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sponsorData]);

  useEffect(() => {
    if (Array.isArray(sponsorSearchList?.data?.AMAHospitals)) {
      setSponsorOptions([
        ...sponsorOptions,
        ...sponsorSearchList?.data?.AMAHospitals,
      ]);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sponsorSearchList]);

  useEffect(() => {
    if (Array.isArray(speciallitiesList?.data?.specialities)) {
      setSpecialityOptions(speciallitiesList?.data?.specialities);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [speciallitiesList]);

  /**
   * This function is used to search the sponsor by name
   * @param {*} e
   */
  const searchsponsor = (e) => {
    setSponsorOptions([]);
    dispatch(actions.commonAction(searchSponser, { q: e?.target?.value }));
  };
  const callsponsorDebounced = debounce(searchsponsor, 500);

  const setHospitalValue = (sponsorObj) => {
    if(sponsorObj?.amahospitalCode && sponsorOptions?.length > 0){
      return sponsorOptions.find(
        (option) =>
          option?.hospitalCode === sponsorObj?.amahospitalCode
      )
    }else if(sponsorObj?.amahospitalCode){
      return {
        hospitalCode: sponsorObj?.amahospitalCode,
        description: sponsorObj?.hospitalName,
      };
    }else{
      return null;
    }
  }

  return (
    <Styled styleProps={styleProps}>
      {fieldNumber > 1 && <hr />}
      <div id={`sponsor-item-${fieldNumber}`}>
        <div id={`sponser-item-header-${fieldNumber}`}>
          <h4>Sponsor Item {fieldNumber}</h4>
        </div>
        <Grid
          id={`sponser-item-body-${fieldNumber}`}
          container
          columnSpacing={3}
          rowSpacing={0}
        >
          <Grid item xs={12} sm={12}>
            <FormGroup>
              <FormControlLabel
                style={{ pointerEvents: "none" }}
                control={
                  <Checkbox
                    className="checkbox colorGrey4"
                    style={{ pointerEvents: "auto" }}
                    onChange={(e) =>
                      handleObjectChange(
                        "Y",
                        "deleteIndc",
                        sponsorData,
                        setSponsorData
                      )
                    }
                  />
                }
                className="checkbox-label colorBlack"
                label="Erroneous Report, please call 1-888-338-6998"
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Autocomplete
              id={`sponsor-name-search-box-${fieldNumber}`}
              name={`sponsor-name-search-box-${fieldNumber}`}
              options={sponsorOptions}
              getOptionLabel={(option) => option?.description || null}
              renderOption={(props, option) =>
                handleDropdownList(
                  props,
                  option.hospitalCode,
                  option.description
                )
              }
              onChange={(e, value) =>
                setSponsorData({
                  ...sponsorData,
                  amahospitalCode: value?.hospitalCode,
                  hospitalName: value?.description,
                })
              }
              isOptionEqualToValue={(option, value) =>
                option?.hospitalCode === value?.hospitalCode
              }
              value={
                setHospitalValue(sponsorData)
              }
              loading={loading}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Name"
                  placeholder="Enter a sponsor name"
                  InputLabelProps={{ shrink: true }}
                  onChange={callsponsorDebounced}
                />
              )}
              PopperComponent={(props) => <CustomPopper {...props} />}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Autocomplete
              id={`speciality-search-box--${fieldNumber}`}
              name={`speciality-search-box-${fieldNumber}`}
              onChange={(e, value) =>
                setSponsorData({
                  ...sponsorData,
                  specialtyCode: value?.value,
                  specialtyName: value?.description,
                })
              }
              value={
                sponsorData?.specialtyCode && specialityOptions?.length > 0
                  ? specialityOptions.find(
                      (option) => option?.value === sponsorData?.specialtyCode
                    )
                  : null
              }
              options={specialityOptions}
              getOptionLabel={(option) => option?.description}
              renderOption={(props, option) =>
                handleDropdownList(props, option.value, option.description)
              }
              isOptionEqualToValue={(option, value) =>
                option?.value === value?.value
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Speciality"
                  InputLabelProps={{ shrink: true }}
                  placeholder="Enter a speciality"
                />
              )}
              PopperComponent={(props) => <CustomPopper {...props} />}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                inputFormat="MM-DD-YYYY"
                name={`sponsor-start-date-${fieldNumber}`}
                value={
                  sponsorData?.gmestartDate
                    ? dayjs(sponsorData?.gmestartDate)
                    : null
                }
                onChange={(date) =>
                  handleObjectChange(
                    date ? dayjs(date).format("YYYY-MM-DD") : null,
                    "gmestartDate",
                    sponsorData,
                    setSponsorData
                  )
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Start Date (MM-DD-YYYY)"
                    InputLabelProps={{ shrink: true }}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: "MM-DD-YYYY",
                    }}
                  />
                )}
                className="datepicker"
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                inputFormat="MM-DD-YYYY"
                name={`sponsor-completion-date-${fieldNumber}`}
                value={
                  sponsorData?.gmeendDate
                    ? dayjs(sponsorData?.gmeendDate)
                    : null
                }
                onChange={(date) =>
                  handleObjectChange(
                    date ? dayjs(date).format("YYYY-MM-DD") : null,
                    "gmeendDate",
                    sponsorData,
                    setSponsorData
                  )
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Completion Date (MM-DD-YYYY)"
                    InputLabelProps={{ shrink: true }}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: "MM-DD-YYYY",
                    }}
                  />
                )}
                className="datepicker"
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={12}>
            <CustomRadio
              value={sponsorData?.gmecompleted}
              formLabel="GME Completed?"
              onChangeCustom={(e) =>
                handleObjectChange(
                  e.target.value,
                  "gmecompleted",
                  sponsorData,
                  setSponsorData
                )
              }
              ariaLabel={`gme-completed-${fieldNumber}`}
            />
          </Grid>
        </Grid>
      </div>
    </Styled>
  );
}
