import styled from "styled-components/macro";

export default styled.div`
  #malpractice-header h4 {
    margin-bottom: 0;
  }

  #malpractice-body ul {
    padding-left: 50px;
    margin: 15px 0px 15px 0px;
  }
`;
