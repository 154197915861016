import React, { useState, useEffect, useCallback } from "react";
import Styled from "./styles";
import { useSelector } from "react-redux";
import { TextField, Autocomplete } from "@mui/material";
import {
  handleObjectChange,
  handleDropdownList,
} from "../../../../utils/Utils";
import CustomPopper from "../../../Common/CustomPopper";
import CustomRadio from "../../../Common/CustomRadio";

const MedicalPractice = ({
  medicalPracticeData = undefined,
  fieldNumber,
  onMedicalPracticeChange,
  checkValue,
}) => {
  const [medicalPractice, setMedicalPractice] = useState({
    practiceAreaText: "",
    primaryIndc: "",
    practiceAreaCode: "",
    fieldNumber: fieldNumber,
  });
  const [medicalPracticeOptions, setMedicalPracticeOptions] = useState([]);

  const { data: practiceAreas } = useSelector(
    ({ getPracticeAreas }) => getPracticeAreas
  );
  const { data: styleProps } = useSelector(
    ({ getStyleProps }) => getStyleProps
  );

  /**
   * Used to set the initial local state for the current Field of Practice being rendered.
   * It uses the data passed in from the parent and adds one more prop called fieldNumber.
   * fieldNumber is needed for the logic of adding, removing, and updating of that data in the field.
   * Note: Initial fields will have all the column attributes from the initial GET request. New fields will have filtered list since that is all that is needed.
   */
  const setDefaultData = useCallback(() => {
    setMedicalPractice({
      ...medicalPracticeData,
      fieldNumber: fieldNumber,
    });
  }, [medicalPracticeData, fieldNumber]);

  useEffect(() => {
    if (medicalPractice) {
      onMedicalPracticeChange(medicalPractice);
    }
  }, [medicalPractice, onMedicalPracticeChange]);

  useEffect(() => {
    if (Array.isArray(practiceAreas?.data?.practices)) {
      setMedicalPracticeOptions(practiceAreas?.data?.practices);
    }
  }, [practiceAreas]);

  useEffect(() => {
    if (medicalPracticeData) setDefaultData();
  }, [medicalPracticeData, setDefaultData]);

  /**
   * Called when the user selects a new value from the drop down list.
   * Sets the local state variable with the updated data.
   * Calls checkValue to make see if the Add Field button needs to be enabled/disabled.
   * @param {*} e Event object from the onChange event
   * @param {*} newValue The new value the user has selected
   * @param {*} textFieldPropName The name of the property that contains the field value that needs to be updated
   * @param {*} codePropName The name of the property that contains the code value that needs to be updated
   */
  const handleAutocomplete = (e, newValue, textFieldPropName, codePropName) => {
    checkValue(newValue);

    if (newValue === null) {
      setMedicalPractice({
        ...medicalPractice,
        [textFieldPropName]: "",
        [codePropName]: "",
      });
    } else {
      setMedicalPractice({
        ...medicalPractice,
        [textFieldPropName]: newValue?.description,
        [codePropName]: newValue?.value,
      });
    }
  };

  return (
    <Styled styleProps={styleProps}>
      <div
        id={`medical-practice-item-${fieldNumber}`}
        className="no-divider-item"
      >
        <Autocomplete
          id={`medical-practice-${fieldNumber}`}
          name={`medical_practice_${fieldNumber}`}
          options={medicalPracticeOptions}
          getOptionLabel={(option) => option?.description || ""}
          value={
            medicalPractice?.practiceAreaText
              ? medicalPracticeOptions.find(
                  (option) =>
                    option?.value === medicalPractice?.practiceAreaCode
                )
              : null
          }
          onChange={(e, newValue) => {
            handleAutocomplete(
              e,
              newValue,
              "practiceAreaText",
              "practiceAreaCode"
            );
          }}
          isOptionEqualToValue={(option, currValue) =>
            option?.value === currValue?.value
          }
          renderOption={(props, option) =>
            handleDropdownList(props, option.value, option.description)
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Field of Practice"
              placeholder="Enter a field of practice"
              InputLabelProps={{ shrink: true }}
            />
          )}
          PopperComponent={(props) => <CustomPopper {...props} />}
        />
        <CustomRadio
          value={medicalPractice?.primaryIndc}
          formLabel="Primary field of practice:"
          onChangeCustom={(e) =>
            handleObjectChange(
              e.target.value,
              "primaryIndc",
              medicalPractice,
              setMedicalPractice,
              fieldNumber
            )
          }
          ariaLabel={`primary-field-of-practice-${fieldNumber}`}
        />
      </div>
    </Styled>
  );
};

export default MedicalPractice;
