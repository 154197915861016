import {
  GET_LOGIN_REQUEST,
  GET_LOGIN_REQUEST_SUCCESS,
  GET_LOGIN_REQUEST_ERROR,
  UNAUTHORIZED_USER,
} from "../actions/type";

const initialState = {
  loading: null,
  error: null,
  data: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_LOGIN_REQUEST_SUCCESS:
      let resp = action.data ? action.data : {};
      if (resp.data?.token) {
        localStorage.setItem("authToken", resp.data?.token);
        localStorage.setItem("logoutUrl", resp.data?.logoutUrl);
      } else if (resp.data?.auth_url) {
        localStorage.setItem("code_verifier", resp.data?.code_verifier);
        const auth_url = resp.data?.auth_url;
        window.location.replace(auth_url);
      }

      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case GET_LOGIN_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case UNAUTHORIZED_USER:
      return {
        ...state,
        loading: false,
        error: action,
      };
    default:
      return state;
  }
};
