import styled from "styled-components/macro";

export default styled.div`
  .btn-container {
    margin-top: 60px;
    margin-bottom: 30px;
    @media only screen and (max-width: 1228px) {
      div {
        flex-basis: 100%;
        min-width: 100%;
        display: inline-flex;
        justify-content: center;
        button {
          width: 90%;
        }
      }
      flex-direction: column-reverse;
    }
  }
  @media only screen and (min-width: 1229px) {
    .margin-right-30 {
      margin-right: 30px !important;
    }
    .min-width-200 {
      min-width: 200px;
    }
  }

  .btn-fill,
  .btn-no-fill {
    margin-right: 10px;
    height: 2.6667rem;
    line-height: var(--line_height_20px);
  }
`;
