import Styled from "./styles";
import { useSelector } from "react-redux";

const SubPageHeader = ({ titleBlue = "PHYSICIAN", titleBlack = "Profile" }) => {
  const { data: styleProps } = useSelector(
    ({ getStyleProps }) => getStyleProps
  );

  if (!styleProps) return null; // Needed so app does not crash on first render
  return (
    <Styled styleProps={styleProps}>
      <h1 className="sub-page-header-container" id="main">
        <div className="sub-headers">
          <div className="title-blue">{titleBlue}</div>
          <div className="title-black">{titleBlack}</div>
        </div>
      </h1>
    </Styled>
  );
};

export default SubPageHeader;
