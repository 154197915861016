import React, { useState, useEffect, useCallback } from "react";
import Styled from "./styles";
import { useSelector } from "react-redux";
import { Grid, Autocomplete, TextField } from "@mui/material";
import AddRemoveFieldButtons from "../../../Common/AddRemoveField";
import CustomPopper from "../../../Common/CustomPopper";
import CustomRadio from "../../../Common/CustomRadio";
import {
  handleObjectChange,
  handleDropdownList,
} from "../../../../utils/Utils";
import PhysicianName from "./PhysicianName";
import { useRef } from "react";

const PracticeItem = ({
  practiceItemData = undefined,
  fieldNumber,
  onPracticeItemChange,
}) => {
  const [practiceItem, setPracticeItem] = useState({
    name: "",
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
    addressLine4: "",
    city: "",
    countryName: "",
    state: "",
    zipCode: "",
    countyFipsCode: "",
    provinceName: "",
    phoneNumber: "",
    disabledAccess: "",
    doctors: [],
    fieldNumber: fieldNumber,
  });
  const [stateOptions, setStateOptions] = useState([]);
  const [countyOptions, setCountyOptions] = useState([]);
  const [physicianNames, setPhysicianNames] = useState([]);

  const { data: masterList } = useSelector(({ getMaster }) => getMaster);
  const { data: styleProps } = useSelector(
    ({ getStyleProps }) => getStyleProps
  );

  const physicianNameList = useRef([]);
  const practiceItemCloneObj = useRef({});
  const phyNamesCount = useRef(1);

  /**
   * Used to set the initial local state for the current Practice Item being rendered.
   * It uses the data passed in from the parent and adds one more prop called fieldNumber.
   * fieldNumber is needed for the logic of adding, removing, and updating of that data in the field.
   * Note: Initial fields will have all the column attributes from the initial GET request. New fields will have filtered list since that is all that is needed.
   */
  const setDefaultData = useCallback(() => {
    setPracticeItem({
      ...practiceItemData,
      fieldNumber: fieldNumber,
    });
  }, [practiceItemData, fieldNumber]);

  /**
   * Used to set up physicianNames when the Practice page first renders.
   * Handles the use cases of data and no data returning from the server.
   */
  const loadInitialPhysicianNames = () => {
    let physicianNamesClone = [];
    if (practiceItemData?.doctors?.length > 0) {
      practiceItemData?.doctors.forEach((data, index) => {
        physicianNamesClone.push(
          <PhysicianName
            key={index + 1}
            fieldNumber={fieldNumber}
            physicianNumber={index + 1}
            onPhysicianNameChange={updatePhysicanNameData}
            physicianNameData={data}
          />
        );
      });
      phyNamesCount.current = practiceItemData?.doctors?.length;
    } else {
      physicianNamesClone.push(
        <PhysicianName
          fieldNumber={fieldNumber}
          physicianNumber={1}
          key={1}
          onPhysicianNameChange={updatePhysicanNameData}
        />
      );
    }
    setPhysicianNames(physicianNamesClone);
  };

  useEffect(() => {
    loadInitialPhysicianNames();

    // Adding loadInitialPhysicianNames to the dependency array will cause infinite loop and make Add stop working
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (practiceItem) {
      practiceItemCloneObj.current = practiceItem;
      onPracticeItemChange(practiceItem);
    }
  }, [practiceItem, onPracticeItemChange]);

  useEffect(() => {
    if (practiceItemData) setDefaultData();
  }, [practiceItemData, setDefaultData]);

  useEffect(() => {
    if (masterList?.data?.states && masterList?.data?.counties) {
      setStateOptions(masterList?.data?.states);
      setCountyOptions(masterList?.data?.counties);
    }
  }, [masterList]);

  /**
   * Called when the user selects a new value from the drop down list.
   * Sets the local state variable with the updated data.
   * @param {*} e Event object from the onChange event
   * @param {*} newValue The new value the user has selected
   * @param {*} codePropName The name of the property that contains the code value that needs to be updated
   */
  const handleAutocomplete = (e, newValue, codePropName) => {
    // Might need to change this since we are not using state codes
    if (newValue === null) {
      setPracticeItem({
        ...practiceItem,
        [codePropName]: "",
      });
    } else {
      setPracticeItem({
        ...practiceItem,
        [codePropName]: newValue?.value,
      });
    }
  };

  /**
   * This function is called when the user clicks on the Add Physician button and it updates the Physician Names
   */
  const handleAddPhysician = () => {
    phyNamesCount.current = phyNamesCount.current + 1;
    setPhysicianNames([
      ...physicianNames,
      <PhysicianName
        key={phyNamesCount.current + 1}
        fieldNumber={fieldNumber}
        physicianNumber={phyNamesCount.current + 1}
        onPhysicianNameChange={updatePhysicanNameData}
      />,
    ]);
  };

  /**
   * This function is used to update the Physician Names when the user clicks on the
   * Add Field button and changes a field value
   * @param {*} physicianNamesArray | Updated Physicians Name array
   */
  const updatePhysicanNameData = (physicianNamesArray) => {
    if (physicianNamesArray?.physicianNumber) {
      let index = physicianNameList.current.findIndex(
        (data) => data?.physicianNumber === physicianNamesArray?.physicianNumber
      );
      if (index === -1) {
        phyNamesCount.current = physicianNameList.current?.length + 1;
        physicianNameList.current.push(physicianNamesArray);
      } else {
        physicianNameList.current[index] = physicianNamesArray;
      }
      setPracticeItem({
        ...practiceItem,
        ...practiceItemCloneObj.current,
        doctors: physicianNameList.current,
      });
    }
  };

  return (
    <Styled styleProps={styleProps}>
      {fieldNumber > 1 && <hr />}
      <div id={`practice-item-${fieldNumber}`}>
        <div id={`practice-item-header-${fieldNumber}`}>
          <h4>Practice {fieldNumber}</h4>
        </div>
        <Grid
          id={`practice-item-body-${fieldNumber}`}
          container
          columnSpacing={3}
          rowSpacing={0}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12} sm={12}>
            <TextField
              id={`practice-name-${fieldNumber}`}
              label="Practice Name"
              placeholder="Enter Practice Name"
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={practiceItem?.name}
              onChange={(e) => {
                handleObjectChange(
                  e.target.value,
                  "name",
                  practiceItem,
                  setPracticeItem
                );
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              id={`practice-address-line-1-${fieldNumber}`}
              label="Address Line 1"
              placeholder="Enter Address"
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={practiceItem?.addressLine1}
              onChange={(e) => {
                handleObjectChange(
                  e.target.value,
                  "addressLine1",
                  practiceItem,
                  setPracticeItem
                );
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id={`practice-address-line-2-${fieldNumber}`}
              label="Address Line 2"
              placeholder="Enter Address"
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={practiceItem?.addressLine2}
              onChange={(e) => {
                handleObjectChange(
                  e.target.value,
                  "addressLine2",
                  practiceItem,
                  setPracticeItem
                );
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id={`practice-address-line-3-${fieldNumber}`}
              label="Address Line 3"
              placeholder="Enter Address"
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={practiceItem?.addressLine3}
              onChange={(e) => {
                handleObjectChange(
                  e.target.value,
                  "addressLine3",
                  practiceItem,
                  setPracticeItem
                );
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id={`practice-city-${fieldNumber}`}
              label="City"
              placeholder="Enter City"
              fullWidth
              value={practiceItem?.city}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => {
                handleObjectChange(
                  e.target.value,
                  "city",
                  practiceItem,
                  setPracticeItem
                );
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id={`practice-country-${fieldNumber}`}
              label="Country"
              placeholder="Enter Country"
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={practiceItem?.countryName}
              onChange={(e) => {
                handleObjectChange(
                  e.target.value,
                  "countryName",
                  practiceItem,
                  setPracticeItem
                );
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              options={stateOptions}
              getOptionLabel={(option) => option?.description || ""}
              value={
                practiceItem?.state && stateOptions?.length > 0
                  ? stateOptions.find(
                      (option) => option?.value === practiceItem?.state
                    )
                  : null
              }
              onChange={(e, newValue) => {
                handleAutocomplete(e, newValue, "state");
              }}
              isOptionEqualToValue={(option, currValue) =>
                option?.value === currValue?.value
              }
              renderOption={(props, option) =>
                handleDropdownList(props, option.value, option.description)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  id={`state-${fieldNumber}`}
                  name={`state_${fieldNumber}`}
                  label="State (USA Only)"
                  placeholder="Select a state"
                  InputLabelProps={{ shrink: true }}
                />
              )}
              PopperComponent={(props) => <CustomPopper {...props} />}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id={`practice-zip-code-${fieldNumber}`}
              label="ZIP Code"
              placeholder="ZIP Code"
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={practiceItem?.zipCode}
              onChange={(e) => {
                handleObjectChange(
                  e.target.value,
                  "zipCode",
                  practiceItem,
                  setPracticeItem
                );
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              id={`practice-county-${fieldNumber}`}
              name={`practice_county_${fieldNumber}`}
              options={countyOptions}
              getOptionLabel={(option) => option?.description || ""}
              value={
                practiceItem?.countyFipsCode && countyOptions?.length > 0
                  ? countyOptions.find(
                      (option) => option?.value === practiceItem?.countyFipsCode
                    )
                  : null
              }
              onChange={(e, newValue) => {
                handleAutocomplete(e, newValue, "countyFipsCode");
              }}
              isOptionEqualToValue={(option, currValue) =>
                option?.value === currValue?.value
              }
              renderOption={(props, option) =>
                handleDropdownList(props, option.value, option.description)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="County/Borough"
                  placeholder="Select a County/Borough"
                  InputLabelProps={{ shrink: true }}
                />
              )}
              PopperComponent={(props) => <CustomPopper {...props} />}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id={`practice-province-${fieldNumber}`}
              label="Province/Region"
              placeholder="Enter Province/Region"
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={practiceItem?.provinceName}
              onChange={(e) => {
                handleObjectChange(
                  e.target.value,
                  "provinceName",
                  practiceItem,
                  setPracticeItem
                );
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id={`practice-phone-number-${fieldNumber}`}
              label="Phone Number"
              placeholder="Enter Phone Number"
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={practiceItem?.phoneNumber}
              onChange={(e) => {
                handleObjectChange(
                  e.target.value,
                  "phoneNumber",
                  practiceItem,
                  setPracticeItem
                );
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomRadio
              value={practiceItem?.disabledAccess}
              formLabel="Accommodates disability"
              onChangeCustom={(e) => {
                handleObjectChange(
                  e.target.value,
                  "disabledAccess",
                  practiceItem,
                  setPracticeItem
                );
              }}
              ariaLabel={`accommodates-disability-${fieldNumber}`}
            />
          </Grid>
          <Grid id="physician-names" item xs={12} sm={12}>
            {physicianNames}
            <AddRemoveFieldButtons
              id="add-physician-btn"
              handleAddField={handleAddPhysician}
              addButtonText="Add physician"
            />
          </Grid>
        </Grid>
      </div>
    </Styled>
  );
};

export default PracticeItem;
