import React, { useState, useEffect } from "react";
import Styled from "./styles";
import { useSelector } from "react-redux";
import { Grid, TextField } from "@mui/material";
import { handleObjectChange, getRegex } from "../../../../utils/Utils";

const MailingAddress = ({ mailingAddressData, onMailingAddressChange }) => {
  const [mailingAddress, setMailingAddress] = useState({
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
    addressLine4: "",
    zipCode: "",
  });
  const [errorMsg, setErrorMsg] = useState({
    zipCode: "",
  });

  const { data: styleProps } = useSelector(
    ({ getStyleProps }) => getStyleProps
  );

  useEffect(() => {
    if (mailingAddress) {
      const zipPattern = getRegex("zipCode");
      let updatedMailingAddress = { ...mailingAddress };

      // Check ContactInfo/index.jsx for more details about each section since it is more involved there

      /* SETTING ERROR MESSAGE */
      if (
        mailingAddress?.zipCode &&
        !zipPattern.test(mailingAddress?.zipCode) &&
        errorMsg?.zipCode === ""
      ) {
        handleObjectChange(
          "You must enter a valid 5 digit zip code",
          "zipCode",
          errorMsg,
          setErrorMsg
        );
      }

      /* REMOVING ERROR MESSAGE */
      if (
        (zipPattern.test(mailingAddress?.zipCode) ||
          !mailingAddress?.zipCode) &&
        errorMsg?.zipCode !== ""
      ) {
        setErrorMsg({
          ...errorMsg,
          zipCode: "",
        });
      }

      /* 
      SETTING TEMP VAR FOR PARENT TO RECEIVE 

      The reason for the second condition here is to allow submitting and saving an empty string. This field is not required, like phone number and email for Contact Info
      Without this second condition, if the string were empty, we would submit the initial value of the zipCode instead. We only want that when the zipCode has more than 
      0 chars and is an invalid format      
      */

      if (
        !zipPattern.test(mailingAddress?.zipCode) &&
        mailingAddress?.zipCode
      ) {
        updatedMailingAddress = {
          ...updatedMailingAddress,
          zipCode: mailingAddressData?.zipCode,
        };
      }

      onMailingAddressChange(updatedMailingAddress);
    }
  }, [
    mailingAddress,
    onMailingAddressChange,
    errorMsg,
    mailingAddressData?.zipCode,
  ]);

  useEffect(() => {
    // Triggered when this changes and if it is defined
    if (mailingAddressData) {
      setMailingAddress(mailingAddressData);
    }
  }, [mailingAddressData]);

  return (
    <Styled styleProps={styleProps}>
      <div
        id="mailing-address-container"
        className="md-space-between-containers"
      >
        <div id="mailing-address-header">
          <h4>Mailing address</h4>
          <p>
            Enter a preferred address in the spaces. This address is{" "}
            <strong>confidential</strong> and for contact purposes only. This
            will not be made available to the public. Any changes will be
            effective immediately.
          </p>
        </div>
        <Grid
          id="mailing-address-body"
          className="sm-space-between-containers"
          container
          columnSpacing={3}
          rowSpacing={0}
        >
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              id="mailing-address-1"
              label="Mailing Address 1"
              placeholder="Enter a mailing address line 1"
              value={mailingAddress?.addressLine1}
              InputLabelProps={{ shrink: true }}
              onChange={(e) =>
                handleObjectChange(
                  e.target.value,
                  "addressLine1",
                  mailingAddress,
                  setMailingAddress
                )
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              id="mailing-address-2"
              label="Mailing Address 2"
              placeholder="Enter a mailing address line 2"
              value={mailingAddress?.addressLine2}
              InputLabelProps={{ shrink: true }}
              onChange={(e) =>
                handleObjectChange(
                  e.target.value,
                  "addressLine2",
                  mailingAddress,
                  setMailingAddress
                )
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="mailing-address-3"
              label="Mailing Address 3"
              placeholder="Enter a mailing address line 3"
              value={mailingAddress?.addressLine3}
              InputLabelProps={{ shrink: true }}
              onChange={(e) =>
                handleObjectChange(
                  e.target.value,
                  "addressLine3",
                  mailingAddress,
                  setMailingAddress
                )
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="mailing-address-4"
              label="Mailing Address 4"
              placeholder="Enter a mailing address line 4"
              value={mailingAddress?.addressLine4}
              InputLabelProps={{ shrink: true }}
              onChange={(e) =>
                handleObjectChange(
                  e.target.value,
                  "addressLine4",
                  mailingAddress,
                  setMailingAddress
                )
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              error={errorMsg?.zipCode ? true : false}
              helperText={errorMsg?.zipCode}
              id="zip-code"
              label="ZIP Code"
              placeholder="Enter ZIP Code"
              value={mailingAddress?.zipCode}
              InputLabelProps={{ shrink: true }}
              onChange={(e) =>
                handleObjectChange(
                  e.target.value,
                  "zipCode",
                  mailingAddress,
                  setMailingAddress
                )
              }
            />
          </Grid>
        </Grid>
      </div>
    </Styled>
  );
};

export default MailingAddress;
