import {
  GET_EDUCATION_DATA_REQUEST,
  GET_EDUCATION_DATA_SUCCESS,
  GET_EDUCATION_DATA_ERROR,
  CLEAR_EDUCATION_DATA,
} from "../actions/type";

const initialState = {
  loading: null,
  error: null,
  data: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_EDUCATION_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_EDUCATION_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case GET_EDUCATION_DATA_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case CLEAR_EDUCATION_DATA:
      return {
        ...state,
        loading: false,
        data: null,
      };
    default:
      return state;
  }
};
