import React, { useState, useEffect, useRef } from "react";
import Styled from "./styles";
import * as actions from "../../../redux/actions";
import { Checkbox, FormControlLabel, Grid, TextField } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ScrollToTop from "../../Common/HOC/ScrollToTop";
import SubPage from "../../Common/SubPage";
import ShowResponse from "../../Common/Messages";
import {
  getHealthPlansData,
  postHealthPlansData,
} from "../../../utils/ModuleActions";
import SaveButtons from "../../Common/SaveButtons";
import {
  addBtnName,
  sendPayload,
  handleCancelPopUpBtn,
  handleOkayPopUpBtn,
} from "../../../utils/Utils";

const HealthPlans = () => {
  /* ********** STATE VARIABLES ********** */
  const [selectedPlans, setSelectedPlans] = useState([]);
  const [response, setResponse] = useState({
    headerText: "Error message",
    message: "",
    show: false,
  });

  /* ********** REF VARIABLES ********** */
  const navDataObj = useRef({});

  const properties = {
    titlePage: "HEALTH PLAN CONTRACTS OR OTHER AFFILIATION (OPTIONAL)",
    showInstructions: true,
  };
  const { data: styleProps } = useSelector(
    ({ getStyleProps }) => getStyleProps
  );
  const { data: navigationList } = useSelector(({ navigate }) => navigate);
  const { data: healthPlansData } = useSelector(
    ({ healthPlans }) => healthPlans
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(actions.commonAction(getHealthPlansData));
  }, [dispatch]);

  useEffect(() => {
    // If we call this when there are errorMessages, then all selected plans reset to empty
    if (healthPlansData && !healthPlansData?.errorMessages) {
      const healthPlanClone = [];
      healthPlansData?.data?.healthPlans?.forEach((healthPlan) => {
        if (healthPlan?.planCode !== null) {
          healthPlanClone.push(healthPlan);
        }
      });
      setSelectedPlans(healthPlanClone);
    }
  }, [healthPlansData]);

  /**
   * This function is called when the user tries to save, either by clicking on a side menu item
   * or by clicking one of the three save buttons. Calls addBtnName(). Read that function description for more details.
   * Calls the sendPayload() function afterwards.
   * @param {*} navObject An object from navigate.json or an event object from clicking the save buttons
   * @param {*} location Location object from useLocation(), either from Sidebar or SaveButtons component
   */
  const handleSubmit = (navObject, location) => {
    let payload = {
      healthPlans: [],
      otherPlan: "",
    };
    payload.healthPlans = selectedPlans;
    payload.otherPlan = otherPlanValue();

    navDataObj.current = addBtnName(navObject, navigationList, location);

    let params = {
      dispatch: dispatch,
      actionData: postHealthPlansData,
      requestBody: payload,
      response: response,
      setResponse: setResponse,
      navObject: navDataObj.current,
      navigate: navigate,
    };

    sendPayload(params);
  };

  /**
   * Checks if the passed in object matches with an objected in selectedPlans. If so, then the index should be greater than -1.
   * @param {*} code The plan code object to be checked
   * @returns index, which is either -1 or greater than -1
   */
  const checkedIndex = (code) => {
    let index = selectedPlans.findIndex(
      (plan) => plan?.planCode === code?.value
    );

    return index;
    // If a match was found, then it is checked
  };

  /**
   * Handles changes to the check boxes. If there is a new item being selected, then add it to the list of selected plans.
   * If the check state of the object passed in is false (being, de-selected), then it is removed from the list of selected plans.
   * @param {*} checkVal The current object's check state; either true or false
   * @param {*} code The plan code object to be checked
   */
  const onHealthPlanChange = (checkVal, code) => {
    if (checkVal === false) {
      let index = checkedIndex(code);
      if (index > -1) {
        setSelectedPlans((prevState) => {
          prevState.splice(index, 1);
          return [...prevState];
        });
      }
    } else {
      setSelectedPlans((prevState) => [
        ...prevState,
        { planCode: code?.value },
      ]);
    }
  };

  /**
   * Checks if there is an "other" plan in selectedPlans. If so, then the index should be greater than -1.
   * @returns index, which is either -1 or greater than -1
   */
  const otherPlanIndex = () => {
    let index = selectedPlans.findIndex((plan) => plan?.other?.length > 0);

    return index;
  };

  /**
   * Used to figure out what the current value of the other plan field is
   * @returns Either an empty string (if empty) or the user's input
   */
  const otherPlanValue = () => {
    let index = otherPlanIndex();

    if (index > -1) return selectedPlans[index]?.other;

    return "";
  };

  /**
   * Handles changes made to the other textbox and updates the list of selected plans accordingly.
   * This function also checks if the other plans field is a part of the list or not. If not,
   * it adds it. If it is there already, it is modified with the new input.
   * @param {*} e The event object due to the onChange event
   */
  const handleOtherPlan = (e) => {
    let index = otherPlanIndex();

    if (index > -1) {
      let selectedPlansTemp = [...selectedPlans];
      selectedPlansTemp[index].other = e?.target?.value;
      setSelectedPlans(selectedPlansTemp);
    } else {
      setSelectedPlans((prevState) => [
        ...prevState,
        { planCode: "", other: e?.target?.value },
      ]);
    }
  };

  /* ********** FUNCTION COMPONENT RETURN ********** */
  if (!styleProps) return null; // Needed so app does not crash on first render
  return (
    <Styled styleProps={styleProps}>
      {response?.show && (
        <ShowResponse
          headerText={response?.headerText}
          message={response?.message}
          show={response?.show}
          handleCancel={() =>
            handleCancelPopUpBtn(
              response,
              setResponse,
              navDataObj.current,
              navigate
            )
          }
          handleOkay={() => handleOkayPopUpBtn(response, setResponse)}
        />
      )}
      <SubPage properties={properties} handleSideMenuSave={handleSubmit}>
        <div
          id="health-plans-form-body"
          className="form-body-container space-between-text"
        >
          <div id="health-plans-header">
            <h4>Health Plan Contracts or Other Affiliation</h4>
            <p>
              Click the check box beside each health plan with which you are
              contracted or affiliated to place a check mark in it (clicking a
              box that already has a check mark will clear the field).
            </p>
          </div>
          <Grid
            container
            columnSpacing={3}
            rowSpacing={0}
            id="health-plans-body"
          >
            {healthPlansData?.data?.planCodes &&
              healthPlansData?.data?.planCodes.map((code, index) => {
                return (
                  <Grid key={index} item xs={12} sm={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name={`health-plan-checkbox-${index}`}
                          checked={checkedIndex(code) > -1 ? true : false}
                          value={code?.value}
                          onChange={(e) =>
                            onHealthPlanChange(e?.target?.checked, code)
                          }
                        />
                      }
                      label={code?.description}
                    />
                  </Grid>
                );
              })}
            <Grid item xs={12} sm={12}>
              <TextField
                id="other-health-plans"
                label="Other Health Plans"
                placeholder="Please list other health plans here"
                InputLabelProps={{ shrink: true }}
                fullWidth
                value={otherPlanValue()}
                onChange={handleOtherPlan}
              />
            </Grid>
          </Grid>
          <SaveButtons
            saveGoBack={handleSubmit}
            saveExit={handleSubmit}
            saveContinue={handleSubmit}
          />
        </div>
      </SubPage>
    </Styled>
  );
};

export default ScrollToTop(HealthPlans);
