import React, { useState, useEffect } from "react";
import Styled from "./styles";
import SubBoxInfo from "../../../Common/SubBoxInfo";

const Name = ({ nameData, lastProfileUpdate }) => {
  const [finalNameData, setFinalNameData] = useState([]);

  useEffect(() => {
    if (nameData) {
      setFinalNameData([
        {
          displayName: "Last name",
          fieldValue: nameData?.lastName,
          isDate: false,
        },
        {
          displayName: "First name",
          fieldValue: nameData?.firstName,
          isDate: false,
        },
        {
          displayName: "Middle name (or initial)",
          fieldValue: nameData?.middleName,
          isDate: false,
        },
        {
          displayName: "Suffix",
          fieldValue: nameData?.suffix,
          isDate: false,
        },
        {
          displayName: "Last update to profile",
          fieldValue: lastProfileUpdate,
          isDate: true,
        },
      ]);
    }
  }, [nameData, lastProfileUpdate]);

  return (
    <Styled>
      <div id="name-container">
        <div id="name-header">
          <h4>Name</h4>
          <p>
            This information cannot be changed. If you feel it's incorrect,
            please call the Physician Helpline{" "}
            <strong className="help-desk-number">1-888-338-6998</strong>
          </p>
        </div>
        <div id="name-body">
          <SubBoxInfo data={finalNameData} tableName="Physician Information Page Name Fields"/>
        </div>
      </div>
    </Styled>
  );
};

export default Name;
