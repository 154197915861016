import React, { useEffect, useRef, useState } from "react";
import Styled from "./styles";
import { useDispatch, useSelector } from "react-redux";
import ScrollToTop from "../../Common/HOC/ScrollToTop";
import SubPage from "../../Common/SubPage";
import AddRemoveFieldButtons from "../../Common/AddRemoveField";
import SaveButtons from "../../Common/SaveButtons";
import CustomRadio from "../../Common/CustomRadio";
import * as actions from "../../../redux/actions";
import {
  getMasterData,
  getRestrictionsData,
  postRestrictionsData,
} from "../../../utils/ModuleActions";
import { useNavigate } from "react-router";
import ShowResponse from "../../Common/Messages";
import {
  handleObjectChange,
  addBtnName,
  sendPayload,
  handleCancelPopUpBtn,
  handleOkayPopUpBtn,
} from "../../../utils/Utils";
import Restriction from "./Restriction";
import FailureToRenew from "./FailureToRenew";

const Restrictions = () => {
  const properties = {
    titlePage: "HOSPITAL PRIVILEGES RESTRICTIONS",
    showInstructions: true,
  };

  const MIN_FIELDS = 1;
  const MAX_FIELDS = 20;

  const [restrictionCount, setRestrictionCount] = useState(1);
  const [restrictionsFields, setRestrictionsFields] = useState([]);
  const [failureToRenewCount, setFailureToRenewCount] = useState(1);
  const [failureToRenewFields, setFailureToRenewFields] = useState([]);
  const [response, setResponse] = useState({
    headerText: "Error message",
    message: "",
    show: false,
  });
  const [radioValues, setRadioValues] = useState({
    responsePrivilegeLossRestrictions: "Y",
    responsePrivilegeRenewResign: "Y",
  });
  const [noNewItem, setNewItem] = useState({
    lossRestrictionsNewItem: true,
    renewResignNewItem: true,
  });

  const { data: navigationList } = useSelector(({ navigate }) => navigate);
  const { data: styleProps } = useSelector(
    ({ getStyleProps }) => getStyleProps
  );
  const { data: restrictionsData } = useSelector(
    ({ restrictions }) => restrictions
  );

  const restrictionDataArray = useRef({
    privilegeLossRestrictions: [],
    privilegeRenewResign: [],
  });
  const initialItemCount = useRef({
    lossRestrictionsNewItem: 0,
    renewResignNewItem: 0,
  });
  const navDataObj = useRef({});

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(actions.commonAction(getRestrictionsData));
    dispatch(actions.commonAction(getMasterData));
    dispatch(
      actions.updateWithoutPost({
        type: "UPDATE_RESTRICTIONS_INDC",
        data: "",
      })
    );
    dispatch(
      actions.updateWithoutPost({
        type: "UPDATE_FAILURE_TO_RENEW_INDC",
        data: "",
      })
    );

    return () => {
      dispatch(
        actions.updateWithoutPost({
          type: "CLEAR_RESTRICTIONS_DATA",
          data: {},
        })
      );
    };
  }, [dispatch]);

  /**
   * This function us use to load existed data or load first spinser blank form.
   */
  const loadExistedRestrictionData = (restrictionDataArr) => {
    let restrictionsFieldsClone = [];
    if (restrictionDataArr?.length > 0) {
      restrictionDataArr.forEach((data, index) => {
        restrictionsFieldsClone.push(
          <Restriction
            key={index + 1}
            fieldNumber={index + 1}
            onRestrictionChage={updateRestrictionArray}
            restrictionData={data}
          />
        );
      });
      initialItemCount.current.lossRestrictionsNewItem =
        restrictionDataArr?.length;
      setRestrictionCount(restrictionDataArr?.length);
    } else {
      restrictionsFieldsClone.push(
        <Restriction
          onRestrictionChage={updateRestrictionArray}
          fieldNumber={1}
          key={1}
        />
      );
    }
    setRestrictionsFields(restrictionsFieldsClone);
  };

  /**
   * This function us use to load existed data or load first spinser blank form.
   */
  const loadExistedFailureToRenewData = (failureTRDataArr) => {
    let failureTRDataClone = [];
    if (failureTRDataArr?.length > 0) {
      failureTRDataArr.forEach((data, index) => {
        failureTRDataClone.push(
          <FailureToRenew
            key={index + 1}
            fieldNumber={index + 1}
            onFailureToRenewChange={updateFailureToRenewArray}
            failureToRenewData={data}
          />
        );
      });
      initialItemCount.current.renewResignNewItem = failureTRDataArr?.length;
      setFailureToRenewCount(failureTRDataArr?.length);
    } else {
      failureTRDataClone.push(
        <FailureToRenew
          fieldNumber={1}
          onFailureToRenewChange={updateFailureToRenewArray}
          key={1}
        />
      );
    }
    setFailureToRenewFields(failureTRDataClone);
  };

  useEffect(() => {
    if (restrictionsData?.data) {
      const {
        privilegeLossRestrictions = [],
        privilegeRenewResign = [],
        responsePrivilegeLossRestrictions = "",
        responsePrivilegeRenewResign = "",
      } = restrictionsData?.data;
      loadExistedRestrictionData(privilegeLossRestrictions);
      loadExistedFailureToRenewData(privilegeRenewResign);
      //updateRestrictionsIndc(responsePrivilegeLossRestrictions);
      //updateFailureToRenewIndc(responsePrivilegeRenewResign);
      setRadioValues({
        ...radioValues,
        responsePrivilegeLossRestrictions: responsePrivilegeLossRestrictions,
        responsePrivilegeRenewResign: responsePrivilegeRenewResign,
      });
      dispatch(
        actions.updateWithoutPost({
          type: "UPDATE_RESTRICTIONS_INDC",
          data: responsePrivilegeLossRestrictions,
        })
      );
      dispatch(
        actions.updateWithoutPost({
          type: "UPDATE_FAILURE_TO_RENEW_INDC",
          data: responsePrivilegeRenewResign,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restrictionsData]);

  /**
   * This function call when user change any value from restriction form or add new restriction
   * @param {*} restrictionObj Updated restriction object
   */
  const updateRestrictionArray = (restrictionObj) => {
    if (restrictionObj?.fieldNumber) {
      let index =
        restrictionDataArray.current?.privilegeLossRestrictions?.findIndex(
          (data) => data?.fieldNumber === restrictionObj?.fieldNumber
        );
      if (index === -1) {
        restrictionDataArray.current.privilegeLossRestrictions?.push(
          restrictionObj
        );
      } else {
        restrictionDataArray.current.privilegeLossRestrictions[index] =
          restrictionObj;
      }
      setRestrictionCount(
        restrictionDataArray.current?.privilegeLossRestrictions?.length
      );
    }
  };

  /**
   * This function call when user change any value from failureToRenew form or add new failureToRenew
   * @param {*} failureToRenewObj Updated failureToRenew object
   */
  const updateFailureToRenewArray = (failureToRenewObj) => {
    if (failureToRenewObj?.fieldNumber) {
      let index = restrictionDataArray.current?.privilegeRenewResign?.findIndex(
        (data) => data?.fieldNumber === failureToRenewObj?.fieldNumber
      );
      if (index === -1) {
        restrictionDataArray.current?.privilegeRenewResign?.push(
          failureToRenewObj
        );
      } else {
        restrictionDataArray.current.privilegeRenewResign[index] =
          failureToRenewObj;
      }
      setFailureToRenewCount(
        restrictionDataArray.current?.privilegeRenewResign?.length
      );
    }
  };

  /**
   * This function is used to update the restrictions indicator when the user changes a field value.
   * It also updates the Redux store with the current value of restrictionsIndc so the Restrictions
   * component can determine whether or not to disable its fields.
   * Note: An indicator function is only needed for Restriction Items since the fields of saved items needs to be disabled
   * @param {*} restrictionsIndc Updated restrictionsIndc value
   */
  const updateRestrictionsIndc = (restrictionsIndc) => {
    handleObjectChange(
      restrictionsIndc,
      "responsePrivilegeLossRestrictions",
      radioValues,
      setRadioValues
    );

    let actionData = {
      type: "UPDATE_RESTRICTIONS_INDC",
      data: restrictionsIndc,
    };
    dispatch(actions.updateWithoutPost(actionData));
  };

  /**
   * This function is used to update the restrictions indicator when the user changes a field value.
   * It also updates the Redux store with the current value of restrictionsIndc so the Restrictions
   * component can determine whether or not to disable its fields.
   * @param {*} failureToRenewIndc Updated failureToRenewIndc value
   */
  const updateFailureToRenewIndc = (failureToRenewIndc) => {
    handleObjectChange(
      failureToRenewIndc,
      "responsePrivilegeRenewResign",
      radioValues,
      setRadioValues
    );

    let actionData = {
      type: "UPDATE_FAILURE_TO_RENEW_INDC",
      data: failureToRenewIndc,
    };
    dispatch(actions.updateWithoutPost(actionData));
  };

  /**
   * This function is used to remove only additional fields.
   */
  const removeRestriction = () => {
    if (restrictionsFields.length === MIN_FIELDS) return;
    let CObj =
      restrictionDataArray.current?.privilegeLossRestrictions[
        restrictionCount - 1
      ];
    if (CObj !== undefined) {
      if (CObj?.fieldNumber === restrictionCount) {
        restrictionDataArray.current?.privilegeLossRestrictions?.pop();
        let currentCount =
          restrictionCount > 1 ? restrictionCount - 1 : restrictionCount;
        if (
          currentCount === initialItemCount.current?.lossRestrictionsNewItem
        ) {
          setNewItem({ ...noNewItem, lossRestrictionsNewItem: true });
        }
        setRestrictionCount(currentCount);
        setRestrictionsFields(
          restrictionsFields.slice(0, restrictionsFields.length - 1)
        );
      }
    }
  };

  /**
   * This function is used to add more fields in the form
   */
  const addOneMoreRestriction = () => {
    setRestrictionCount(restrictionCount + 1);
    setNewItem({ ...noNewItem, lossRestrictionsNewItem: false });
    setRestrictionsFields([
      ...restrictionsFields,
      <Restriction
        onRestrictionChage={updateRestrictionArray}
        fieldNumber={restrictionCount + 1}
        key={restrictionCount + 1}
      />,
    ]);
  };

  /**
   * This function is used to remove only additional fields.
   */
  const removeOneFailureToRenew = () => {
    if (failureToRenewFields.length === MIN_FIELDS) return;
    let SObj =
      restrictionDataArray.current?.privilegeRenewResign[
        failureToRenewCount - 1
      ];
    if (SObj !== undefined) {
      if (SObj?.fieldNumber === failureToRenewCount) {
        restrictionDataArray.current?.privilegeRenewResign?.pop();
        let currentCount =
          failureToRenewCount > 1
            ? failureToRenewCount - 1
            : failureToRenewCount;
        if (currentCount === initialItemCount.current?.renewResignNewItem) {
          setNewItem({ ...noNewItem, renewResignNewItem: true });
        }
        setFailureToRenewCount(currentCount);
        setFailureToRenewFields(
          failureToRenewFields.slice(0, failureToRenewFields.length - 1)
        );
      }
    }
  };

  /**
   * This function is used to add more fields in the form for failureToRenew
   */
  const addOneMoreFailureToRenew = () => {
    setFailureToRenewCount(failureToRenewCount + 1);
    setNewItem({ ...noNewItem, renewResignNewItem: false });
    setFailureToRenewFields([
      ...failureToRenewFields,
      <FailureToRenew
        fieldNumber={failureToRenewCount + 1}
        onFailureToRenewChange={updateFailureToRenewArray}
        key={failureToRenewCount + 1}
      />,
    ]);
  };

  /**
   * This function is called when the user tries to save, either by clicking on a side menu item
   * or by clicking one of the three save buttons. Calls addBtnName(). Read that function description for more details.
   * Calls the sendPayload() function afterwards.
   * @param {*} navObject An object from navigate.json or an event object from clicking the save buttons
   * @param {*} location Location object from useLocation(), either from Sidebar or SaveButtons component
   */
  const handleSubmit = (navObject, location) => {
    let payload = {
      privilegeLossRestrictions: [],
      privilegeRenewResign: [],
      responsePrivilegeLossRestrictions: "",
      responsePrivilegeRenewResign: "",
    };
    payload.privilegeLossRestrictions =
      restrictionDataArray.current?.privilegeLossRestrictions;
    payload.privilegeRenewResign =
      restrictionDataArray.current?.privilegeRenewResign;
    payload.responsePrivilegeLossRestrictions =
      radioValues?.responsePrivilegeLossRestrictions;
    payload.responsePrivilegeRenewResign =
      radioValues?.responsePrivilegeRenewResign;

    navDataObj.current = addBtnName(navObject, navigationList, location);

    let dateErrMsgsFlag = dateValidation(payload, setResponse);

    if (dateErrMsgsFlag === false) {

      let params = {
        dispatch: dispatch,
        actionData: postRestrictionsData,
        requestBody: payload,
        response: response,
        setResponse: setResponse,
        navObject: navDataObj.current,
        navigate: navigate,
      };

      sendPayload(params);
    }
  };

  function dateValidation(payload, setResponse) {

    let dateErrMsgs = [];
    let dateErrMsgsFlag = false;

    let restrictionsArray = payload.privilegeLossRestrictions;
    let renewArray = payload.privilegeRenewResign;
    let restrictionDateErrMsg = "";
    let renewDateErrMsg = "";

    for (let i = 0; i < restrictionsArray.length; i++) {
      if (restrictionsArray[i].restrictionDate === "Invalid Date") {
        restrictionDateErrMsg = "Please enter a valid Restriction Date in Restriction Section " + (i + 1) + ".";
        dateErrMsgs.push(restrictionDateErrMsg);
        dateErrMsgsFlag = true;
      }
    }

    for (let i = 0; i < renewArray.length; i++) {
      if (renewArray[i].renewDate === "Invalid Date") {
        renewDateErrMsg = "Please enter a valid Renew Date in Renew Section " + (i + 1) + ".";
        dateErrMsgs.push(renewDateErrMsg);
        dateErrMsgsFlag = true;
      }
    }

    if (dateErrMsgsFlag === true) {
      let userStepsMsg = "Do you wish to remain on this page to correct your data and resubmit your changes? If so click the 'OK' button below. If you prefer you may discard your changes and proceed to the page indicated by the button you clicked. To do this click on the 'Cancel' button to Cancel your updates instead."
      dateErrMsgs.push(userStepsMsg);
      setResponse({
        ...response,
        show: true,
        message: dateErrMsgs.join("\r\n\n"),
      });
    }

    return dateErrMsgsFlag;
  }

  if (!styleProps) return null; // Needed so app does not crash on first render
  return (
    <Styled styleProps={styleProps}>
      {response?.show && (
        <ShowResponse
          headerText={response?.headerText}
          message={response?.message}
          show={response?.show}
          handleCancel={() =>
            handleCancelPopUpBtn(
              response,
              setResponse,
              navDataObj.current,
              navigate
            )
          }
          handleOkay={() => handleOkayPopUpBtn(response, setResponse)}
        />
      )}
      <SubPage properties={properties} handleSideMenuSave={handleSubmit}>
        <div
          id="restrictions-form-body"
          className="form-body-container space-between-text"
        >
          <div id="restriction-container">
            <div id="restriction-header">
              <h4>Hospital Privileges Restrictions</h4>
              <p>
                Any change to previously entered information will be effective
                immediately.
              </p>
            </div>
            <div id="restriction-body">
              <CustomRadio
                value={radioValues?.responsePrivilegeLossRestrictions}
                formLabel="Within the past ten years, has there has been any loss or
                  involuntary restriction of your hospital privileges or removal
                  of your medical staff membership related to the quality of
                  patient care you delivered and where procedural due process
                  has been afforded, exhausted or waived?"
                onChangeCustom={(e) => updateRestrictionsIndc(e.target.value)}
                ariaLabel="has-there-been-restrictions"
              />
              <p>If yes, enter the information of the loss or restriction: </p>
              <p>
                Click the Add below button if you need to enter additional
                Information.
              </p>
              <div
                id="restriction-items"
                className="md-space-between-containers"
              >
                {restrictionsFields}
                <AddRemoveFieldButtons
                  handleAddField={addOneMoreRestriction}
                  handleRemoveField={removeRestriction}
                  disableAddButton={
                    restrictionCount === MAX_FIELDS ||
                    radioValues?.responsePrivilegeLossRestrictions === "N"
                  }
                  disableRemoveButton={
                    restrictionCount === MIN_FIELDS ||
                    radioValues?.responsePrivilegeLossRestrictions === "N" ||
                    noNewItem?.lossRestrictionsNewItem
                  }
                />
              </div>
            </div>
          </div>
          <hr />
          <div id="failure-to-renew-container">
            <div id="failure-to-renew-header">
              <h4>Failure to Renew</h4>
            </div>
            <div id="failure-to-renew-body">
              <CustomRadio
                value={radioValues?.responsePrivilegeRenewResign}
                formLabel="Have you failed to renew your professional privileges or
                  resigned from medical staff membership in lieu of a pending
                  disciplinary case against you related to the quality of
                  patient care you delivered?"
                onChangeCustom={(e) => updateFailureToRenewIndc(e.target.value)}
                ariaLabel="failed-to-renew"
              />
              <p>If yes, enter the information of the loss or restriction: </p>
              <p>
                Click the Add below button if you need to enter additional
                Information.
              </p>
              <div
                id="failure-to-renew-items"
                className="md-space-between-containers"
              >
                {failureToRenewFields}
                <AddRemoveFieldButtons
                  handleAddField={addOneMoreFailureToRenew}
                  handleRemoveField={removeOneFailureToRenew}
                  disableAddButton={
                    failureToRenewCount === MAX_FIELDS ||
                    radioValues?.responsePrivilegeRenewResign === "N"
                  }
                  disableRemoveButton={
                    failureToRenewCount === MIN_FIELDS ||
                    radioValues?.responsePrivilegeRenewResign === "N" ||
                    noNewItem?.renewResignNewItem
                  }
                />
              </div>
            </div>
          </div>
          <SaveButtons
            saveGoBack={handleSubmit}
            saveExit={handleSubmit}
            saveContinue={handleSubmit}
          />
        </div>
      </SubPage>
    </Styled>
  );
};

export default ScrollToTop(Restrictions);
