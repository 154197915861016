import React, { useState, useEffect, useCallback } from "react";
import Styled from "./styles";
import * as actions from "../../../../redux/actions";
import { useSelector, useDispatch } from "react-redux";
import { MenuItem, Box, TextField, Autocomplete } from "@mui/material";
import {
  handleObjectChange,
  handleDropdownList,
} from "../../../../utils/Utils";
import { searchMedicalSchools } from "../../../../utils/ModuleActions";
import debounce from "lodash.debounce";
import CustomPopper from "../../../Common/CustomPopper";

const MedicalSchool = ({ medicalSchoolData, onMedicalSchoolChange }) => {
  const [medicalSchool, setMedicalSchool] = useState({
    receiveDate: "",
    schoolName: "",
    schoolCode: "",
  });
  const [medicalSchoolOptions, setMedicalSchoolOptions] = useState([]);

  const { data: styleProps } = useSelector(
    ({ getStyleProps }) => getStyleProps
  );
  const { data: medicalSchoolsList } = useSelector(
    ({ searchMedicalSchools }) => searchMedicalSchools
  );
  const dispatch = useDispatch();

  /**
   * Used to set the initial local state for the Medical School field being rendered.
   * It uses the data passed in from the parent to set this component's local state.
   * Sets the options list to the initial value in the text field.
   */
  const setDefaultData = useCallback(() => {
    if (medicalSchoolData?.schoolCode) {
      const medicalSchoolD = {
        schoolName: medicalSchoolData?.schoolName,
        schoolCode: medicalSchoolData?.schoolCode,
      };
      setMedicalSchoolOptions([medicalSchoolD]);
      setMedicalSchool(medicalSchoolData);
    }
  }, [medicalSchoolData]);

  useEffect(() => {
    if (medicalSchoolData) {
      setDefaultData();
    }
  }, [medicalSchoolData, setDefaultData]);

  useEffect(() => {
    if (medicalSchool) {
      onMedicalSchoolChange(medicalSchool);
    }
  }, [medicalSchool, onMedicalSchoolChange]);

  useEffect(() => {
    if (Array.isArray(medicalSchoolsList?.data?.medicalSchools)) {
      setMedicalSchoolOptions(medicalSchoolsList?.data?.medicalSchools);
    }
  }, [medicalSchoolsList]);

  /**
   * Calculates a list of years for the year the user recieved their degree.
   * @returns listOfYears converted to an array of strings
   */
  const getListOfYears = () => {
    const currentYear = new Date().getFullYear();
    const range = (start, stop, step) =>
      Array.from(
        { length: (stop - start) / step + 1 },
        (_, i) => start + i * step
      );
    const listOfYears = range(currentYear, currentYear - 100, -1);

    return listOfYears.map(String);
  };

  /**
   * Called when the user selects a new value from the drop down list.
   * Sets the local state variable with the updated data.
   * Clears the options list when the user clicks to clear. This means there is not any text in the text field.
   * If a value is selected, it sets the options to the selected value so the previous query results do not appear in the options list.
   * @param {*} e Event object from the onChange event
   * @param {*} newValue The new value the user has selected
   * @param {*} textFieldPropName The name of the property that contains the field value that needs to be updated
   * @param {*} codePropName The name of the property that contains the code value that needs to be updated
   */
  const handleAutocomplete = (e, newValue, textFieldPropName, codePropName) => {
    if (newValue === null) {
      setMedicalSchoolOptions([]);
      setMedicalSchool({
        ...medicalSchool,
        [textFieldPropName]: "",
        [codePropName]: "",
      });
    } else {
      setMedicalSchoolOptions([
        {
          [textFieldPropName]: newValue?.[textFieldPropName],
          [codePropName]: newValue?.[codePropName],
        },
      ]);
      setMedicalSchool({
        ...medicalSchool,
        [textFieldPropName]: newValue?.[textFieldPropName],
        [codePropName]: newValue?.[codePropName],
      });
    }
  };

  /**
   * Called by the lodash debounce function to debounce the search of the Medical Schools
   * Clears the options list in between searches so previous values are not in the filtered options list.
   * Waits to call the api for values until a minimum of 3 characters are typed.
   * @param {*} e Event object from the onChange event
   * @returns Nothing. Used to exit the function if the 3 character minimum requirement is not met.
   */
  const handleMedicalSchoolSearch = (e) => {
    setMedicalSchoolOptions([]);
    if (e?.target?.value.length < 3) return;
    dispatch(
      actions.commonAction(searchMedicalSchools, { q: e?.target?.value })
    );
  };
  const callMedicalSchoolDebounced = debounce(handleMedicalSchoolSearch, 500);

  return (
    <Styled styleProps={styleProps}>
      <div id="medical-school-container">
        <div id="medical-school-header">
          <h4>Education</h4>
          <p>Medical school from which you recieved your degree</p>
        </div>
        <div id="medical-school-body" className="sm-space-between-containers">
          <Autocomplete
            id="medical-school"
            name="medical_school"
            options={medicalSchoolOptions}
            getOptionLabel={(option) => option?.schoolName || ""}
            value={
              medicalSchool?.schoolName
                ? medicalSchoolOptions.find(
                    (option) => option?.schoolCode === medicalSchool?.schoolCode
                  )
                : null
            }
            onChange={(e, newValue) => {
              handleAutocomplete(e, newValue, "schoolName", "schoolCode");
            }}
            isOptionEqualToValue={(option, value) =>
              option?.schoolCode === value?.schoolCode
            }
            renderOption={(props, option) =>
              handleDropdownList(props, option.schoolCode, option.schoolName)
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Medical School"
                onChange={callMedicalSchoolDebounced}
                placeholder="Enter a medical school"
                InputLabelProps={{ shrink: true }}
              />
            )}
            PopperComponent={(props) => <CustomPopper {...props} />}
          />
          <Box sx={{ maxWidth: 360 }}>
            <TextField
              id="year-degree-recieved"
              value={medicalSchool?.receiveDate}
              label="Year degree received"
              placeholder="Select year degree was received"
              select
              fullWidth
              InputLabelProps={{ shrink: true }}
              onChange={(e) =>
                handleObjectChange(
                  e.target.value,
                  "receiveDate",
                  medicalSchool,
                  setMedicalSchool
                )
              }
              inputProps={{
                "aria-hidden": "false",
              }}
              SelectProps={{
                MenuProps: {
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getcontentanchorel: null,
                  PaperProps: {
                    style: {
                      maxHeight: "20%",
                    },
                  },
                },
              }}
            >
              {getListOfYears().map((val) => (
                <MenuItem key={val} value={val}>
                  {val}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </div>
      </div>
    </Styled>
  );
};

export default MedicalSchool;
