import {
    GET_MAILING_KEY
  } from "../actions/type";
  
  const initialState = {
    loading: null,
    error: null,
    data: null,
  };
  
  // eslint-disable-next-line import/no-anonymous-default-export
  export default (state = initialState, action) => {
    switch (action.type) {
      case GET_MAILING_KEY:
        return {
          ...state,
          loading: false,
          data: action.data,
        };
      default:
        return state;
    }
  };
  