import { useState, useEffect } from "react";
import Styled from "./styles";
import TextField from "@mui/material/TextField";
import { Grid } from "@mui/material";
import { handleObjectChange } from "../../../../utils/Utils";

export default function Membership({
  fieldNumber,
  onMembershipChange,
  membershipData = undefined,
}) {
  const MAX_CHAR_LENGTH = 254;
  const [charCount, setCharCount] = useState(MAX_CHAR_LENGTH);
  const [membershipObj, setMembershipObj] = useState({
    organization: "",
    deleteIndc: "",
    fieldNumber: fieldNumber,
  });

  /**
   * This function is used to load existed form data in the form
   */
  const setProfMembershipData = () => {
    if (membershipData?.membershipKey) {
      setMembershipObj({
        ...membershipObj,
        ...membershipData,
        fieldNumber: fieldNumber,
      });
      setCharCount(MAX_CHAR_LENGTH - membershipData?.organization?.length);
    }
  };

  useEffect(() => {
    if (membershipData) setProfMembershipData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [membershipData]);

  useEffect(() => {
    if (membershipObj) onMembershipChange(membershipObj);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [membershipObj]);

  return (
    <Styled>
      {fieldNumber > 1 && <hr />}
      <div id={`membership-item-${fieldNumber}`}>
        <div id={`membership-item-header-${fieldNumber}`}>
          <h4>Professional Membership Item {fieldNumber}</h4>
        </div>
        <Grid
          id={`membership-item-body-${fieldNumber}`}
          container
          columnSpacing={3}
          rowSpacing={0}
        >
          <Grid item xs={12} sm={12}>
            <TextField
              label="Describe your professional membership"
              className="multiline-textbox"
              fullWidth
              multiline
              rows={6}
              inputProps={{ maxLength: MAX_CHAR_LENGTH }}
              value={membershipObj?.organization}
              onChange={(e) => {
                setCharCount(MAX_CHAR_LENGTH - e.target?.value?.length);
                handleObjectChange(
                  e.target?.value,
                  "organization",
                  membershipObj,
                  setMembershipObj
                );
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} container justifyContent="flex-end">
            <p>{charCount} characters</p>
          </Grid>
        </Grid>
      </div>
    </Styled>
  );
}
