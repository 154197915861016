import styled from "styled-components/macro";

export default styled.div`
  .form-body-container hr {
    margin-top: 20px;
  }
  #practice-items-container > hr {
    margin-bottom: 35px;
    margin-top: 25px;
  }
`;
