import React, { Fragment } from "react";

const MissingElements = ({ missingEleData }) => {
  return (
    <table>
      <tbody>
        {missingEleData?.length > 0 &&
          missingEleData.map((ele, index) => {
            return (
              <Fragment key={index}>
                {ele?.msg && (
                  <tr>
                    <th colSpan={2} align="left">
                      {ele?.msg?.trim()}
                    </th>
                  </tr>
                )}
                <tr>
                  <td colSpan={ele?.section?.length > 0 ? 1 : 2}>{ele?.page?.trim()}{ele.section?.length > 0 ? ":" : ""}</td>
                  {ele?.section && <td>{ele?.section?.trim()}</td>}
                </tr>
              </Fragment>
            );
          })}
      </tbody>
    </table>
  );
};

export default MissingElements;
