import React, { useState, useEffect } from "react";
import Styled from "./styles";
import SubBoxInfo from "../../../Common/SubBoxInfo";

const NYSLicense = ({ nysLicenseData }) => {
  const [nysLicense, setNYSLicense] = useState([]);

  useEffect(() => {
    if (nysLicenseData) {
      setNYSLicense([
        {
          displayName: "NY License Number",
          fieldValue: nysLicenseData?.licenseNumber,
          isDate: false,
        },
        {
          displayName: "Date Conferred",
          fieldValue: nysLicenseData?.licenseDate,
          isDate: true,
        },
        {
          displayName: "NPI",
          fieldValue: nysLicenseData?.npiNumber,
          isDate: false,
        },
      ]);
    }
  }, [nysLicenseData]);

  return (
    <Styled>
      <div id="nys-license-container">
        <div id="nys-license-header">
          <h4>NYS license to practice medicine</h4>
          <p>
            This information cannot be changed. If you feel it is not correct,
            please call the Physician Helpline{" "}
            <strong className="help-desk-number">1-888-338-6998</strong>
          </p>
        </div>
        <div id="nys-license-body">
          <SubBoxInfo data={nysLicense} tableName="New York State License Information" />
        </div>
      </div>
    </Styled>
  );
};

export default NYSLicense;
