import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import SubPageHeader from "../SubPageHeader";
import Sidebar from "../Sidebar";
import Styled from "./styles";
import { useSelector } from "react-redux";
import FormInstructions from "./SubPageText";

const SubPage = ({ properties, handleSideMenuSave, isLogout, children }) => {
  const { data: styleProps } = useSelector(
    ({ getStyleProps }) => getStyleProps
  );
  const isHelpFile = properties ? false : true;

  if (!styleProps) return null; // Needed so app does not crash on first render
  return (
    <>
      <Header
        handleSideMenuSave={handleSideMenuSave}
        isHelpFile={isHelpFile}
        isLogout={isLogout}
      />
      {!isHelpFile && !isLogout ? <SubPageHeader {...properties} /> : null}
      <Styled
        styleProps={styleProps}
        isHelpFile={isHelpFile}
        isLogout={isLogout}
      >
        {!isLogout ? (
          <Sidebar
            id="default-sidebar"
            handleSideMenuSave={handleSideMenuSave}
            isHelpFile={isHelpFile}
          />
        ) : null}
        <div className="subpage">
          {!isHelpFile ? (
            <h2 className="subpage-header">{properties?.titlePage}</h2>
          ) : null}
          {properties?.showInstructions && (
            <FormInstructions type={properties?.instructionType} />
          )}
          {children}
        </div>
      </Styled>
      <Footer />
    </>
  );
};

export default SubPage;
