import React, { useState, useEffect, useCallback } from "react";
import Styled from "./styles";
import { useSelector } from "react-redux";
import { Grid, Autocomplete, TextField } from "@mui/material";
import CustomPopper from "../../../Common/CustomPopper";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import {
  handleObjectChange,
  handleDropdownList,
  disableField,
} from "../../../../utils/Utils";

// Note: detailSource is used for the enabling/disabling of the fields for this page.
// Submitting a value of PW does not save in the DB anyway. Despite this, the logic still works.

const ConvictionItem = ({
  convictionItemData = undefined,
  fieldNumber,
  onConvictionItemChange,
}) => {
  const [convictionItem, setConvictionItem] = useState({
    stateCode: "",
    offense: "",
    convictionDate: "",
    detailSource: "PW", // so new entries can have this submitted
    fieldNumber: fieldNumber,
  });
  const [stateOptions, setStateOptions] = useState([]);
  const MAX_CHAR_LENGTH = 254;
  const [charCount, setCharCount] = useState(MAX_CHAR_LENGTH);

  const { data: stateList } = useSelector(({ getMaster }) => getMaster);
  const { data: styleProps } = useSelector(
    ({ getStyleProps }) => getStyleProps
  );

  const { data: crimConvictsIndc } = useSelector(
    ({ updatedIndicator }) => updatedIndicator
  );

  /**
   * Used to set the initial local state for the current Conviction Item being rendered.
   * It uses the data passed in from the parent and adds one more prop called fieldNumber.
   * fieldNumber is needed for the logic of adding, removing, and updating of that data in the field.
   * Note: Initial fields will have all the column attributes from the initial GET request. New fields will have filtered list since that is all that is needed.
   */
  const setDefaultData = useCallback(() => {
    setConvictionItem({
      ...convictionItemData,
      fieldNumber: fieldNumber,
    });
    setCharCount(MAX_CHAR_LENGTH - convictionItemData?.offense?.length);
  }, [convictionItemData, fieldNumber]);

  useEffect(() => {
    if (convictionItem) {
      onConvictionItemChange(convictionItem);
    }
  }, [convictionItem, onConvictionItemChange]);

  useEffect(() => {
    if (convictionItemData) setDefaultData();
  }, [convictionItemData, setDefaultData]);

  useEffect(() => {
    if (stateList?.data?.states) setStateOptions(stateList?.data?.states);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateList]);

  /**
   * Called when the user selects a new value from the drop down list.
   * Sets the local state variable with the updated data.
   * @param {*} e Event object from the onChange event
   * @param {*} newValue The new value the user has selected
   * @param {*} codePropName The name of the property that contains the code value that needs to be updated
   */
  const handleAutocomplete = (e, newValue, codePropName) => {
    if (newValue === null) {
      setConvictionItem({
        ...convictionItem,
        [codePropName]: "",
      });
    } else {
      setConvictionItem({
        ...convictionItem,
        [codePropName]: newValue?.value,
      });
    }
  };

  return (
    <Styled styleProps={styleProps}>
      {fieldNumber > 1 && <hr />}
      <div id={`conviction-item-${fieldNumber}`}>
        <Grid
          id={`conviction-item-body-${fieldNumber}`}
          container
          columnSpacing={3}
          rowSpacing={0}
        >
          <Grid item xs={12} sm={6}>
            <Autocomplete
              id={`conviction-state-${fieldNumber}`}
              name={`conviction_state_${fieldNumber}`}
              options={stateOptions}
              getOptionLabel={(option) => option?.description || ""}
              disabled={disableField(
                !convictionItem?.convictionKey,
                fieldNumber,
                crimConvictsIndc,
                convictionItem?.detailSource
              )}
              value={
                convictionItem?.stateCode
                  ? stateOptions.find(
                      (option) => option?.value === convictionItem?.stateCode
                    )
                  : null
              }
              onChange={(e, newValue) => {
                handleAutocomplete(e, newValue, "stateCode");
              }}
              isOptionEqualToValue={(option, currValue) =>
                option?.value === currValue?.value
              }
              renderOption={(props, option) =>
                handleDropdownList(props, option.value, option.description)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="State"
                  placeholder="Select a state"
                  InputLabelProps={{ shrink: true }}
                />
              )}
              PopperComponent={(props) => <CustomPopper {...props} />}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Date of Action Taken (MM-DD-YYYY)"
                inputFormat="MM-DD-YYYY"
                name={`date_of_action_taken_${fieldNumber}`}
                disabled={disableField(
                  !convictionItem?.convictionKey,
                  fieldNumber,
                  crimConvictsIndc,
                  convictionItem?.detailSource
                )}
                value={
                  convictionItem?.convictionDate
                    ? dayjs(convictionItem?.convictionDate)
                    : null
                }
                onChange={(date) =>
                  handleObjectChange(
                    date ? dayjs(date).format("YYYY-MM-DD") : null,
                    "convictionDate",
                    convictionItem,
                    setConvictionItem
                  )
                }
                inputProps={{
                  placeholder: "MM-DD-YYYY",
                }}
                renderInput={(params) => (
                  <TextField {...params} InputLabelProps={{ shrink: true }} />
                )}
                className="datepicker"
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              id={`offense-${fieldNumber}`}
              className="multiline-textbox"
              label="Offense"
              placeholder="Describe the offense"
              fullWidth
              multiline
              rows={6}
              value={convictionItem?.offense}
              disabled={disableField(
                !convictionItem?.convictionKey,
                fieldNumber,
                crimConvictsIndc,
                convictionItem?.detailSource
              )}
              inputProps={{ maxLength: MAX_CHAR_LENGTH }}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => {
                setCharCount(MAX_CHAR_LENGTH - e.target?.value?.length);
                handleObjectChange(
                  e.target.value,
                  "offense",
                  convictionItem,
                  setConvictionItem
                );
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} container justifyContent="flex-end">
            <p>{charCount} characters</p>
          </Grid>
        </Grid>
      </div>
    </Styled>
  );
};

export default ConvictionItem;
