import { useState, useEffect } from "react";
import Styled from "./styles";
import { Grid, Autocomplete, TextField } from "@mui/material";
import {
  handleObjectChange,
  handleDropdownList,
  disableField,
} from "../../../../utils/Utils";
import CustomPopper from "../../../Common/CustomPopper";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

export default function OutOfState({
  fieldNumber,
  onOutOfStateChange,
  outOfStateData = undefined,
}) {
  const MAX_CHAR_LENGTH = 254;
  const [actionCount, setActionCount] = useState(MAX_CHAR_LENGTH);
  const [misconductCount, setMisconductCount] = useState(MAX_CHAR_LENGTH);
  const [stateOptions, setStateOptions] = useState([]);
  const [isOPMC, setIsOPMC] = useState(false);
  const [outOfStateObj, setOutOfStateObj] = useState({
    finalActionText: "",
    actionSummary: "",
    stateCode: "",
    actionDate: null,
    detailSource: "PW", // so new entries can have this submitted
    fieldNumber: fieldNumber,
  });
  const { data: stateList } = useSelector(({ getMaster }) => getMaster);
  const { data: anyActionIndc } = useSelector(
    ({ updatedIndicator }) => updatedIndicator
  );

  /**
   * This function is used to load existed form data in the form
   */
  const setOutOfStateData = () => {
    if (outOfStateData?.stateCode) {
      setOutOfStateObj({
        ...outOfStateObj,
        ...outOfStateData,
        fieldNumber: fieldNumber,
      });
      setActionCount(MAX_CHAR_LENGTH - outOfStateData?.finalActionText?.length);
      setMisconductCount(
        MAX_CHAR_LENGTH - outOfStateData?.actionSummary?.length
      );
    }
  };

  useEffect(() => {
    if (outOfStateData) {
      setOutOfStateData();

      // If detailSource is not one of the following, then the data comes from OPMC and not the physician
      // If the data is from OPMC, the physician cannot edit that field
      if (
        outOfStateData?.detailSource !== "PH" &&
        outOfStateData?.detailSource !== "PP" &&
        outOfStateData?.detailSource !== "PW"
      ) {
        setIsOPMC(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outOfStateData]);

  useEffect(() => {
    if (stateList?.data?.states) setStateOptions(stateList?.data?.states);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateList]);

  useEffect(() => {
    if (outOfStateObj) onOutOfStateChange(outOfStateObj);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outOfStateObj]);

  return (
    <Styled>
      {fieldNumber > 1 && <hr />}
      <div
        id={`out-of-state-action-item-${fieldNumber}`}
        className="out-of-state-action-item"
      >
        <div id={`out-of-state-action-item-header-${fieldNumber}`}>
          <h4>Out-of-State Licensee Action {fieldNumber}</h4>
        </div>
        <Grid
          id={`out-of-state-action-item-body-${fieldNumber}`}
          container
          columnSpacing={3}
          rowSpacing={0}
        >
          <Grid item xs={12} sm={6}>
            <Autocomplete
              id={`licensee-action-state-${fieldNumber}`}
              name={`licensee-action_state_${fieldNumber}`}
              options={stateOptions}
              getOptionLabel={(option) => option?.description}
              disabled={
                disableField(
                  !outOfStateObj?.actionKey,
                  fieldNumber,
                  anyActionIndc
                ) || isOPMC
              }
              value={
                outOfStateObj?.stateCode && stateOptions?.length > 0
                  ? stateOptions.find(
                      (state) => state?.value === outOfStateObj?.stateCode
                    )
                  : null
              }
              onChange={(e, newValue) => {
                setOutOfStateObj({
                  ...outOfStateObj,
                  stateCode: newValue?.value,
                });
              }}
              isOptionEqualToValue={(option, currValue) =>
                option?.value === currValue?.value
              }
              renderOption={(props, option) =>
                handleDropdownList(props, option.value, option.description)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="State"
                  placeholder="Select a state"
                  InputLabelProps={{ shrink: true }}
                />
              )}
              PopperComponent={(props) => <CustomPopper {...props} />}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Date of Action Taken (MM-DD-YYYY)"
                inputFormat="MM-DD-YYYY"
                name={`date-of-action-taken-${fieldNumber}`}
                disabled={
                  disableField(
                    !outOfStateObj?.actionKey,
                    fieldNumber,
                    anyActionIndc
                  ) || isOPMC
                }
                value={
                  outOfStateObj?.actionDate
                    ? dayjs(outOfStateObj?.actionDate)
                    : null
                }
                onChange={(date) => {
                  handleObjectChange(
                    date ? dayjs(date).format("YYYY-MM-DD") : null,
                    "actionDate",
                    outOfStateObj,
                    setOutOfStateObj
                  );
                }}
                inputProps={{
                  placeholder: "MM-DD-YYYY",
                }}
                renderInput={(params) => (
                  <TextField {...params} InputLabelProps={{ shrink: true }} />
                )}
                className="datepicker"
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              label="Describe the action taken"
              className="multiline-textbox"
              multiline
              fullWidth
              rows={6}
              disabled={
                disableField(
                  !outOfStateObj?.actionKey,
                  fieldNumber,
                  anyActionIndc
                ) || isOPMC
              }
              inputProps={{ maxLength: MAX_CHAR_LENGTH }}
              value={outOfStateObj?.finalActionText}
              onChange={(e) => {
                setActionCount(MAX_CHAR_LENGTH - e.target?.value?.length);
                handleObjectChange(
                  e.target?.value,
                  "finalActionText",
                  outOfStateObj,
                  setOutOfStateObj
                );
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} container justifyContent="flex-end">
            <p>{actionCount} characters</p>
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              label="Summarize the misconduct"
              className="multiline-textbox"
              multiline
              fullWidth
              rows={6}
              disabled={
                disableField(
                  !outOfStateObj?.actionKey,
                  fieldNumber,
                  anyActionIndc
                ) || isOPMC
              }
              inputProps={{ maxLength: MAX_CHAR_LENGTH }}
              value={outOfStateObj?.actionSummary}
              onChange={(e) => {
                setMisconductCount(MAX_CHAR_LENGTH - e.target?.value?.length);
                handleObjectChange(
                  e.target?.value,
                  "actionSummary",
                  outOfStateObj,
                  setOutOfStateObj
                );
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} container justifyContent="flex-end">
            <p>{misconductCount} characters</p>
          </Grid>
        </Grid>
      </div>
    </Styled>
  );
}
