import {
  UPDATE_LIMITATIONS_INDC,
  UPDATE_SERVED_AS_FACULTY_INDC,
  UPDATE_CONVICTION_INDC,
  UPDATE_ANY_ACTION_INDC,
  UPDATE_RESTRICTIONS_INDC,
  UPDATE_FAILURE_TO_RENEW_INDC,
} from "../actions/type";

const initialState = {
  loading: null,
  error: null,
  data: null,
};

// All indicators that disable other form fields can go here
// The word INDC is added to the end of each action to make it more clear these are for indicators only
// even though the data for each of these will most likely only have the indicator value anyway

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SERVED_AS_FACULTY_INDC:
      return {
        ...state,
        data: action.data,
      };
    case UPDATE_LIMITATIONS_INDC:
      return {
        ...state,
        data: action.data,
      };
    case UPDATE_CONVICTION_INDC:
      return {
        ...state,
        data: action.data,
      };
    case UPDATE_ANY_ACTION_INDC:
      return {
        ...state,
        data: action.data,
      };
    case UPDATE_RESTRICTIONS_INDC:
      return {
        ...state,
        data: {
          ...state?.data,
          restrictionsIndc: action.data,
        },
      };
    case UPDATE_FAILURE_TO_RENEW_INDC:
      return {
        ...state,
        data: {
          ...state?.data,
          failureToRenewIndc: action.data,
        },
      };
    default:
      return state;
  }
};
