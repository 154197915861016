import {
  GET_STYLE_PROPS_REQUEST,
  GET_STYLE_PROPS_SUCCESS,
  GET_STYLE_PROPS_ERROR,
  GET_SPECIALITIES_DATA_REQUEST,
  GET_SPECIALITIES_DATA_SUCCESS,
  GET_SPECIALITIES_DATA_ERROR,
  GET_GME_DATA_ERROR,
  GET_GME_DATA_REQUEST,
  GET_GME_DATA_SUCCESS,
  SEARCH_SPONSOR_REQUEST,
  SEARCH_SPONSOR_SUCCESS,
  SEARCH_SPONSOR_ERROR,
  GET_NAVIGATE_LIST_REQUEST,
  GET_NAVIGATE_LIST_SUCCESS,
  GET_NAVIGATE_LIST_ERROR,
  GET_NAVIGATE_COMPLETED_REQUEST,
  GET_NAVIGATE_COMPLETED_SUCCESS,
  GET_NAVIGATE_COMPLETED_ERROR,
  GET_EDUCATION_DATA_REQUEST,
  GET_EDUCATION_DATA_SUCCESS,
  GET_EDUCATION_DATA_ERROR,
  POST_EDUCATION_DATA_REQUEST,
  POST_EDUCATION_DATA_SUCCESS,
  POST_EDUCATION_DATA_ERROR,
  POST_GME_DATA_REQUEST,
  POST_GME_DATA_SUCCESS,
  POST_GME_DATA_ERROR,
  GET_CERTIFICATION_DATA_REQUEST,
  GET_CERTIFICATION_DATA_SUCCESS,
  GET_CERTIFICATION_DATA_ERROR,
  GET_PRACTICE_AREAS_REQUEST,
  GET_PRACTICE_AREAS_SUCCESS,
  GET_PRACTICE_AREAS_ERROR,
  GET_PHYSICIAN_INFO_REQUEST,
  GET_PHYSICIAN_INFO_SUCCESS,
  GET_PHYSICIAN_INFO_ERROR,
  PUT_PHYSICIAN_INFO_REQUEST,
  PUT_PHYSICIAN_INFO_SUCCESS,
  PUT_PHYSICIAN_INFO_ERROR,
  SEARCH_MEDICAL_SCHOOLS_REQUEST,
  SEARCH_MEDICAL_SCHOOLS_SUCCESS,
  SEARCH_MEDICAL_SCHOOLS_ERROR,
  GET_PHYSICIAN_CERTIFICATES_DATA_REQUEST,
  GET_PHYSICIAN_CERTIFICATES_DATA_ERROR,
  GET_PHYSICIAN_CERTIFICATES_DATA_SUCCESS,
  GET_TEACHING_DATA_REQUEST,
  GET_TEACHING_DATA_SUCCESS,
  GET_TEACHING_DATA_ERROR,
  POST_TEACHING_DATA_REQUEST,
  POST_TEACHING_DATA_SUCCESS,
  POST_TEACHING_DATA_ERROR,
  GET_PROF_MEMBERSHIP_DATA_REQUEST,
  GET_PROF_MEMBERSHIP_DATA_SUCCESS,
  GET_PROF_MEMBERSHIP_DATA_ERROR,
  POST_PROF_MEMBERSHIP_DATA_REQUEST,
  POST_PROF_MEMBERSHIP_DATA_SUCCESS,
  POST_PROF_MEMBERSHIP_DATA_ERROR,
  POST_PHYSICIAN_CERTIFICATES_DATA_REQUEST,
  POST_PHYSICIAN_CERTIFICATES_DATA_SUCCESS,
  POST_PHYSICIAN_CERTIFICATES_DATA_ERROR,
  GET_INSURANCE_DATA_REQUEST,
  GET_INSURANCE_DATA_SUCCESS,
  GET_INSURANCE_DATA_ERROR,
  POST_INSURANCE_DATA_REQUEST,
  POST_INSURANCE_DATA_SUCCESS,
  POST_INSURANCE_DATA_ERROR,
  GET_HOSPITAL_PRIVILEGES_DATA_REQUEST,
  GET_HOSPITAL_PRIVILEGES_DATA_SUCCESS,
  GET_HOSPITAL_PRIVILEGES_DATA_ERROR,
  POST_HOSPITAL_PRIVILEGES_DATA_REQUEST,
  POST_HOSPITAL_PRIVILEGES_DATA_SUCCESS,
  POST_HOSPITAL_PRIVILEGES_DATA_ERROR,
  SEARCH_HOSPITAL_REQUEST,
  SEARCH_HOSPITAL_SUCCESS,
  SEARCH_HOSPITAL_ERROR,
  GET_MALPRACTICE_DATA_REQUEST,
  GET_MALPRACTICE_DATA_SUCCESS,
  GET_MALPRACTICE_DATA_ERROR,
  POST_MALPRACTICE_DATA_REQUEST,
  POST_MALPRACTICE_DATA_SUCCESS,
  POST_MALPRACTICE_DATA_ERROR,
  GET_TRANSLATION_SERVICES_DATA_REQUEST,
  GET_TRANSLATION_SERVICES_DATA_SUCCESS,
  GET_TRANSLATION_SERVICES_DATA_ERROR,
  POST_TRANSLATION_SERVICES_DATA_REQUEST,
  POST_TRANSLATION_SERVICES_DATA_SUCCESS,
  POST_TRANSLATION_SERVICES_DATA_ERROR,
  GET_MASTER_DATA_REQUEST,
  GET_MASTER_DATA_SUCCESS,
  GET_MASTER_DATA_ERROR,
  GET_LICENSEE_ACTION_DATA_REQUEST,
  GET_LICENSEE_ACTION_DATA_SUCCESS,
  GET_LICENSEE_ACTION_DATA_ERROR,
  POST_LICENSEE_ACTION_DATA_ERROR,
  POST_LICENSEE_ACTION_DATA_REQUEST,
  POST_LICENSEE_ACTION_DATA_SUCCESS,
  GET_FINISH_DATA_REQUEST,
  GET_FINISH_DATA_SUCCESS,
  GET_FINISH_DATA_ERROR,
  POST_FINISH_DATA_REQUEST,
  POST_FINISH_DATA_SUCCESS,
  POST_FINISH_DATA_ERROR,
  GET_LIMITATIONS_DATA_REQUEST,
  GET_LIMITATIONS_DATA_SUCCESS,
  GET_LIMITATIONS_DATA_ERROR,
  POST_LIMITATIONS_DATA_REQUEST,
  POST_LIMITATIONS_DATA_SUCCESS,
  POST_LIMITATIONS_DATA_ERROR,
  GET_RESTRICTIONS_DATA_REQUEST,
  GET_RESTRICTIONS_DATA_SUCCESS,
  GET_RESTRICTIONS_DATA_ERROR,
  POST_RESTRICTIONS_DATA_REQUEST,
  POST_RESTRICTIONS_DATA_SUCCESS,
  POST_RESTRICTIONS_DATA_ERROR,
  GET_CONVICTIONS_DATA_REQUEST,
  GET_CONVICTIONS_DATA_SUCCESS,
  GET_CONVICTIONS_DATA_ERROR,
  POST_CONVICTIONS_DATA_REQUEST,
  POST_CONVICTIONS_DATA_SUCCESS,
  POST_CONVICTIONS_DATA_ERROR,
  GET_COMM_SERVICE_DATA_REQUEST,
  GET_COMM_SERVICE_DATA_SUCCESS,
  GET_COMM_SERVICE_DATA_ERROR,
  POST_COMM_SERVICE_DATA_REQUEST,
  POST_COMM_SERVICE_DATA_SUCCESS,
  POST_COMM_SERVICE_DATA_ERROR,
  GET_PRACTICE_DATA_REQUEST,
  GET_PRACTICE_DATA_SUCCESS,
  GET_PRACTICE_DATA_ERROR,
  POST_PRACTICE_DATA_REQUEST,
  POST_PRACTICE_DATA_SUCCESS,
  POST_PRACTICE_DATA_ERROR,
  GET_PUBLICATIONS_DATA_REQUEST,
  GET_PUBLICATIONS_DATA_SUCCESS,
  GET_PUBLICATIONS_DATA_ERROR,
  POST_PUBLICATIONS_DATA_REQUEST,
  POST_PUBLICATIONS_DATA_SUCCESS,
  POST_PUBLICATIONS_DATA_ERROR,
  GET_HELP_FILE_DATA_REQUEST,
  GET_HELP_FILE_DATA_SUCCESS,
  GET_HELP_FILE_DATA_ERROR,
  GET_HEALTH_PLANS_DATA_REQUEST,
  GET_HEALTH_PLANS_DATA_SUCCESS,
  GET_HEALTH_PLANS_DATA_ERROR,
  POST_HEALTH_PLANS_DATA_REQUEST,
  POST_HEALTH_PLANS_DATA_SUCCESS,
  POST_HEALTH_PLANS_DATA_ERROR,
  GET_LOGIN_REQUEST,
  GET_LOGIN_REQUEST_SUCCESS,
  GET_LOGIN_REQUEST_ERROR,
  POST_QUESTIONNAIRE_REQUEST,
  POST_QUESTIONNAIRE_SUCCESS,
  POST_QUESTIONNAIRE_ERROR,
  GET_ANALYTICS_URL_DATA_REQUEST,
  GET_ANALYTICS_URL_DATA_SUCCESS,
  GET_ANALYTICS_URL_DATA_ERROR
} from "../redux/actions/type";

export const getStyleProps = {
  path: "/json_data/styleProps.json",
  request: GET_STYLE_PROPS_REQUEST,
  success: GET_STYLE_PROPS_SUCCESS,
  error: GET_STYLE_PROPS_ERROR,
};

export const navigateActions = {
  path: "/json_data/navigate.json",
  request: GET_NAVIGATE_LIST_REQUEST,
  success: GET_NAVIGATE_LIST_SUCCESS,
  error: GET_NAVIGATE_LIST_ERROR,
};

export const login = {
  path: "/api/v1/authorize",
  request: GET_LOGIN_REQUEST,
  success: GET_LOGIN_REQUEST_SUCCESS,
  error: GET_LOGIN_REQUEST_ERROR,
};

export const navigateCompletedActions = {
  path: "/api/v1/physician/completionStatus",
  request: GET_NAVIGATE_COMPLETED_REQUEST,
  success: GET_NAVIGATE_COMPLETED_SUCCESS,
  error: GET_NAVIGATE_COMPLETED_ERROR,
};

export const getGMEData = {
  path: "/api/v1/physician/graduateMedicalEducation",
  request: GET_GME_DATA_REQUEST,
  success: GET_GME_DATA_SUCCESS,
  error: GET_GME_DATA_ERROR,
};

export const postGMEData = {
  path: "/api/v1/physician/graduateMedicalEducation",
  request: POST_GME_DATA_REQUEST,
  success: POST_GME_DATA_SUCCESS,
  error: POST_GME_DATA_ERROR,
};

export const getSpecialitiesData = {
  path: "/api/v1/GMESpecialities",
  request: GET_SPECIALITIES_DATA_REQUEST,
  success: GET_SPECIALITIES_DATA_SUCCESS,
  error: GET_SPECIALITIES_DATA_ERROR,
};

export const searchSponser = {
  path: "/api/v1/GMESponsors",
  request: SEARCH_SPONSOR_REQUEST,
  success: SEARCH_SPONSOR_SUCCESS,
  error: SEARCH_SPONSOR_ERROR,
};

export const getEducationData = {
  path: "/api/v1/physician/education",
  request: GET_EDUCATION_DATA_REQUEST,
  success: GET_EDUCATION_DATA_SUCCESS,
  error: GET_EDUCATION_DATA_ERROR,
};

export const postEducationData = {
  path: "/api/v1/physician/education",
  request: POST_EDUCATION_DATA_REQUEST,
  success: POST_EDUCATION_DATA_SUCCESS,
  error: POST_EDUCATION_DATA_ERROR,
};

export const getCertificationData = {
  path: "/api/v1/certifications",
  request: GET_CERTIFICATION_DATA_REQUEST,
  success: GET_CERTIFICATION_DATA_SUCCESS,
  error: GET_CERTIFICATION_DATA_ERROR,
};

export const getPhysicianCertificatesData = {
  path: "/api/v1/physician/certifications",
  request: GET_PHYSICIAN_CERTIFICATES_DATA_REQUEST,
  success: GET_PHYSICIAN_CERTIFICATES_DATA_SUCCESS,
  error: GET_PHYSICIAN_CERTIFICATES_DATA_ERROR,
};

export const postPhysicianCertificatesData = {
  path: "/api/v1/physician/certifications",
  request: POST_PHYSICIAN_CERTIFICATES_DATA_REQUEST,
  success: POST_PHYSICIAN_CERTIFICATES_DATA_SUCCESS,
  error: POST_PHYSICIAN_CERTIFICATES_DATA_ERROR,
};

export const getPracticeAreas = {
  path: "/api/v1/practices",
  request: GET_PRACTICE_AREAS_REQUEST,
  success: GET_PRACTICE_AREAS_SUCCESS,
  error: GET_PRACTICE_AREAS_ERROR,
};

export const getPhysicianInfo = {
  path: "/api/v1/physicianInformation",
  request: GET_PHYSICIAN_INFO_REQUEST,
  success: GET_PHYSICIAN_INFO_SUCCESS,
  error: GET_PHYSICIAN_INFO_ERROR,
};

export const putPhysicianInfo = {
  path: "/api/v1/physicianInformation",
  request: PUT_PHYSICIAN_INFO_REQUEST,
  success: PUT_PHYSICIAN_INFO_SUCCESS,
  error: PUT_PHYSICIAN_INFO_ERROR,
};

export const searchMedicalSchools = {
  path: "/api/v1/medicalSchools",
  request: SEARCH_MEDICAL_SCHOOLS_REQUEST,
  success: SEARCH_MEDICAL_SCHOOLS_SUCCESS,
  error: SEARCH_MEDICAL_SCHOOLS_ERROR,
};

export const getTeachingData = {
  path: "/api/v1/physician/teaching",
  request: GET_TEACHING_DATA_REQUEST,
  success: GET_TEACHING_DATA_SUCCESS,
  error: GET_TEACHING_DATA_ERROR,
};

export const postTeachingData = {
  path: "/api/v1/physician/teaching",
  request: POST_TEACHING_DATA_REQUEST,
  success: POST_TEACHING_DATA_SUCCESS,
  error: POST_TEACHING_DATA_ERROR,
};

export const getProfMembershipData = {
  path: "/api/v1/physician/professionalMemberships",
  request: GET_PROF_MEMBERSHIP_DATA_REQUEST,
  success: GET_PROF_MEMBERSHIP_DATA_SUCCESS,
  error: GET_PROF_MEMBERSHIP_DATA_ERROR,
};

export const postProfMembershipData = {
  path: "/api/v1/physician/professionalMemberships",
  request: POST_PROF_MEMBERSHIP_DATA_REQUEST,
  success: POST_PROF_MEMBERSHIP_DATA_SUCCESS,
  error: POST_PROF_MEMBERSHIP_DATA_ERROR,
};

export const getInsuranceData = {
  path: "/api/v1/physician/insurance",
  request: GET_INSURANCE_DATA_REQUEST,
  success: GET_INSURANCE_DATA_SUCCESS,
  error: GET_INSURANCE_DATA_ERROR,
};

export const postInsuranceData = {
  path: "/api/v1/physician/insurance",
  request: POST_INSURANCE_DATA_REQUEST,
  success: POST_INSURANCE_DATA_SUCCESS,
  error: POST_INSURANCE_DATA_ERROR,
};

export const getHospitalPrivilegesData = {
  path: "/api/v1/physician/hospitalPrivileges",
  request: GET_HOSPITAL_PRIVILEGES_DATA_REQUEST,
  success: GET_HOSPITAL_PRIVILEGES_DATA_SUCCESS,
  error: GET_HOSPITAL_PRIVILEGES_DATA_ERROR,
};

export const postHospitalPrivilegesData = {
  path: "/api/v1/physician/hospitalPrivileges",
  request: POST_HOSPITAL_PRIVILEGES_DATA_REQUEST,
  success: POST_HOSPITAL_PRIVILEGES_DATA_SUCCESS,
  error: POST_HOSPITAL_PRIVILEGES_DATA_ERROR,
};

export const searchHospital = {
  path: "/api/v1/hospitals/admit",
  request: SEARCH_HOSPITAL_REQUEST,
  success: SEARCH_HOSPITAL_SUCCESS,
  error: SEARCH_HOSPITAL_ERROR,
};

export const getMalpracticeData = {
  path: "/api/v1/physician/malpractice",
  request: GET_MALPRACTICE_DATA_REQUEST,
  success: GET_MALPRACTICE_DATA_SUCCESS,
  error: GET_MALPRACTICE_DATA_ERROR,
};

export const postMalpracticeData = {
  path: "/api/v1/physician/malpractice",
  request: POST_MALPRACTICE_DATA_REQUEST,
  success: POST_MALPRACTICE_DATA_SUCCESS,
  error: POST_MALPRACTICE_DATA_ERROR,
};

export const getTranslationServicesData = {
  path: "/api/v1/physician/translation",
  request: GET_TRANSLATION_SERVICES_DATA_REQUEST,
  success: GET_TRANSLATION_SERVICES_DATA_SUCCESS,
  error: GET_TRANSLATION_SERVICES_DATA_ERROR,
};

export const postTranslationServicesData = {
  path: "/api/v1/physician/translation",
  request: POST_TRANSLATION_SERVICES_DATA_REQUEST,
  success: POST_TRANSLATION_SERVICES_DATA_SUCCESS,
  error: POST_TRANSLATION_SERVICES_DATA_ERROR,
};

export const getMasterData = {
  path: "/api/v1/master",
  request: GET_MASTER_DATA_REQUEST,
  success: GET_MASTER_DATA_SUCCESS,
  error: GET_MASTER_DATA_ERROR,
};

export const getLicenseeActionData = {
  path: "/api/v1/physician/licenseeActions",
  request: GET_LICENSEE_ACTION_DATA_REQUEST,
  success: GET_LICENSEE_ACTION_DATA_SUCCESS,
  error: GET_LICENSEE_ACTION_DATA_ERROR,
};

export const postLicenseeActionData = {
  path: "/api/v1/physician/licenseeActions",
  request: POST_LICENSEE_ACTION_DATA_REQUEST,
  success: POST_LICENSEE_ACTION_DATA_SUCCESS,
  error: POST_LICENSEE_ACTION_DATA_ERROR,
};

export const getFinishData = {
  path: "/api/v1/physician/statement",
  request: GET_FINISH_DATA_REQUEST,
  success: GET_FINISH_DATA_SUCCESS,
  error: GET_FINISH_DATA_ERROR,
};

export const postFinishData = {
  path: "/api/v1/physician/statement",
  request: POST_FINISH_DATA_REQUEST,
  success: POST_FINISH_DATA_SUCCESS,
  error: POST_FINISH_DATA_ERROR,
};

export const getLimitationsData = {
  path: "/api/v1/physician/licenseeLimitations",
  request: GET_LIMITATIONS_DATA_REQUEST,
  success: GET_LIMITATIONS_DATA_SUCCESS,
  error: GET_LIMITATIONS_DATA_ERROR,
};

export const postLimitationsData = {
  path: "/api/v1/physician/licenseeLimitations",
  request: POST_LIMITATIONS_DATA_REQUEST,
  success: POST_LIMITATIONS_DATA_SUCCESS,
  error: POST_LIMITATIONS_DATA_ERROR,
};

export const getRestrictionsData = {
  path: "/api/v1/physician/privilegeLossRestrictions",
  request: GET_RESTRICTIONS_DATA_REQUEST,
  success: GET_RESTRICTIONS_DATA_SUCCESS,
  error: GET_RESTRICTIONS_DATA_ERROR,
};

export const postRestrictionsData = {
  path: "/api/v1/physician/privilegeLossRestrictions",
  request: POST_RESTRICTIONS_DATA_REQUEST,
  success: POST_RESTRICTIONS_DATA_SUCCESS,
  error: POST_RESTRICTIONS_DATA_ERROR,
};

export const getConvictionsData = {
  path: "/api/v1/physician/convictions",
  request: GET_CONVICTIONS_DATA_REQUEST,
  success: GET_CONVICTIONS_DATA_SUCCESS,
  error: GET_CONVICTIONS_DATA_ERROR,
};

export const postConvictionsData = {
  path: "/api/v1/physician/convictions",
  request: POST_CONVICTIONS_DATA_REQUEST,
  success: POST_CONVICTIONS_DATA_SUCCESS,
  error: POST_CONVICTIONS_DATA_ERROR,
};

export const getCommServiceData = {
  path: "/api/v1/physician/communityServices",
  request: GET_COMM_SERVICE_DATA_REQUEST,
  success: GET_COMM_SERVICE_DATA_SUCCESS,
  error: GET_COMM_SERVICE_DATA_ERROR,
};

export const postCommServiceData = {
  path: "/api/v1/physician/communityServices",
  request: POST_COMM_SERVICE_DATA_REQUEST,
  success: POST_COMM_SERVICE_DATA_SUCCESS,
  error: POST_COMM_SERVICE_DATA_ERROR,
};

export const getPracticeData = {
  path: "/api/v1/physician/practices",
  request: GET_PRACTICE_DATA_REQUEST,
  success: GET_PRACTICE_DATA_SUCCESS,
  error: GET_PRACTICE_DATA_ERROR,
};

export const postPracticeData = {
  path: "/api/v1/physician/practices",
  request: POST_PRACTICE_DATA_REQUEST,
  success: POST_PRACTICE_DATA_SUCCESS,
  error: POST_PRACTICE_DATA_ERROR,
};

export const getPublicationsData = {
  path: "/api/v1/physician/publications",
  request: GET_PUBLICATIONS_DATA_REQUEST,
  success: GET_PUBLICATIONS_DATA_SUCCESS,
  error: GET_PUBLICATIONS_DATA_ERROR,
};

export const postPublicationsData = {
  path: "/api/v1/physician/publications",
  request: POST_PUBLICATIONS_DATA_REQUEST,
  success: POST_PUBLICATIONS_DATA_SUCCESS,
  error: POST_PUBLICATIONS_DATA_ERROR,
};

export const getHelpFileData = {
  path: "/json_data/onlineHelpFileNew.json",
  request: GET_HELP_FILE_DATA_REQUEST,
  success: GET_HELP_FILE_DATA_SUCCESS,
  error: GET_HELP_FILE_DATA_ERROR,
};

export const getHealthPlansData = {
  path: "/api/v1/physician/healthPlanContracts",
  request: GET_HEALTH_PLANS_DATA_REQUEST,
  success: GET_HEALTH_PLANS_DATA_SUCCESS,
  error: GET_HEALTH_PLANS_DATA_ERROR,
};

export const postHealthPlansData = {
  path: "/api/v1/physician/healthPlanContracts",
  request: POST_HEALTH_PLANS_DATA_REQUEST,
  success: POST_HEALTH_PLANS_DATA_SUCCESS,
  error: POST_HEALTH_PLANS_DATA_ERROR,
};

export const postQuestionnaireData = {
  path: "/api/v1/physician/questionaire",
  request: POST_QUESTIONNAIRE_REQUEST,
  success: POST_QUESTIONNAIRE_SUCCESS,
  error: POST_QUESTIONNAIRE_ERROR,
};

export const getAnalyticsUrl = {
  path: "/api/v1/analyticsUrl",
  request: GET_ANALYTICS_URL_DATA_REQUEST,
  success: GET_ANALYTICS_URL_DATA_SUCCESS,
  error: GET_ANALYTICS_URL_DATA_ERROR,
};