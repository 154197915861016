import React, { useEffect, useRef, useState } from "react";
import Styled from "./styles";
import { useDispatch, useSelector } from "react-redux";
import ScrollToTop from "../../Common/HOC/ScrollToTop";
import SubPage from "../../Common/SubPage";
import Certification from "./Certification";
import AddRemoveFieldButtons from "../../Common/AddRemoveField";
import SaveButtons from "../../Common/SaveButtons";
import * as actions from "../../../redux/actions";
import {
  getCertificationData,
  getPhysicianCertificatesData,
  postPhysicianCertificatesData,
} from "../../../utils/ModuleActions";
import Subspeciality from "./Subspeciality";
import { useNavigate } from "react-router";
import ShowResponse from "../../Common/Messages";
import {
  addBtnName,
  sendPayload,
  handleCancelPopUpBtn,
  handleOkayPopUpBtn,
} from "../../../utils/Utils";

const BoardCertification = () => {
  const properties = {
    titlePage: "BOARD CERTIFICATION",
    showInstructions: true,
  };

  const MIN_FIELDS = 1;
  const MAX_FIELDS = 20;

  const [certificateCount, setCertificateCount] = useState(1);
  const [certificateFields, setCertificateFields] = useState([]);
  const [subspecialityCount, setSubspecialityCount] = useState(1);
  const [subspecialityFields, setSubspecialityFields] = useState([]);
  const [response, setResponse] = useState({
    headerText: "Error message",
    message: "",
    show: false,
  });
  const { data: navigationList } = useSelector(({ navigate }) => navigate);
  const { data: styleProps } = useSelector(
    ({ getStyleProps }) => getStyleProps
  );
  const { data: physicianCertificates } = useSelector(
    ({ physicianCertificates }) => physicianCertificates
  );

  const certificatesArray = useRef([]);
  const subspecialityArray = useRef([]);
  const navDataObj = useRef({});

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(actions.commonAction(getCertificationData));
    dispatch(actions.commonAction(getPhysicianCertificatesData));

    return () => {
      dispatch(
        actions.updateWithoutPost({
          type: "CLEAR_PHYSICIAN_CERTIFICATES_DATA",
          data: {},
        })
      );
    };
  }, [dispatch]);

  /**
   * This function us use to load existed data or load first spinser blank form.
   */
  const loadExistedCertiData = (certificates) => {
    let certificateFieldsClone = [];
    if (certificates?.length > 0) {
      certificates.forEach((data, index) => {
        certificateFieldsClone.push(
          <Certification
            key={index + 1}
            fieldNumber={index + 1}
            onCertificateChage={updateCertificateArray}
            certificateData={data}
          />
        );
      });
      setCertificateCount(certificates?.length);
    } else {
      certificateFieldsClone.push(
        <Certification
          onCertificateChage={updateCertificateArray}
          fieldNumber={1}
          key={1}
        />
      );
    }
    setCertificateFields(certificateFieldsClone);
  };

  /**
   * This function us use to load existed data or load first spinser blank form.
   */
  const loadExistedSubSData = (subspecialities) => {
    let subSFieldsClone = [];
    if (subspecialities?.length > 0) {
      subspecialities.forEach((data, index) => {
        subSFieldsClone.push(
          <Subspeciality
            key={index + 1}
            fieldNumber={index + 1}
            onSubspecialityChange={updateSubspecialityArray}
            subspecialityData={data}
          />
        );
      });
      setSubspecialityCount(subspecialities?.length);
    } else {
      subSFieldsClone.push(
        <Subspeciality
          fieldNumber={1}
          onSubspecialityChange={updateSubspecialityArray}
          key={1}
        />
      );
    }
    setSubspecialityFields(subSFieldsClone);
  };

  useEffect(() => {
    if (physicianCertificates?.data) {
      const { certifications = [], subCertifications = [] } =
        physicianCertificates?.data;
      loadExistedCertiData(certifications);
      loadExistedSubSData(subCertifications);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [physicianCertificates]);

  /**
   * This function call when user change any value fron certificat form or add new certificate
   * @param {*} certificateObj Updated certificate object
   */
  const updateCertificateArray = (certificateObj) => {
    if (certificateObj?.fieldNumber) {
      let index = certificatesArray.current?.findIndex(
        (data) => data?.fieldNumber === certificateObj?.fieldNumber
      );
      if (index === -1) {
        certificatesArray.current?.push(certificateObj);
      } else {
        certificatesArray.current[index] = certificateObj;
      }
      setCertificateCount(certificatesArray.current?.length);
    }
  };

  /**
   * This function call when user change any value from subspeciality form or add new subspeciality
   * @param {*} subspecialityObj Updated subspeciality object
   */
  const updateSubspecialityArray = (subspecialityObj) => {
    if (subspecialityObj?.fieldNumber) {
      let index = subspecialityArray.current?.findIndex(
        (data) => data?.fieldNumber === subspecialityObj?.fieldNumber
      );
      if (index === -1) {
        subspecialityArray.current?.push(subspecialityObj);
      } else {
        subspecialityArray.current[index] = subspecialityObj;
      }
      setSubspecialityCount(subspecialityArray.current?.length);
    }
  };

  /**
   * This function is used to remove only additional fields.
   */
  const removeOneCertificate = () => {
    if (certificateFields.length === MIN_FIELDS) return;
    let CObj = certificatesArray.current[certificateCount - 1];
    if (CObj !== undefined) {
      if (CObj?.fieldNumber === certificateCount) {
        certificatesArray.current?.pop();
        let currentCount =
          certificateCount > 1 ? certificateCount - 1 : certificateCount;
        setCertificateCount(currentCount);
        setCertificateFields(
          certificateFields.slice(0, certificateFields.length - 1)
        );
      }
    }
  };

  /**
   * This function is used to add more fields in the form
   */
  const addOneMoreCertificate = () => {
    setCertificateCount(certificateCount + 1);
    setCertificateFields([
      ...certificateFields,
      <Certification
        onCertificateChage={updateCertificateArray}
        fieldNumber={certificateCount + 1}
        key={certificateCount + 1}
      />,
    ]);
  };

  /**
   * This function is used to remove only additional fields.
   */
  const removeOneSubspeciality = () => {
    if (subspecialityFields.length === MIN_FIELDS) return;
    let SObj = subspecialityArray.current[subspecialityCount - 1];
    if (SObj !== undefined) {
      if (SObj?.fieldNumber === subspecialityCount) {
        subspecialityArray.current?.pop();
        let currentCount =
          subspecialityCount > 1 ? subspecialityCount - 1 : subspecialityCount;
        setSubspecialityCount(currentCount);
        setSubspecialityFields(
          subspecialityFields.slice(0, subspecialityFields.length - 1)
        );
      }
    }
  };

  /**
   * This function is used to add more fields in the form for subspeciality
   */
  const addOneMoreSubspeciality = () => {
    setSubspecialityCount(subspecialityCount + 1);
    setSubspecialityFields([
      ...subspecialityFields,
      <Subspeciality
        fieldNumber={subspecialityCount + 1}
        onSubspecialityChange={updateSubspecialityArray}
        key={subspecialityCount + 1}
      />,
    ]);
  };

  /**
   * This function is called when the user tries to save, either by clicking on a side menu item
   * or by clicking one of the three save buttons. Calls addBtnName(). Read that function description for more details.
   * Calls the sendPayload() function afterwards.
   * @param {*} navObject An object from navigate.json or an event object from clicking the save buttons
   * @param {*} location Location object from useLocation(), either from Sidebar or SaveButtons component
   */
  const handleSubmit = (navObject, location) => {
    let payload = {
      certifications: [],
      subCertifications: [],
    };
    payload.certifications = certificatesArray.current;
    payload.subCertifications = subspecialityArray.current;

    navDataObj.current = addBtnName(navObject, navigationList, location);

    let params = {
      dispatch: dispatch,
      actionData: postPhysicianCertificatesData,
      requestBody: payload,
      response: response,
      setResponse: setResponse,
      navObject: navDataObj.current,
      navigate: navigate,
    };

    sendPayload(params);
  };

  if (!styleProps) return null; // Needed so app does not crash on first render
  return (
    <Styled styleProps={styleProps}>
      {response?.show && (
        <ShowResponse
          headerText={response?.headerText}
          message={response?.message}
          show={response?.show}
          handleCancel={() =>
            handleCancelPopUpBtn(
              response,
              setResponse,
              navDataObj.current,
              navigate
            )
          }
          handleOkay={() => handleOkayPopUpBtn(response, setResponse)}
        />
      )}
      <SubPage properties={properties} handleSideMenuSave={handleSubmit}>
        <div
          id="board-certification-form-body"
          className="form-body-container space-between-text"
        >
          <div id="certification-container">
            <div id="certification-header">
              <h4>Board Certifications</h4>
              <p>
                <strong>(ABMS, AOA, or RCPSC recognized boards only)</strong>
              </p>
              <p>
                Click on the down arrow to select from a list of values for the
                field(s) below. Any change to previously entered information
                will be effective immediately.
              </p>
            </div>
            <div
              id="certification-items"
              className="md-space-between-containers"
            >
              {certificateFields}
              <AddRemoveFieldButtons
                handleAddField={addOneMoreCertificate}
                handleRemoveField={removeOneCertificate}
                disableAddButton={certificateCount >= MAX_FIELDS}
                disableRemoveButton={certificateCount <= MIN_FIELDS}
              />
            </div>
          </div>
          <hr />
          <div id="subspeciality-container">
            <div id="subspeciality-header">
              <h4>Subspeciality</h4>
              <p>Enter requested change in the space provided</p>
              <p>
                Click the <strong>Add</strong> button below if you need to enter
                additional Subspecialties.
              </p>
            </div>
            <div
              id="subspeciality-items"
              className="md-space-between-containers"
            >
              {subspecialityFields}
              <AddRemoveFieldButtons
                handleAddField={addOneMoreSubspeciality}
                handleRemoveField={removeOneSubspeciality}
                disableAddButton={
                  subspecialityCount >= MAX_FIELDS ? true : false
                }
                disableRemoveButton={
                  subspecialityCount <= MIN_FIELDS ? true : false
                }
              />
            </div>
          </div>
          <SaveButtons
            saveGoBack={handleSubmit}
            saveExit={handleSubmit}
            saveContinue={handleSubmit}
          />
        </div>
      </SubPage>
    </Styled>
  );
};

export default ScrollToTop(BoardCertification);
