import { combineReducers } from "redux";
import navigate from "./navigate";
import navigateCompleted from "./navigateCompleted";
import getStyleProps from "./getStyleProps";
import physicianInformation from "./physicianInformation";
import searchSponsor from "./searchSponsor";
import getPracticeAreas from "./getPracticeAreas";
import getSpecialities from "./getSpecialities";
import GMEData from "./GMEData";
import education from "./education";
import getCertification from "./getCertification";
import searchMedicalSchools from "./searchMedicalSchools";
import physicianCertificates from "./physicianCertificates";
import teaching from "./teaching";
import profMembership from "./profMembership";
import updatedIndicator from "./updatedIndicator";
import insurance from "./insurance";
import hospitalPrivileges from "./hospitalPrivileges";
import searchHospital from "./searchHospital";
import malpractice from "./malpractice";
import translationServices from "./translationServices";
import limitations from "./limitations";
import getMaster from "./getMaster";
import licenseeAction from "./licenseeAction";
import finish from "./finish";
import restrictions from "./restrictions";
import convictions from "./convictions";
import communityService from "./communityService";
import practice from "./practice";
import publications from "./publications";
import onlineHelpFile from "./onlineHelpFile";
import healthPlans from "./healthPlans";
import getMailingKey from "./getMailingKey";
import login from "./login";
import analytics from "./analytics";

const rootReducer = combineReducers({
  navigate,
  navigateCompleted,
  getStyleProps,
  physicianInformation,
  searchSponsor,
  getPracticeAreas,
  getSpecialities,
  GMEData,
  education,
  getCertification,
  searchMedicalSchools,
  physicianCertificates,
  teaching,
  profMembership,
  updatedIndicator,
  insurance,
  hospitalPrivileges,
  searchHospital,
  malpractice,
  translationServices,
  limitations,
  getMaster,
  licenseeAction,
  restrictions,
  finish,
  convictions,
  communityService,
  practice,
  publications,
  onlineHelpFile,
  healthPlans,
  getMailingKey,
  login,
  analytics
});

export default rootReducer;
