import React, { useEffect, useRef, useState } from "react";
import Styled from "./styles";
import { useDispatch, useSelector } from "react-redux";
import ScrollToTop from "../../Common/HOC/ScrollToTop";
import SubPage from "../../Common/SubPage";
import SaveButtons from "../../Common/SaveButtons";
import * as actions from "../../../redux/actions";
import { postFinishData, getFinishData } from "../../../utils/ModuleActions";

import ShowResponse from "../../Common/Messages";
import { useNavigate } from "react-router";
import {
  handleObjectChange,
  handleSubmitRedirect,
  addBtnName,
  handleCancelPopUpBtn,
  handleOkayPopUpBtn,
} from "../../../utils/Utils";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import MissingElements from "./MissingElements/MissingElements";

const Finish = () => {
  const properties = {
    titlePage: "FINISH",
    showInstructions: true,
    instructionType: "second",
  };
  const MAX_CHAR_LENGTH = 10000;

  const [response, setResponse] = useState({
    headerText: "Error message",
    message: "",
    show: false,
  });
  const [charCount, setCharCount] = useState(MAX_CHAR_LENGTH);
  const [finishData, setFinishData] = useState({
    physicianStatement: "",
    isThereAnyPenalty: false,
    date: null,
  });

  const navDataObj = useRef({});

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { data: navigationList } = useSelector(({ navigate }) => navigate);
  const { data: finish } = useSelector(({ finish }) => finish);
  const { data: styleProps } = useSelector(
    ({ getStyleProps }) => getStyleProps
  );

  useEffect(() => {
    dispatch(actions.commonAction(getFinishData));

    return () => {
      dispatch(
        actions.updateWithoutPost({
          type: "CLEAR_FINISH_DATA",
          data: {},
        })
      );
    };
  }, [dispatch]);

  useEffect(() => {
    if (finish?.data?.physicianStatement?.noteText) {
      setFinishData({
        ...finishData,
        physicianStatement: finish?.data?.physicianStatement?.noteText,
      });
      setCharCount(
        MAX_CHAR_LENGTH - finish?.data?.physicianStatement?.noteText?.length
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finish]);

  /**
   * This function is called when the user tries to save, either by clicking on a side menu item
   * or by clicking one of the three save buttons. Calls addBtnName(). Read that function description for more details.
   * Only file not to use sendPayload() since this has additional if-conditions on the response.
   * @param {*} navObject An object from navigate.json or an event object from clicking the save buttons
   * @param {*} location Location object from useLocation(), either from Sidebar or SaveButtons component
   */
  const handleSubmit = (navObject, location) => {
    let payload = {
      physicianStatement: {
        noteText: "",
      },
      signature: {
        signedDate: "",
        signed: "N",
        userTimeZone: "EST"
      },
      finished: ""
    }; // updated data payload 

    payload.physicianStatement.noteText = finishData?.physicianStatement;
    payload.signature.signedDate = finishData?.date;
    payload.signature.signed = finishData?.isThereAnyPenalty;
    let userTimeZone = Intl.DateTimeFormat()?.resolvedOptions()?.timeZone;
    if (userTimeZone !== undefined && userTimeZone !== null) payload.signature.userTimeZone = userTimeZone;

    if(navObject?.target?.name === "save_and_continue") payload.finished = "Y";

    navDataObj.current = addBtnName(navObject, navigationList, location);

    dispatch(
      actions.commonAction(postFinishData, {}, {}, payload, "POST")
    ).then((res) => {
      if (res?.errorMessages) {
        setResponse({
          ...response,
          show: true,
          message: res?.errorMessages.join("\r\n\n"),
        });
      } else if (res?.data?.missingElements?.length > 0) {
        setResponse({
          ...response,
          headerText: "Incomplete Profile",
          dialogBody: "HTMLComp",
          show: true,
          message: (
            <>
              <p> <strong> The following sections of your Profile contain one or more questions which must be answered
                by selecting either the Yes or the No button appearing after the question.  Please complete the questions appearing in the sections listed below:</strong> </p>
              <MissingElements
                key="missing-elements"
                missingEleData={res?.data?.missingElements}
              />
            </>
          ),
        });
      } else if (res?.data?.unvisitedElements?.length > 0) {
        setResponse({
          ...response,
          headerText: "Incomplete Profile",
          dialogBody: "HTMLComp",
          show: true,
          message: (
            <>
              <p> <strong>Please review the pages noted on the Side Navigation menu that are not highlighted in green to ensure that
                the information entered is up to date and accurate: </strong> </p>
              <MissingElements
                key="unvisited-elements"
                missingEleData={res?.data?.unvisitedElements}
              />
            </>
          ),
        });
      }
      else {
        dispatch(
          actions.updateWithoutPost({
            type: "GET_MAILING_KEY",
            data: res?.data?.mailingKey,
          })
        );
        handleSubmitRedirect(navDataObj.current, navigate);
      }
    });
  };

  if (!styleProps) return null; // Needed so app does not crash on first render
  return (
    <Styled styleProps={styleProps}>
      {response?.show && (
        <ShowResponse
          headerText={response?.headerText}
          dialogBody={response?.dialogBody ? response?.dialogBody : "Regular"}
          message={response?.message}
          show={response?.show}
          handleCancel={() =>
            handleCancelPopUpBtn(
              response,
              setResponse,
              navDataObj.current,
              navigate
            )
          }
          handleOkay={() => handleOkayPopUpBtn(response, setResponse)}
        />
      )}
      <SubPage properties={properties} handleSideMenuSave={handleSubmit}>
        <div
          id="finish-form-body"
          className="form-body-container space-between-text"
        >
          <div id="physician-statement-container">
            <div id="physician-statement-header">
              <h4>Physician Statement (Optional)</h4>
              <p>
                You have the right to make a concise statement specific to the
                information that will be available to the public on your NYS
                Physician Profile, please enter it here. Only the statement you
                would like the public to see should be entered in this space.
                Please note that if medical malpractice payments have been made
                on your behalf within the past ten year period you will receive
                a separate mailing which will give you the opportunity to review
                and comment on how this information will be displayed to the
                public. You may want to add or modify your concise statement
                related to medical malpractice at a later time when you have
                completed your review.
              </p>
            </div>
            <Grid
              id="physician-statement-body"
              className="sm-space-between-containers"
              container
              columnSpacing={3}
              rowSpacing={0}
            >
              <Grid item xs={12} sm={12}>
                <TextField
                  label="Physician statement"
                  className="multiline-textbox"
                  fullWidth
                  multiline
                  rows={6}
                  inputProps={{ maxLength: MAX_CHAR_LENGTH }}
                  value={finishData?.physicianStatement}
                  onChange={(e) => {
                    setCharCount(MAX_CHAR_LENGTH - e.target?.value?.length);
                    handleObjectChange(
                      e.target?.value,
                      "physicianStatement",
                      finishData,
                      setFinishData
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} container justifyContent="flex-end">
                <p>{charCount} characters</p>
              </Grid>
            </Grid>
          </div>
          <hr />
          <div id="signature-container">
            <div id="signature-header">
              <h4>Signature</h4>
              <p>
                I am a physician licensed to practice in NY State and this is my
                individual account. I have not shared this account with anyone
                else and am responsible for any activity attributable to the use
                of this account. I have been afforded the opportunity to request
                changes to factual inaccuracies. The information, including
                unchanged pre-printed information and requested changes to
                pre-printed information, is true and accurate to the best of my
                knowledge and belief, and is being submitted under penalty of
                perjury.
              </p>
              <p>
                Any physician who knowingly provides false or misleading
                information to the New York State Health Department is guilty of
                professional misconduct under Section 6530 of the State
                Education Law.
              </p>
            </div>
            <Grid
              id="signature-body"
              className="md-space-between-containers"
              container
              columnSpacing={3}
              rowSpacing={0}
            >
              <Grid item xs={12} sm={12}>
                <FormGroup>
                  <FormControlLabel
                    sx={{ alignItems: "flex-start" }}
                    control={
                      <Checkbox
                        className="checkbox colorGrey4"
                        sx={{
                          marginTop: -1,
                        }}
                        value={finishData?.isThereAnyPenalty}
                        onChange={(e) =>
                          handleObjectChange(
                            "Y",
                            "isThereAnyPenalty",
                            finishData,
                            setFinishData
                          )
                        }
                      />
                    }
                    className="checkbox-label colorBlack"
                    label="Under the penalties of perjury, I declare and affirm that the statements made in this profile including accompanying documents, are true, complete and correct. I understand that except for requested changes to pre-printed information, this information will be utilized to fulfill the requirements of my Physician Profile."
                  />
                </FormGroup>
              </Grid>
              <Grid className="sm-space-between-containers" item xs={12} sm={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    inputFormat="MM-DD-YYYY"
                    name={`sponsor-start-date`}
                    value={finishData?.date}
                    onChange={(date) =>
                      handleObjectChange(
                        dayjs(date).format("YYYY-MM-DD"),
                        "date",
                        finishData,
                        setFinishData
                      )
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Today's Date (MM-DD-YYYY)"
                        InputLabelProps={{ shrink: true }}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "MM-DD-YYYY",
                        }}
                      />
                    )}
                    className="datepicker"
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={6}></Grid>
            </Grid>
          </div>
          <SaveButtons
            saveGoBack={handleSubmit}
            saveExit={handleSubmit}
            saveContinue={handleSubmit}
          />
        </div>
      </SubPage>
    </Styled>
  );
};

export default ScrollToTop(Finish);
