import {React, useRef, useState} from "react";
import Styled from "./styles";
import { useSelector, useDispatch } from "react-redux";
import ScrollToTop from "../../Common/HOC/ScrollToTop";
import SubPage from "../../Common/SubPage";
import SaveButtons from "../../Common/SaveButtons";
import { useNavigate } from "react-router";
import Question from "./Question";
import TextQuestion from "./TextQuestion";

import {
  postQuestionnaireData
} from "../../../utils/ModuleActions";
import {
  addBtnName,
  sendPayload
} from "../../../utils/Utils";

const Questionnaire = () => {
  const properties = {
    titlePage: "QUESTIONNAIRE",
    showInstructions: false,
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [response, setResponse] = useState({
    headerText: "Error message",
    message: "",
    show: false,
  });

  const { data: styleProps } = useSelector(
    ({ getStyleProps }) => getStyleProps
  );

  const questionArray = useRef([]);
  const navDataObj = useRef({});
  const { data: navigationList } = useSelector(({ navigate }) => navigate);

  const handleDataChange = (questionObj) => {
    if (questionObj?.fieldNumber) {
      let index = questionArray.current?.findIndex(
        (data) => data?.fieldNumber === questionObj?.fieldNumber
      );
      if (index === -1) {
        questionArray.current?.push(questionObj);
      } else {
        questionArray.current[index] = questionObj;
      }
    }
  };

  const handleSubmit = (navObject, location) => {
    let payload = {};
    const answers = questionArray?.current;

    payload.adequate_EXPLAIN_INDC = answers[0]?.radioValue;
    payload.inadequate_EXPLAIN_TEXT = answers[0]?.textValue;
    payload.login_DIRECTIONS_INDC = answers[1]?.radioValue;
    payload.unclear_LOGIN_TEXT = answers[1]?.textValue;
    payload.understand_QUESTION_INDC = answers[2]?.radioValue;
    payload.difficult_QUESTION_TEXT = answers[2]?.textValue;
    payload.screen_READABILITY_INDC = answers[3]?.radioValue;
    payload.completion_DIRECTIONS_INDC = answers[4]?.radioValue;
    payload.unclear_ONLINE_TEXT = answers[4]?.textValue;
    payload.mandatory_COMPLETION_TIME = answers[5]?.textValue;
    payload.optional_COMPLETION_TIME = answers[6]?.textValue;
    payload.completion_DIFFICULTY_INDC = answers[7]?.radioValue;
    payload.completion_DIFFICULTY_TEXT = answers[7]?.textValue;
    payload.recommendations_INDC = answers[8]?.radioValue;
    payload.recommendations_TEXT = answers[8]?.textValue

    navDataObj.current = addBtnName(navObject, navigationList, location);

    let params = {
      dispatch: dispatch,
      actionData: postQuestionnaireData,
      requestBody: payload,
      response: response,
      setResponse: setResponse,
      navObject: navDataObj.current,
      navigate: navigate,
    };

    sendPayload(params);
  };
  
  if (!styleProps) return null; // Needed so app does not crash on first render
  return (
    <Styled styleProps={styleProps}>
      <SubPage properties={properties}>
        <div
          id="questionnaire-form-body"
          className="form-body-container space-between-text"
        >
          <div id="questionnaire-container">
            <div id="questionnaire-header">
              <h4>Thank you</h4>
              <p>
                We would appreciate your feedback regarding the clarity and
                organization of the profile questions, as well as your ability
                to complete the profile instrument on-line. In the text areas
                provided, please limit your response to 12 lines or less. After
                filling in your responses please click on the{" "}
                <strong>Submit</strong> button below to complete the
                questionnaire.
              </p>
            </div>
            <div className="questionnaire-body">
              <h4>Questions about the packet you received in the mail:</h4>
              <Question
                fieldNumber={1}
                questionLabel="Did the letter you receive adequately explain the overall purpose of the survey?"
                textboxLabel="If no, please explain"
                onDataChange={handleDataChange}
                questionData={undefined}
              />
              <Question
                fieldNumber={2}
                questionLabel="Were the written directions about logging on and receiving your password clear?"
                textboxLabel="If no, please explain"
                onDataChange={handleDataChange}
                questionData={undefined}
              />
              <h4>Questions about completing the profile on-line:</h4>
              <Question
                fieldNumber={3}
                questionLabel="Did you encounter any difficulty in interpreting or understanding any profile questions?"
                textboxLabel="If yes, please list the questions and describe the problem"
                onDataChange={handleDataChange}
                questionData={undefined}
              />
              <Question
                fieldNumber={4}
                questionLabel="Were the questions on the data entry screens positioned in a way that enabled you to easily read them?"
                textboxLabel=""
                onDataChange={handleDataChange}
                questionData={undefined}
              />
              <Question
                fieldNumber={5}
                questionLabel="Were the directions for completing the profile on-line clear and easy to understand?"
                textboxLabel="If no, please explain"
                onDataChange={handleDataChange}
                questionData={undefined}
              />
              <p className="margin-top-25">
                6. Please indicate how long it took you to complete the profile
                on-line?
              </p>
              <TextQuestion
                fieldNumber="A"
                questionLabel="How long did it take you to complete the mandatory sections?"
                placeholder=""
                onDataChange={handleDataChange}
                questionData={undefined}
              />
              <TextQuestion
                fieldNumber="B"
                questionLabel="How long did it take to complete the optional sections? "
                placeholder=""
                onDataChange={handleDataChange}
                questionData={undefined}
              />
              <Question
                fieldNumber={7}
                questionLabel="Did you encounter any difficulty in completing the survey, such as entering your identification number and password, typing your responses, moving the cursor through the data entry screens, or submitting the survey once completed?"
                textboxLabel="If yes, please describe the difficulties encountered"
                onDataChange={handleDataChange}
                questionData={undefined}
              />
              <Question
                fieldNumber={8}
                questionLabel="Do you have any recommendations for improving the Electronic Profile Survey process?"
                textboxLabel="If yes, please list your recommendations"
                onDataChange={handleDataChange}
                questionData={undefined}
              />
              <SaveButtons
                saveExit={handleSubmit}
                buttonsProp={{
                  saveExit: { show: true, class: "min-width-200" },
                }}
                exitButtonName="SUBMIT"
              />
            </div>
          </div>
        </div>
      </SubPage>
    </Styled>
  );
};

export default ScrollToTop(Questionnaire);
