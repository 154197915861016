import Styled from "./styles";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import logo from "../../../assets/new-york-state-vector-logo-white.svg";
import { Grid } from "@mui/material";

const Footer = () => {
  const { data: styleProps } = useSelector(
    ({ getStyleProps }) => getStyleProps
  );

  if (!styleProps) return null; // Needed so app does not crash on first render
  return (
    <Styled styleProps={styleProps}>
      <Grid container justify-content="flex-start" className="footer">
        <Grid item className="footer-logo">
          <Link to="/">
            <img src={logo} alt="footer logo"></img>
          </Link>
        </Grid>
        <Grid item className="footer-container">
          <p>New York State Department of Health</p>
          <p>PO Box 5007 </p>
          <p>New York, NY 10274-5007</p>
          <p>
            Physician Helpline:{" "}
            <strong className="help-desk-number">1-888-338-6998</strong>.
          </p>
          <p>
            To view the Online Help File,{" "}
            <a href="/help/welcome" aria-label = "click here for help file link" target="_blank" rel="noreferrer">
              <strong>click here</strong>
            </a>
            .
          </p>
        </Grid>
      </Grid>
    </Styled>
  );
};
export default Footer;
