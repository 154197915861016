import styled from "styled-components/macro";

export default styled.div`
  .sub-box-info-container table tr td:first-child {
    width: 17.2222rem;
  }

  .no-divider-item {
    margin-bottom: 40px;
  }
`;
