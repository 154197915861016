import Styled from "./styles";

const FormInstructions = ({ type = "first" }) => {
  return (
    <Styled>
      <div id="form-instructions" className="form-instructions-container">
        {type === "first" && (
          <p>
            When you finish working on or completing this profile section use
            the <strong>Save & Continue</strong> button to move to the next
            section. You can also go back to a previous section using the{" "}
            <strong>Save & Go Back</strong> button. The information you entered
            will be recorded in the database when you click on any of these
            buttons. Use the <strong>Save & Exit</strong> button if you do not
            want to move to the next section but wish to save your work.
          </p>
        )}
        {type === "second" && (
          <>
            <p className="marginBottom8">
              <strong>Instructions: Do Not</strong> use your{" "}
              <strong>Browser Back Button</strong> while you are accessing this
              website as this could cause unpredictable results. The Patient
              Health Information and Quality Improvement Act requires that
              physicians be provided the opportunity to correct factual
              inaccuracies that appear on the profile and be permitted to file a
              concise statement concerning information contained in the profile.
              Accordingly, this Profile section is very important because you
              are being asked to file a concise statement, if you so choose, and
              to attest to the accuracy of the information that you have
              provided as well as to the fact that you have had the opportunity
              to request corrections to factual inaccuracies in pre-printed
              information.
            </p>
            <p>
              You may use the buttons below to{" "}
              <strong>
                Save your work on this page and go to a Previous Page
              </strong>
              , to <strong>Save and Stop for now</strong> if you are not ready
              to complete your profile, or if you are ready, you can use the <strong>Finish</strong> button 
              to complete your profile
              submission.
            </p>
          </>
        )}
      </div>
    </Styled>
  );
};

export default FormInstructions;
