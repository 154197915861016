import React, { useState, useEffect } from "react";
import Styled from "./styles";
import { useSelector } from "react-redux";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";

/**
 *
 * @param {*} value The value of the indicator, either Y or N. Type is a string.
 * @param {*} onValueChange The event handler defined in the parent to be called if the parent needs to use the internal state of this custom component.
 * If this function is being used, do not use onChangeCustom. Defaults to null if not passed in.
 * @param {*} formLabel The label text before the radio button to be displayed
 * @param {*} onChangeCustom The event handler defined in the parent to be called only if setting state or handleObjectChange(). This function only needs to be called on an onChange event.
 * If this function is being used, do not use onValueChange. Defaults to null if not passed in.
 * @param {*} className Any classes that are wanted. This is optional. Might be removed if it is needed more.
 *
 * @returns Custom Radio Component
 */
const CustomRadio = ({
  value,
  onValueChange = null,
  formLabel,
  onChangeCustom = null,
  className = "",
  ariaLabel,
}) => {
  // Just an empty string since all we need is the value of the indicator. Only needed if value is a reference var in the parent
  const [state, setState] = useState("");

  const { data: styleProps } = useSelector(
    ({ getStyleProps }) => getStyleProps
  );

  useEffect(() => {
    if (state && onValueChange) {
      onValueChange(state);
    }
  }, [state, onValueChange]);

  useEffect(() => {
    // Triggered when this changes and if it is defined. Only needed if value is a reference var in the parent
    if (value && onValueChange) {
      setState(value);
    }
  }, [value, onValueChange]);

  return (
    <Styled styleProps={styleProps}>
      <div className={className}>
        <FormControl>
          <FormLabel id={`${ariaLabel}-radio-group-label`}>
            {formLabel}
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby={`${ariaLabel}-radio-group-label`}
            name="custom_radio_group"
            value={state ? state : value}
            onChange={
              onChangeCustom ? onChangeCustom : (e) => setState(e.target.value)
            }
          >
            <FormControlLabel value="Y" control={<Radio />} label="Yes" />
            <FormControlLabel value="N" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
      </div>
    </Styled>
  );
};

export default CustomRadio;
