import CheckCircle from "@mui/icons-material/CheckCircle";
import RadioButtonUnchecked from "@mui/icons-material/RadioButtonUnchecked";
import { Checkbox, List, ListItem } from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import Styled from "./styles";
import * as actions from "../../../redux/actions";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { navigateCompletedActions } from "../../../utils/ModuleActions";

const Sidebar = ({
  handleSideMenuSave,
  isHelpFile,
  toggleMobileMenu = null,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { data: navigationList } = useSelector(({ navigate }) => navigate);

  const { data: navigationCompletedList } = useSelector(
    ({ navigateCompleted }) => navigateCompleted
  );
  const { data: styleProps } = useSelector(
    ({ getStyleProps }) => getStyleProps
  );

  useEffect(() => {
    let token = localStorage.getItem("authToken");
    if (token) {
      dispatch(actions.commonAction(navigateCompletedActions));
    }
  }, [dispatch]);

  const { error: authStatus } = useSelector(({ login }) => login);

  useEffect(() => {
    if (authStatus?.error === 401) {
      localStorage.removeItem("authToken");
      sessionStorage.setItem("attemptCount", "1");
      navigate("/");
    }
  }, [authStatus, navigate]);

  useEffect(() => {
    if (navigationList) {
      let page = navigationList.find(
        (navItem) =>
          navItem?.path === location?.pathname ||
          navItem?.helpPath === location?.pathname
      );
      let docTitle = `New York State Physician Profile - ${page?.description}`;

      if (location?.pathname?.includes("help")) {
        docTitle += `${" "}Help File`;
      }
      document.title = docTitle;
    }
  }, [location?.pathname, navigationList]);

  const handlePageNav = (path) => {
    navigate({ pathname: path });
  };

  /**
   * Validate navigation checkbox is checked or not.
   * @param {*} navCode Navigation code
   * @returns
   */
  const validateNavChecked = (navItem) => {
    let completionStatusObj = navigationCompletedList?.data;
    if (completionStatusObj && navItem?.showCheckBox) {
      return (
        <Checkbox
          edge="end"
          checked={completionStatusObj[navItem?.code]}
          icon={<RadioButtonUnchecked />}
          checkedIcon={<CheckCircle className="check-icon-clr" />}
          inputProps={{ "aria-label": `${navItem?.description} Checkbox` }}
        />
      );
    } else {
      return false;
    }
  };

  
  if (!navigationList) return null; // Needed so app does not crash on first render
  return (
    <Styled styleProps={styleProps} isHelpFile={isHelpFile}>
      <div className="sidebar">
        {!isHelpFile ? <h2>Profile Navigation</h2> : null}
        <List>
          {navigationList.length > 0 &&
            location?.pathname !== "/" &&
            navigationList.map((navItem) => {
              if (navItem?.visibility || !isHelpFile) {
                return (
                  <ListItem
                    tabIndex="0"
                    onKeyDown={e => {
                      if (e.key === "Enter") {
                        // Use the passed in save function if it was passed in.
                        // Otherwise, use handlePageNav()
                        if (handleSideMenuSave) {
                          handleSideMenuSave(navItem, location);
                        } else if (!handleSideMenuSave && !isHelpFile) {
                          handlePageNav(navItem?.path); // Only is true for normal Welcome page, as of now
                        } else {
                          handlePageNav(navItem?.helpPath);
                        }
  
                        // To close the mobile sidebar for the Online Help File
                        if (toggleMobileMenu) {
                          toggleMobileMenu(e);
                        }
                      
                      }
                    }}
                    key={navItem?.code}
                    name={navItem?.description}
                    className={`nav-item ${
                      location?.pathname === navItem?.path ||
                      location?.pathname === navItem?.helpPath
                        ? "active"
                        : ""
                    }`}
                    secondaryAction={
                      !isHelpFile ? validateNavChecked(navItem) : null
                    }
                    onClick={(event) => {
                      // Use the passed in save function if it was passed in.
                      // Otherwise, use handlePageNav()

                      if (handleSideMenuSave) {
                        handleSideMenuSave(navItem, location);
                      } else if (!handleSideMenuSave && !isHelpFile) {
                        handlePageNav(navItem?.path); // Only is true for normal Welcome page, as of now
                      } else {
                        handlePageNav(navItem?.helpPath);
                      }

                      // To close the mobile sidebar for the Online Help File
                      if (toggleMobileMenu) {
                        toggleMobileMenu(event);
                      }
                    }}
                  >
                    <ArrowRightIcon className="arrow-right-icon" />{" "}
                    {navItem?.description}
                  </ListItem>
                );
              } else {
                return false;
              }
            })}
          {location?.pathname === "/" && (
            <ListItem className="nav-item active">Loading Screen</ListItem>
          )}
        </List>
      </div>
    </Styled>
  );
};

export default Sidebar;
