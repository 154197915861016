import { useState, useEffect } from "react";
import Styled from "./styles";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { handleObjectChange } from "../../../../utils/Utils";

export default function Publication({
  fieldNumber,
  onPublicationChage = () => {},
  publicationData = undefined,
}) {
  const MAX_CHAR_LENGTH = 100;

  const [publication, setPublication] = useState({
    publicationDate: null,
    article: "",
    journal: "",
    fieldNumber: fieldNumber,
  });

  const { data: styleProps } = useSelector(
    ({ getStyleProps }) => getStyleProps
  );
  /**
   * This function is used to load existed form data in the form
   */
  const setExistedData = () => {
    if (publicationData?.publicationKey) {
      setPublication({ ...publication, ...publicationData });
    }
  };

  useEffect(() => {
    if (publicationData) setExistedData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicationData]);

  useEffect(() => {
    if (publication) onPublicationChage(publication);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publication]);

  return (
    <Styled styleProps={styleProps}>
      {fieldNumber > 1 && <hr />}
      <div id={`publication-item-${fieldNumber}`}>
        <div id={`publication-item-header-${fieldNumber}`}>
          <h4>Publication Item {fieldNumber}</h4>
        </div>
        <Grid
          id={`publication-item-body-${fieldNumber}`}
          container
          columnSpacing={3}
          rowSpacing={0}
        >
          <Grid item xs={12} sm={12}>
            <TextField
              label="Article Name"
              placeholder="Enter Article Name (100 characters left)"
              className="multiline-textbox"
              InputLabelProps={{ shrink: true }}
              fullWidth
              inputProps={{ maxLength: MAX_CHAR_LENGTH }}
              value={publication?.title ? publication.title : ''}
              onChange={(e) => {
                handleObjectChange(
                  e.target?.value,
                  "title",
                  publication,
                  setPublication
                );
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} container justifyContent="flex-end">
            <p>{publication?.title? MAX_CHAR_LENGTH - publication?.title?.length:MAX_CHAR_LENGTH} characters</p>
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              label="Journal Name"
              placeholder="Enter Journal Name (100 characters left)"
              InputLabelProps={{ shrink: true }}
              className="multiline-textbox"
              fullWidth
              inputProps={{ maxLength: MAX_CHAR_LENGTH }}
              value={publication?.text ? publication.text : ''}
              onChange={(e) => {
                handleObjectChange(
                  e.target?.value,
                  "text",
                  publication,
                  setPublication
                );
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} container justifyContent="flex-end">
            <p>{publication?.text? MAX_CHAR_LENGTH - publication?.text?.length:MAX_CHAR_LENGTH} characters</p>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Date (MM-DD-YYYY)"
                inputFormat="MM-DD-YYYY"
                name={`date-${fieldNumber}`}
                value={
                  publication?.publicationDate
                    ? dayjs(publication?.publicationDate)
                    : null
                }
                onChange={(date) =>
                  handleObjectChange(
                    date ? dayjs(date).format("YYYY-MM-DD") : null,
                    "publicationDate",
                    publication,
                    setPublication
                  )
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: "MM-DD-YYYY",
                    }}
                  />
                )}
                className="datepicker"
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </div>
    </Styled>
  );
}
