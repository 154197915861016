import { format, subYears } from "date-fns";
import * as actions from "../redux/actions";

export function getEffectiveDateFrom(dateFormat = false) {
  let defFormat = "MM/dd/yyyy";
  // if (dateFormat) {
  //   defFormat = dateFormat;
  // }
  return format(subYears(new Date(), 10), defFormat);
}

export function onSubmitHandlerCommon(e) {
  e.preventDefault();
  const formData = new FormData(e.target);
  const formObj = {};
  let formStr = "";
  for (const [key, value] of formData) {
    formObj[key] = value;
    const andOp = formStr === "" ? "" : "&";
    formStr +=
      key === "firstName" || key === "lastName"
        ? ""
        : `${andOp}${key}=${value}`;
  }
  let uriSegments = "";
  if (formObj["firstName"] !== "") {
    uriSegments = `${formObj["lastName"]}/${formObj["firstName"]}`;
  } else {
    uriSegments = `${formObj["lastName"]}`;
  }
  return `${uriSegments}?${formStr}`;
}

export function throttle(func, timeFrame) {
  let lastTime = 0;
  return function (...args) {
    const now = new Date();
    if (now - lastTime >= timeFrame) {
      func(...args);
      lastTime = now;
    }
  };
}

export function convertTagsToJSX(contentWithHtmlTags, index) {
  return (
    <div
      key={index ? index : 1}
      dangerouslySetInnerHTML={{ __html: contentWithHtmlTags }}
    />
  );
}

/**
 * Generic function that handles modifying a single prop of an object. The following parameters are required.
 * @param {*} value The new value that will be added to the object
 * @param {*} objProp The name of the prop that needs to be updated
 * @param {*} object The state variable that is to be modified
 * @param {*} setFunction The setState function for the state varialbe passed into 'object'
 */
export const handleObjectChange = (value, objProp, object, setFunction) => {
  setFunction({ ...object, [objProp]: value });
};

/**
 * Handles the submit for the current page. Checks for validation issues.
 * If there are no validation issues, the submit and redirect happen as expected.
 * If the user clicks "Cancel", they redirect without submitting.
 * If the user clicks "OK", they stay on the current page.
 */
export const sendPayload = ({
  dispatch,
  actionData,
  formData = {},
  headers = {},
  requestBody,
  method = "POST",
  response,
  setResponse,
  navObject,
  navigate,
}) => {
  dispatch(
    actions.commonAction(actionData, formData, headers, requestBody, method)
  ).then((res) => {
    if (res?.errorMessages) {
      setResponse({
        ...response,
        show: true,
        message: res?.errorMessages.join("\r\n\n"),
      });
    } else {
      handleSubmitRedirect(navObject, navigate);
    }
  });
};

/**
 * This function handles the URL redirect depending on how the user saves.
 * It checks if 'navObject' has a prop called 'saveButtonName'. If it does, then one of the 3 Save buttons or help file arrow buttons at the bottom of the screen were clicked.
 * If not, then this was a Side Menu save and the redirect should go to the menu item that was clicked.
 * Note: The help file arrow buttons are included in this function for reusability, but when these are clicked, a save does not occur because it is not needed.
 * @param {*} navObject Object containing everything for page navigation purposes
 * @param {*} navigate The navigate function from 'useNavigate'
 */
export const handleSubmitRedirect = (navObject, navigate) => {
  switch (navObject?.saveButtonName) {
    case "save_and_go_back":
      navigate({ pathname: navObject?.prevPage });
      break;
    case "save_and_exit":
      navigate({ pathname: navObject?.saveLogoffPage });
      break;
    case "save_and_continue":
      navigate({ pathname: navObject?.nextPage });
      break;
    case "help_file_back_btn":
      navigate({ pathname: navObject?.helpPrevPage });
      break;
    case "help_file_forward_btn":
      navigate({ pathname: navObject?.helpNextPage });
      break;
    default:
      navigate({ pathname: navObject?.path });
  }
};

/**
 * Used to add the name of the button pressed to the navObject. The names will be either the save buttons or the arrow buttons on the help file.
 * Checks to see if one of these buttons were pressed by checking if the name property exists. If not, then an item from the sidebar was clicked instead.
 * @param {*} navObject An object from navigate.json, an event object from clicking the save buttons, or just a temporary object with the name of the help file arrow button clicked
 * @returns navObject, which is used to set navObjectData in the caller
 */
export const addBtnName = (navObject, navList, location) => {
  if (navObject?.target?.name) {
    let index = navList.findIndex(
      (page) =>
        page?.path === location?.pathname ||
        page?.helpPath === location?.pathname
    );
    if (index !== -1) {
      navObject = {
        ...navList[index],
        saveButtonName: navObject?.target?.name,
      };
    } //for questionnaire case
    else if (navObject?.target?.name === "save_and_exit") {
      navObject = {
        path: "/logoff",
      };
    }
  }
  return navObject;
};

/**
 * Handle the logic for clicking "Cancel" on the Error Modal
 */
export const handleCancelPopUpBtn = (
  response,
  setResponse,
  navObject,
  navigate
) => {
  setResponse({
    ...response,
    show: false,
    message: "",
  });
  handleSubmitRedirect(navObject, navigate);
};

/**
 * Handle the logic for clicking "Ok" on the Error Modal
 */
export const handleOkayPopUpBtn = (response, setResponse) => {
  setResponse({
    ...response,
    show: false,
    message: "",
  });
};

/**
 * Handle Autocomplete dropdown list for common list options
 * @param {*} props Option list properties
 * @param {*} value Option list unique value
 * @param {*} description Option list description
 * @returns
 */
export const handleDropdownList = (props, value, description) => {
  return (
    <li {...props} key={value}>
      {description}
    </li>
  );
};

/**
 *
 * @param {*} fieldType The type of field that requires a regex pattern
 * @returns A regex pattern requested for the specific field type
 */
export const getRegex = (fieldType) => {
  switch (fieldType) {
    case "phoneNumber":
      return /^([+][0-9])?[-(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4}$/;
    case "email":
      return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    case "zipCode":
      return /^[0-9]{5}$/;
    default:
      return null;
  }
};

/**
 *
 * @param {*} itemEmpty Indicates if the entire item has empty fields or not
 * @param {*} fieldNumber Current field number
 * @param {*} indc The value of the radio button for the corresponding item
 * @param {*} detailSource Indicates if the record was edited on the website or not
 * @returns Boolean
 * Only needed for pages that check for detailSource. If detailSource is not passed in, then the only condition that matters is the indc
 * Made generic enough where it can be used on a page like Teaching, but it does not need to. Technically not needed for Licensee Actions
 * and Failure To Renew Restrictions but it is for now since recent feedback may or may not require the detailSource property
 */
export const disableField = (
  itemEmpty,
  fieldNumber,
  indc,
  detailSource = "PW"
) => {
  if (itemEmpty && fieldNumber === 1) {
    // Only needed for pages with detailSource. In other words, for pages that do not allow the user to modify saved items (added due to NP2-244)
    return indc === "N"; // Only return true if the indicator is No
  } else if (!itemEmpty) {
    return true;
  } else if (itemEmpty) {
    return indc === "N";
  }
};
