import React from "react";
import Styled from "./styles";
import { useSelector } from "react-redux";
import { Button, Grid } from "@mui/material";
import { useLocation } from "react-router-dom";

const SaveButtons = (props) => {
  const {
    saveGoBack = () => {},
    saveExit = () => {},
    saveContinue = () => {},
    buttonsProp = {
      saveGoBack: { show: true, class: "" },
      saveExit: { show: true, class: "" },
      saveContinue: { show: true, class: "" },
    },
    goBackButtonName = "SAVE & GO BACK",
    exitButtonName = "SAVE & EXIT",
    continueButtonName = "SAVE & CONTINUE",
  } = props;
  const { data: styleProps } = useSelector(
    ({ getStyleProps }) => getStyleProps
  );

  const location = useLocation();

  return (
    <Styled styleProps={styleProps}>
      <Grid
        container
        className="btn-container"
        justifyContent="center"
        alignItems="center"
        rowSpacing={2}
      >
        {buttonsProp?.saveGoBack?.show && (
          <Grid item xs={12} sm={4} align="right">
            <Button
              className={`btn-no-fill ${buttonsProp?.saveGoBack?.class}`}
              name="save_and_go_back"
              onClick={(e) => saveGoBack(e, location)}
            >
              {goBackButtonName}
            </Button>
          </Grid>
        )}
        {buttonsProp?.saveExit?.show && (
          <Grid item xs={12} sm={4} align="center">
            <Button
              className={`btn-no-fill ${buttonsProp?.saveExit?.class}`}
              name="save_and_exit"
              onClick={(e) => saveExit(e, location)}
            >
              {exitButtonName}
            </Button>
          </Grid>
        )}
        {buttonsProp?.saveContinue?.show && (
          <Grid item xs={12} sm={4} align="left">
            <Button
              className={`btn-fill ${buttonsProp?.saveContinue?.class}`}
              name="save_and_continue"
              onClick={(e) => saveContinue(e, location)}
            >
              {continueButtonName}
            </Button>
          </Grid>
        )}
      </Grid>
    </Styled>
  );
};

export default SaveButtons;
