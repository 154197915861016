import Styled from "./styles";
import { useSelector } from "react-redux";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";

/**
 * Reusable component for Add and Remove Field buttons on several pages
 * Also customizable to be just an Add button with different text.
 * @param {*} handleAddField Function to add fields
 * @param {*} handleRemoveField Function to remove fields. Defaults to null
 * @param {*} disableRemoveButton State to disable remove button
 * @param {*} disableAddButton State to disable add field button
 * @param {*} addButtonText Display text of the add button. Defaults to "Add field(s)"
 * @param {*} removeButtonText Display text of the remove button. Defaults to "Remove field(s)"
 */
const AddRemoveFieldButtons = ({
  handleAddField,
  handleRemoveField = null,
  disableAddButton,
  disableRemoveButton,
  addButtonText = "Add field(s)",
  removeButtonText = "Remove field(s)",
}) => {
  const { data: styleProps } = useSelector(
    ({ getStyleProps }) => getStyleProps
  );

  if (!styleProps) return null; // Needed so app does not crash on first render
  return (
    <Styled styleProps={styleProps}>
      <div className="add-remove-buttons-container">
        <Button
          className={`${disableAddButton ? "disable-button" : "enable-button"}`}
          variant="outlined"
          disabled={disableAddButton}
          onClick={handleAddField}
        >
          <AddIcon /> {addButtonText}
        </Button>
        {handleRemoveField !== null ? (
          <Button
            className={`${
              disableRemoveButton ? "disable-button" : "enable-button"
            }`}
            disabled={disableRemoveButton}
            onClick={handleRemoveField}
          >
            <CloseIcon /> {removeButtonText}
          </Button>
        ) : null}
      </div>
    </Styled>
  );
};

export default AddRemoveFieldButtons;
