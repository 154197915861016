import React, { useState, useEffect } from "react";
import Styled from "./styles";
import SubBoxInfo from "../../../Common/SubBoxInfo";

const MalpracticeItem = ({
  malpracticeItemData,
  fieldNumber,
  noMalpracticeItemData,
}) => {
  // Default is an empty array since no data from this file needs to be submitted/updated
  const [malpracticeItem, setMalpracticeItem] = useState([]);

  useEffect(() => {
    if (malpracticeItemData) {
      setMalpracticeItem([
        {
          displayName: "Outcome",
          fieldValue: malpracticeItemData?.judgmentDesc,
          isDate: false,
        },
        {
          displayName: "Disposition date",
          fieldValue: malpracticeItemData?.closeDate,
          isDate: true,
        },
        {
          displayName: "Settlement amount",
          fieldValue: malpracticeItemData?.amount,
          isDate: false,
        },
        {
          displayName: "Location of event (facility)",
          fieldValue: malpracticeItemData?.facilityName,
          isDate: false,
        },
        {
          displayName: "Zip code",
          fieldValue: malpracticeItemData?.zipCode,
          isDate: false,
        },
        {
          displayName: "County",
          fieldValue: malpracticeItemData?.countyName,
          isDate: false,
        },
        {
          displayName: "Claim number",
          fieldValue: malpracticeItemData?.claimNumber,
          isDate: false,
        },
        {
          displayName: "Insurance carrier",
          fieldValue: malpracticeItemData?.insuranceCompany,
          isDate: false,
        },
        {
          displayName: "Insurance carrier's phone number",
          fieldValue: malpracticeItemData?.phoneNumber,
          isDate: false,
        },
      ]);
    }
  }, [malpracticeItemData]);

  return (
    <Styled>
      <div id={`malpractice-item-${fieldNumber}`} className="no-divider-item">
        {noMalpracticeItemData ? (
          <div id="malpractice-item-header">
            <h4>{noMalpracticeItemData}</h4>
          </div>
        ) : (
          <>
            <div id={`malpractice-item-header-${fieldNumber}`}>
              <h4>Malpractice Item {fieldNumber}</h4>
            </div>
            <div id={`malpractice-item-body-${fieldNumber}`}>
              <SubBoxInfo data={malpracticeItem} tableName= {`Malpractice Item ${fieldNumber}`} />
            </div>
          </>
        )}
      </div>
    </Styled>
  );
};

export default MalpracticeItem;
