import styled from "styled-components/macro";

export default styled.div`
  .MuiButtonBase-root {
    text-transform: none;
  }

  .add-remove-buttons-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 15px;
    margin-bottom: 10px;
    button {
      font-weight: 700;
      font-size: var(--font_size_16px);
      line-height: var(--line_height_20px);
    }
    button:nth-child(2) {
      margin-left: 15px;
    }
  }

  .empty-add-remove-buttons-container {
    height: 35px;
  }
`;
