import React, { useState, useRef, useEffect } from "react";
import Styled from "./styles";
import * as actions from "../../../redux/actions";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ScrollToTop from "../../Common/HOC/ScrollToTop";
import SubPage from "../../Common/SubPage";
import ShowResponse from "../../Common/Messages";
import {
  getPhysicianInfo,
  putPhysicianInfo,
} from "../../../utils/ModuleActions";
import {
  addBtnName,
  sendPayload,
  handleCancelPopUpBtn,
  handleOkayPopUpBtn,
} from "../../../utils/Utils";
import SaveButtons from "../../Common/SaveButtons";
import Name from "./Name";
import MailingAddress from "./MailingAddress";
import ContactInformation from "./ContactInformation";
import NYSLicense from "./NYSLicense";

const PhysicianInformation = () => {
  /* ********** STATE VARIABLES ********** */
  const [response, setResponse] = useState({
    headerText: "Error message",
    message: "",
    show: false,
  });
  const [isFieldEmptySubmit, setIsFieldEmptySubmit] = useState({
    phoneNumber: false,
    email: false,
  });

  /* ********** REF VARIABLES ********** */
  const mailingAddressData = useRef({});
  const contactInfoData = useRef({});
  const navDataObj = useRef({});

  const properties = {
    titlePage: "PHYSICIAN INFORMATION",
    showInstructions: true,
  };
  const { data: styleProps } = useSelector(
    ({ getStyleProps }) => getStyleProps
  );
  const { data: navigationList } = useSelector(({ navigate }) => navigate);
  const { data: physicianInfo } = useSelector(
    ({ physicianInformation }) => physicianInformation
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(actions.commonAction(getPhysicianInfo));

    return () => {
      dispatch(
        actions.updateWithoutPost({
          type: "CLEAR_PHYSICIAN_INFO_DATA",
          data: {},
        })
      );
    };
  }, [dispatch]);

  /**
   * This function is used to update the Mailing Address data when the user changes a field value
   * @param {*} mailingAddressObject Updated Mailing Address object
   */
  const updateMailingAddressData = (mailingAddressObject) => {
    mailingAddressData.current = mailingAddressObject;
  };

  /**
   * This function is used to update the Contact Information data when the user changes a field value
   * @param {*} contactInfoObject Updated Contact Information object
   */
  const updateContactInfoData = (contactInfoObject) => {
    contactInfoData.current = contactInfoObject;
  };

  /**
   * This function is called when the user tries to save, either by clicking on a side menu item
   * or by clicking one of the three save buttons. Calls addBtnName(). Read that function description for more details.
   * Calls the sendPayload() function afterwards.
   * @param {*} navObject An object from navigate.json or an event object from clicking the save buttons
   * @param {*} location Location object from useLocation(), either from Sidebar or SaveButtons component
   */
  const handleSubmit = (navObject, location) => {
    if (
      contactInfoData.current?.phoneNumber?.length === 0 &&
      contactInfoData.current?.emailaddress?.length === 0
    ) {
      setIsFieldEmptySubmit({ phoneNumber: true, email: true });
      return;
    } else if (contactInfoData.current?.phoneNumber?.length === 0) {
      setIsFieldEmptySubmit({ ...isFieldEmptySubmit, phoneNumber: true });
      return;
    } else if (contactInfoData.current?.emailaddress?.length === 0) {
      setIsFieldEmptySubmit({ ...isFieldEmptySubmit, email: true });
      return;
    }

    let payload = {
      address: {},
      contactInfo: {},
    };
    payload.address = mailingAddressData.current;
    payload.contactInfo = contactInfoData.current;

    navDataObj.current = addBtnName(navObject, navigationList, location);

    let params = {
      dispatch: dispatch,
      actionData: putPhysicianInfo,
      requestBody: payload,
      method: "PUT",
      response: response,
      setResponse: setResponse,
      navObject: navDataObj.current,
      navigate: navigate,
    };

    sendPayload(params);
  };

  /* ********** FUNCTION COMPONENT RETURN ********** */
  if (!styleProps) return null; // Needed so app does not crash on first render
  return (
    <Styled styleProps={styleProps}>
      {response?.show && (
        <ShowResponse
          headerText={response?.headerText}
          message={response?.message}
          show={response?.show}
          handleCancel={() =>
            handleCancelPopUpBtn(
              response,
              setResponse,
              navDataObj.current,
              navigate
            )
          }
          handleOkay={() => handleOkayPopUpBtn(response, setResponse)}
        />
      )}
      <SubPage properties={properties} handleSideMenuSave={handleSubmit}>
        <div
          id="physician-information-form-body"
          className="form-body-container space-between-text"
        >
          <Name
            nameData={physicianInfo?.data?.name}
            lastProfileUpdate={physicianInfo?.data?.lastProfileUpdate}
          />
          <MailingAddress
            mailingAddressData={physicianInfo?.data?.address}
            onMailingAddressChange={updateMailingAddressData}
          />
          <hr />
          <ContactInformation
            contactInfoData={physicianInfo?.data?.contactInfo}
            onContactInfoChange={updateContactInfoData}
            isFieldEmptySubmit={isFieldEmptySubmit}
          />
          <hr />
          <NYSLicense nysLicenseData={physicianInfo?.data?.licenseInfo} />
          <SaveButtons
            saveGoBack={handleSubmit}
            saveExit={handleSubmit}
            saveContinue={handleSubmit}
          />
        </div>
      </SubPage>
    </Styled>
  );
};

export default ScrollToTop(PhysicianInformation);
