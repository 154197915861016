import styled from "styled-components/macro";

export default styled.div`
  display: flex;
  justify-content: ${({ isLogout }) => (isLogout ? "center" : "")};
  padding: 0 83px 0 83px;
  margin-top: ${({ isHelpFile }) => (isHelpFile ? "45px" : "28px")};
  @media only screen and (max-width: ${({ styleProps }) =>
      styleProps?.screenSize?.tablet}) {
    justify-content: center;
    padding: 0 15px 0 15px;
  }

  .sidebar {
    max-width: 13.6111rem;
    min-width: 13.1667rem;
    margin-right: 75px;
    @media only screen and (max-width: ${({ styleProps }) =>
        styleProps?.screenSize?.tablet}) {
      display: none;
      width: 0;
      max-width: 0;
    }
  }

  .subpage {
    max-width: 47.6111rem;
  }

  .subpage-header {
    color: var(--white_1);
    padding: 17px 23px;
    background-color: var(--blue_1);
    margin-top: 28px;
    @media only screen and (max-width: ${({ styleProps }) =>
        styleProps?.screenSize?.tablet}) {
      margin-top: 14px;
    }
    @media only screen and (max-width: ${({ styleProps }) =>
        styleProps?.screenSize?.mobile}) {
      margin-top: 0px;
    }
  }
`;
