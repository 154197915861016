import "./App.css";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as actions from "./redux/actions";
import { useEffect } from "react";
import { navigateActions, getStyleProps } from "./utils/ModuleActions";
import Login from "./Components/Pages/Login";
import Welcome from "./Components/Pages/Welcome";
import Education from "./Components/Pages/Education";
import GME from "./Components/Pages/GME";
import BoardCertification from "./Components/Pages/BoardCertification";
import PhysicianInformation from "./Components/Pages/PhysicianInformation";
import Teaching from "./Components/Pages/Teaching";
import ProfMemberships from "./Components/Pages/ProfMemberships";
import Insurance from "./Components/Pages/Insurance";
import HospitalPrivileges from "./Components/Pages/HospitalPrivileges";
import Malpractice from "./Components/Pages/Malpractice";
import TranslationServices from "./Components/Pages/TranslationServices";
import Limitations from "./Components/Pages/Limitations";
import LicenseeAction from "./Components/Pages/LicenseeAction";
import Finish from "./Components/Pages/Finish";
import Restrictions from "./Components/Pages/Restrictions";
import Convictions from "./Components/Pages/Convictions";
import CommunityService from "./Components/Pages/CommunityService";
import Publications from "./Components/Pages/Publications";
import ThankYou from "./Components/Pages/ThankYou";
import Practice from "./Components/Pages/Practice";
import Questionnaire from "./Components/Pages/Questionnaire";
import OnlineHelpFile from "./Components/Pages/OnlineHelpFile";
import HealthPlans from "./Components/Pages/HealthPlans";
import Logout from "./Components/Pages/Logout";
import Analytics from "./Components/Pages/Analytics";

function App() {
  const dispatch = useDispatch();
 
  sessionStorage.setItem("attemptCount", "1");

  useEffect(() => {
    dispatch(actions.commonAction(getStyleProps));
    dispatch(actions.commonAction(navigateActions));
  }, [dispatch]);

  return (
    <>
    <Analytics />
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/welcome" element={<Welcome />} />
        <Route
          path="/physicianInformation"
          element={<PhysicianInformation />}
        />
        <Route path="/education" element={<Education />} />
        <Route path="/gme" element={<GME />} />
        <Route path="/boardCertifications" element={<BoardCertification />} />
        <Route path="/profMemberships" element={<ProfMemberships />} />
        <Route path="/teaching" element={<Teaching />} />
        <Route path="/hospitalPrivileges" element={<HospitalPrivileges />} />
        <Route path="/insurance" element={<Insurance />} />
        <Route path="/translationServices" element={<TranslationServices />} />
        <Route path="/malpractice" element={<Malpractice />} />
        <Route path="/licenseeAction" element={<LicenseeAction />} />
        <Route path="/limitations" element={<Limitations />} />
        <Route path="/restrictions" element={<Restrictions />} />
        <Route path="/convictions" element={<Convictions />} />
        <Route path="/practice" element={<Practice />} />
        <Route path="/publications" element={<Publications />} />
        <Route path="/communityServices" element={<CommunityService />} />
        <Route path="/healthPlans" element={<HealthPlans />} />
        <Route path="/statement" element={<Finish />} />
        <Route path="/thankyou" element={<ThankYou />} />
        <Route path="/questionnaire" element={<Questionnaire />} />
        <Route path="/logoff" element={<Logout />} />
        <Route path="/help/welcome" element={<OnlineHelpFile />} />
        <Route path="/help/physicianInformation" element={<OnlineHelpFile />} />
        <Route path="/help/education" element={<OnlineHelpFile />} />
        <Route path="/help/gme" element={<OnlineHelpFile />} />
        <Route path="/help/boardCertifications" element={<OnlineHelpFile />} />
        <Route path="/help/teaching" element={<OnlineHelpFile />} />
        <Route path="/help/hospitalPrivileges" element={<OnlineHelpFile />} />
        <Route path="/help/insurance" element={<OnlineHelpFile />} />
        <Route path="/help/translationServices" element={<OnlineHelpFile />} />
        <Route path="/help/malpractice" element={<OnlineHelpFile />} />
        <Route path="/help/licenseeAction" element={<OnlineHelpFile />} />
        <Route path="/help/limitations" element={<OnlineHelpFile />} />
        <Route path="/help/restrictions" element={<OnlineHelpFile />} />
        <Route path="/help/convictions" element={<OnlineHelpFile />} />
        <Route path="/help/practice" element={<OnlineHelpFile />} />
        <Route path="/help/publications" element={<OnlineHelpFile />} />
        <Route path="/help/communityServices" element={<OnlineHelpFile />} />
        <Route path="/help/healthPlans" element={<OnlineHelpFile />} />
        <Route path="/help/statement" element={<OnlineHelpFile />} />
      </Routes>
    </BrowserRouter>
    </>
  );
}

export default App;
