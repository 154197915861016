import React, { useState, useEffect, useCallback } from "react";
import Styled from "./styles";
import { useSelector } from "react-redux";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Grid, Autocomplete, TextField } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import {
  handleObjectChange,
  handleDropdownList,
} from "../../../../utils/Utils";
import CustomPopper from "../../../Common/CustomPopper";

const MedicalSchoolAppointment = ({
  medSchoolApptData = undefined,
  nyMedicalSchools,
  fieldNumber,
  onMedSchoolApptChange,
}) => {
  const [medSchoolAppt, setMedSchoolAppt] = useState({
    schoolName: "",
    schoolCode: "", // Only has value if it is a school in New York state
    beginDate: "",
    endDate: "",
    fieldNumber: fieldNumber,
  });
  const [nyMedSchoolOptions, setNYMedSchoolOptions] = useState([]);
  const [nySchoolName, setNYSchoolName] = useState("");

  const { data: styleProps } = useSelector(
    ({ getStyleProps }) => getStyleProps
  );
  const { data: servedAsFacultyIndc } = useSelector(
    ({ updatedIndicator }) => updatedIndicator
  );

  /**
   * Used to set the initial local state for the current Medical School Appointment being rendered.
   * It uses the data passed in from the parent and adds one more prop called fieldNumber.
   * fieldNumber is needed for the logic of adding, removing, and updating of that data in the field.
   * Note: Initial fields will have all the column attributes from the initial GET request. New fields will have filtered list since that is all that is needed.
   */
  const setDefaultData = useCallback(() => {
    let nySchoolName = "";
    nyMedSchoolOptions.forEach((option) => {
      if (option?.value === medSchoolApptData?.schoolCode) {
        nySchoolName = option?.description;
      }
    });

    setMedSchoolAppt({
      ...medSchoolApptData,
      fieldNumber: fieldNumber,
    });
    setNYSchoolName(nySchoolName);
  }, [medSchoolApptData, nyMedSchoolOptions, fieldNumber]);

  useEffect(() => {
    if (medSchoolAppt) {
      let finalData = medSchoolAppt;
      if (medSchoolAppt?.schoolName && !nySchoolName) {
        finalData["schoolCode"] = "";
      }
      onMedSchoolApptChange(finalData);
    }
  }, [medSchoolAppt, nySchoolName, onMedSchoolApptChange]);

  useEffect(() => {
    if (Array.isArray(nyMedicalSchools)) {
      setNYMedSchoolOptions(nyMedicalSchools);
    }
  }, [nyMedicalSchools]);

  useEffect(() => {
    if (medSchoolApptData) setDefaultData();
  }, [medSchoolApptData, setDefaultData]);

  /**
   * Called when the user selects a new value from the drop down list.
   * Sets the local state variable with the updated data.
   * Contains unique case where the text field value is controlled by a seperate state variable
   * since the object being sent back does not need the school name if it is within the state of New York.
   * @param {*} e Event object from the onCHange event
   * @param {*} newValue The new value the user has selected
   * @param {*} codePropName The name of the property that contains the code value that needs to be updated
   */
  const handleAutocomplete = (e, newValue, codePropName) => {
    if (newValue === null) {
      setMedSchoolAppt({
        ...medSchoolAppt,
        [codePropName]: "",
      });
      setNYSchoolName("");
    } else {
      setMedSchoolAppt({
        ...medSchoolAppt,
        [codePropName]: newValue?.value,
      });
      setNYSchoolName(newValue?.description);
    }
  };

  return (
    <Styled styleProps={styleProps}>
      <div
        id={`medical-school-item-${fieldNumber}`}
        className="no-divider-item"
      >
        <div id={`medical-school-item-header-${fieldNumber}`}>
          <h4>Medical School Item {fieldNumber}</h4>
        </div>
        <Grid
          id={`medical-school-item-body-${fieldNumber}`}
          container
          item
          columnSpacing={3}
          rowSpacing={0}
        >
          <Grid item xs={12} sm={12}>
            <Autocomplete
              id={`ny-medical-school-${fieldNumber}`}
              name={`ny_medical_school_${fieldNumber}`}
              options={nyMedSchoolOptions}
              getOptionLabel={(option) => option?.description || option}
              disabled={servedAsFacultyIndc === "N"}
              value={
                nySchoolName
                  ? {
                      description: nySchoolName,
                      value: medSchoolAppt?.schoolCode,
                    }
                  : null
              }
              onChange={(e, newValue) => {
                handleAutocomplete(e, newValue, "schoolCode");
              }}
              isOptionEqualToValue={(option, currValue) =>
                option?.value === currValue?.value
              }
              renderOption={(props, option) =>
                handleDropdownList(props, option.value, option.description)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Medical school name (if in New York State)"
                  placeholder="Select a school"
                  InputLabelProps={{ shrink: true }}
                />
              )}
              PopperComponent={(props) => <CustomPopper {...props} />}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              id={`medical-school-${fieldNumber}`}
              name={`medical_school_${fieldNumber}`}
              label="Medical school name (if outside New York State or not found above)"
              placeholder="Enter medical school name (if outside New York State or not found above)"
              fullWidth
              InputLabelProps={{ shrink: true }}
              disabled={servedAsFacultyIndc === "N"}
              value={medSchoolAppt?.schoolName}
              onChange={(e) =>
                handleObjectChange(
                  e.target.value,
                  "schoolName",
                  medSchoolAppt,
                  setMedSchoolAppt
                )
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Date Appointment Started (MM-DD-YYYY)"
                inputFormat="MM-DD-YYYY"
                name={`med_school_appt_start_date_${fieldNumber}`}
                disabled={servedAsFacultyIndc === "N"}
                value={
                  medSchoolAppt?.beginDate
                    ? dayjs(medSchoolAppt?.beginDate)
                    : null
                }
                onChange={(date) =>
                  handleObjectChange(
                    date ? dayjs(date).format("YYYY-MM-DD") : null,
                    "beginDate",
                    medSchoolAppt,
                    setMedSchoolAppt
                  )
                }
                inputProps={{
                  placeholder: "MM-DD-YYYY",
                }}
                renderInput={(params) => (
                  <TextField {...params} InputLabelProps={{ shrink: true }} />
                )}
                className="datepicker"
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Date Appointment Ended (MM-DD-YYYY)"
                inputFormat="MM-DD-YYYY"
                name={`med_school_appt_end_date_${fieldNumber}`}
                disabled={servedAsFacultyIndc === "N"}
                value={
                  medSchoolAppt?.endDate ? dayjs(medSchoolAppt?.endDate) : null
                }
                onChange={(date) =>
                  handleObjectChange(
                    date ? dayjs(date).format("YYYY-MM-DD") : null,
                    "endDate",
                    medSchoolAppt,
                    setMedSchoolAppt
                  )
                }
                inputProps={{
                  placeholder: "MM-DD-YYYY",
                }}
                renderInput={(params) => (
                  <TextField {...params} InputLabelProps={{ shrink: true }} />
                )}
                className="datepicker"
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </div>
    </Styled>
  );
};

export default MedicalSchoolAppointment;
