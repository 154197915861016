import styled from "styled-components/macro";

export default styled.div`
  .space-between-text h4 {
    margin-bottom: 0px;
  }

  .space-between-text #subspeciality-header > h4 {
    margin-bottom: 8px;
    // Overriding the above override since everything on this page except this header needs a margin-bottom of 0
  }

  .form-body-container {
    hr {
      margin-top: 12px;
    }
    > hr {
      margin-top: 20px;
    }
  }
`;
