import React, { useState, useEffect, useRef } from "react";
import Styled from "./styles";
import * as actions from "../../../redux/actions";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ScrollToTop from "../../Common/HOC/ScrollToTop";
import SubPage from "../../Common/SubPage";
import AddRemoveFieldButtons from "../../Common/AddRemoveField";
import ShowResponse from "../../Common/Messages";
import CustomRadio from "../../Common/CustomRadio";
import {
  getEducationData,
  postEducationData,
  getPracticeAreas,
} from "../../../utils/ModuleActions";
import MedicalPractice from "./MedicalPractice";
import MedicalSchool from "./MedicalSchool";
import SaveButtons from "../../Common/SaveButtons";
import {
  addBtnName,
  sendPayload,
  handleCancelPopUpBtn,
  handleOkayPopUpBtn,
} from "../../../utils/Utils";

const Education = () => {
  /* ********** STATE VARIABLES ********** */
  const [isValEmpty, setIsValEmpty] = useState(false);
  const [additionalFields, setAdditionalFields] = useState([]);
  const [medicalPracticeCount, setMedicalPracticeCount] = useState(1);
  const [response, setResponse] = useState({
    headerText: "Error message",
    message: "",
    show: false,
  });

  /* ********** REF VARIABLES ********** */
  const medicalPracticeData = useRef([]);
  const hivServicesData = useRef({
    hivservices: "",
    hivreferral: "",
    hivcertified: "",
  });
  const medicalSchoolData = useRef({});
  const navDataObj = useRef({});

  const properties = {
    titlePage: "EDUCATION",
    showInstructions: true,
  };
  const { data: styleProps } = useSelector(
    ({ getStyleProps }) => getStyleProps
  );
  const { data: navigationList } = useSelector(({ navigate }) => navigate);
  const { data: educationInfo } = useSelector(({ education }) => education);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const MIN_FIELDS = 1;
  const MAX_FIELDS = 5;

  useEffect(() => {
    dispatch(actions.commonAction(getPracticeAreas));
    dispatch(actions.commonAction(getEducationData));

    return () => {
      dispatch(
        actions.updateWithoutPost({
          type: "CLEAR_EDUCATION_DATA",
          data: {},
        })
      );
    };
  }, [dispatch]);

  /**
   * Used to set up additionalFields when the Education page first renders.
   * Handles the use cases of data and no data returning from the server.
   */
  const loadInitialAdditionalFields = () => {
    let additionalFieldsClone = [];
    if (educationInfo?.data?.medicalPractice?.length > 0) {
      educationInfo?.data?.medicalPractice.forEach((data, index) => {
        additionalFieldsClone.push(
          <MedicalPractice
            key={data?.practiceKey}
            fieldNumber={index + 1}
            onMedicalPracticeChange={updateMedicalPracticeData}
            medicalPracticeData={data}
            checkValue={checkValue}
          />
        );
      });
      setMedicalPracticeCount(educationInfo?.data?.medicalPractice?.length);
    } else {
      additionalFieldsClone.push(
        <MedicalPractice
          fieldNumber={1}
          key={1}
          onMedicalPracticeChange={updateMedicalPracticeData}
          checkValue={checkValue}
        />
      );
    }
    setAdditionalFields(additionalFieldsClone);
  };

  useEffect(() => {
    // If we call this when there are errorMessages, then all Field of Practice fields reset to empty
    if (educationInfo && !educationInfo?.errorMessages) {
      loadInitialAdditionalFields();
      hivServicesData.current = {
        hivservices: educationInfo?.data?.HIVServices?.hivservices,
        hivreferral: educationInfo?.data?.HIVServices?.hivreferral,
        hivcertified: educationInfo?.data?.HIVServices?.hivcertified,
      };
    }
    // Adding loadInitialAdditionalFields to the dependency array will cause infinite loop and make Add/Remove stop working
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [educationInfo]);

  /**
   * This function is used to update the Medical Practice data when the user clicks on the
   * Add Field button and changes a field value
   * @param {*} medicalPracticeObject Updated Medical Practice object
   */
  const updateMedicalPracticeData = (medicalPracticeObject) => {
    if (medicalPracticeObject?.fieldNumber) {
      let index = medicalPracticeData.current.findIndex(
        (data) => data?.fieldNumber === medicalPracticeObject?.fieldNumber
      );
      if (index === -1) {
        medicalPracticeData.current.push(medicalPracticeObject);
      } else {
        medicalPracticeData.current[index] = medicalPracticeObject;
      }
      setMedicalPracticeCount(medicalPracticeData.current.length);
    }
  };

  /**
   * This function is used to update the hiv services indicator when the user changes a field value
   * @param {*} hivServices Updated hiv services indicator value
   */
  const updateHIVServicesIndc = (hivServices) => {
    hivServicesData.current.hivservices = hivServices;
  };

  /**
   * This function is used to update the hiv referral indicator when the user changes a field value
   * @param {*} hivReferral Updated hiv referral indicator value
   */
  const updateHIVReferralIndc = (hivReferral) => {
    hivServicesData.current.hivreferral = hivReferral;
  };

  /**
   * This function is used to update the hiv certified indicator when the user changes a field value
   * @param {*} hivCertified Updated hiv certified indicator value
   */
  const updateHIVCertifiedIndc = (hivCertified) => {
    hivServicesData.current.hivcertified = hivCertified;
  };

  /**
   * This function is used to update the Medical School data when the user changes a field value
   * @param {*} medicalSchoolObject Updated Medical School object
   */
  const updateMedicalSchoolData = (medicalSchoolObject) => {
    medicalSchoolData.current = medicalSchoolObject;
  };

  /**
   * This function is called when the user clicks on the Remove Field button and updates the Medical Practice data
   */
  const handleRemoveField = () => {
    if (medicalPracticeCount === MIN_FIELDS) return;

    setMedicalPracticeCount(medicalPracticeCount - 1);
    medicalPracticeData.current.pop(); // remove last item
    setAdditionalFields(additionalFields.slice(0, additionalFields.length - 1));
  };

  /**
   * This function is called when the user clicks on the Add Field button and updates the Medical Practice data
   */
  const handleAddField = () => {
    if (medicalPracticeCount === MAX_FIELDS) return;

    setMedicalPracticeCount(medicalPracticeCount + 1);
    setAdditionalFields([
      ...additionalFields,
      <MedicalPractice
        fieldNumber={medicalPracticeCount + 1}
        key={medicalPracticeCount + 1}
        onMedicalPracticeChange={updateMedicalPracticeData}
        checkValue={checkValue}
      />,
    ]);
  };

  /**
   * Helps determine if Add Field button need to be enabled/disabled while a field is being modified.
   * @param {*} newValue New value the user typed into the current field
   */
  const checkValue = (newValue) => {
    let check = newValue === null ? true : false;
    setIsValEmpty(check);
  };

  /**
   * This function is called when the user tries to save, either by clicking on a side menu item
   * or by clicking one of the three save buttons. Calls addBtnName(). Read that function description for more details.
   * Calls the sendPayload() function afterwards.
   * @param {*} navObject An object from navigate.json or an event object from clicking the save buttons
   * @param {*} location Location object from useLocation(), either from Sidebar or SaveButtons component
   */
  const handleSubmit = (navObject, location) => {
    let payload = {
      medicalPractice: [],
      medicalSchoolAttended: {},
      HIVServices: {},
    };
    payload.medicalPractice = medicalPracticeData.current;
    payload.medicalSchoolAttended = medicalSchoolData.current;
    payload.HIVServices = hivServicesData.current;

    navDataObj.current = addBtnName(navObject, navigationList, location);

    let params = {
      dispatch: dispatch,
      actionData: postEducationData,
      requestBody: payload,
      response: response,
      setResponse: setResponse,
      navObject: navDataObj.current,
      navigate: navigate,
    };

    sendPayload(params);
  };

  /* ********** FUNCTION COMPONENT RETURN ********** */
  if (!styleProps) return null; // Needed so app does not crash on first render
  return (
    <Styled styleProps={styleProps}>
      {response?.show && (
        <ShowResponse
          headerText={response?.headerText}
          message={response?.message}
          show={response?.show}
          handleCancel={() =>
            handleCancelPopUpBtn(
              response,
              setResponse,
              navDataObj.current,
              navigate
            )
          }
          handleOkay={() => handleOkayPopUpBtn(response, setResponse)}
        />
      )}
      <SubPage properties={properties} handleSideMenuSave={handleSubmit}>
        <div
          id="education-form-body"
          className="form-body-container space-between-text"
        >
          <div id="medical-practice-container">
            <div id="medical-practice-header">
              <h4>Field(s) of practice</h4>
              <p>
                Click on the down arrow to select from a list of values for the
                field(s) below. Any change to previously entered information
                will be effective immediately.
              </p>
            </div>
            <div
              id="medical-practice-items"
              className="md-space-between-containers"
            >
              {additionalFields}
              <AddRemoveFieldButtons
                handleAddField={handleAddField}
                handleRemoveField={handleRemoveField}
                disableAddButton={
                  medicalPracticeCount === MAX_FIELDS ||
                  (medicalPracticeCount === MIN_FIELDS && isValEmpty)
                }
                disableRemoveButton={medicalPracticeCount === MIN_FIELDS}
              />
            </div>
          </div>
          <hr />
          <div id="hiv-services-container">
            <div id="hiv-services-header">
              <h4>HIV Services (Optional)</h4>
            </div>
            <div id="hiv-services-body">
              <CustomRadio
                value={hivServicesData.current?.hivservices}
                formLabel="Do you provide HIV services and/or care for patients with ARV?"
                onValueChange={updateHIVServicesIndc}
                ariaLabel="provide-hiv-services"
              />
              <CustomRadio
                value={hivServicesData.current?.hivreferral}
                formLabel="Do you accept referrals of new HIV patients?"
                onValueChange={updateHIVReferralIndc}
                ariaLabel="accept-referrals"
              />
              <CustomRadio
                value={hivServicesData.current?.hivcertified}
                formLabel="Are you certified by AAHIVM and/or a member of HIVMA?"
                onValueChange={updateHIVCertifiedIndc}
                ariaLabel="certified-by-aahivm"
              />
            </div>
          </div>
          <hr />
          <MedicalSchool
            medicalSchoolData={educationInfo?.data?.medicalSchoolAttended}
            onMedicalSchoolChange={updateMedicalSchoolData}
          />
          <SaveButtons
            saveGoBack={handleSubmit}
            saveExit={handleSubmit}
            saveContinue={handleSubmit}
          />
        </div>
      </SubPage>
    </Styled>
  );
};

export default ScrollToTop(Education);
