import React, { useState, useEffect } from "react";
import { handleObjectChange } from "../../../../utils/Utils";
import { Radio } from "@mui/material";

const DefaultPrograms = ({ defaultProgramData, onDefaultProgramChange }) => {
  const [defaultProgram, setDefaultProgram] = useState({
    programCode: "",
    participation: "",
    programName: "",
  });

  useEffect(() => {
    if (defaultProgram) {
      onDefaultProgramChange(defaultProgram);
    }
  }, [defaultProgram, onDefaultProgramChange]);

  useEffect(() => {
    // Triggered when this changes and if it is defined
    if (defaultProgramData) {
      setDefaultProgram(defaultProgramData);
    }
  }, [defaultProgramData]);

  return (
    <tr>
      <td>{defaultProgram?.programName}</td>
      <td align="CENTER">
        <Radio
          checked={defaultProgram?.participation === "Y"}
          value="Y"
          onChange={(e) =>
            handleObjectChange(
              e.target.value,
              "participation",
              defaultProgram,
              setDefaultProgram
            )
          }
          name="yes_all_locations"
          inputProps={{ "aria-label": "Yes, at all practice locations" }}
        />
      </td>
      <td align="CENTER">
        <Radio
          checked={defaultProgram?.participation === "S"}
          value="S"
          onChange={(e) =>
            handleObjectChange(
              e.target.value,
              "participation",
              defaultProgram,
              setDefaultProgram
            )
          }
          name="yes_some_locations"
          inputProps={{ "aria-label": "Yes, at some locations" }}
        />
      </td>
      <td align="CENTER">
        <Radio
          checked={defaultProgram?.participation === "N"}
          value="N"
          onChange={(e) =>
            handleObjectChange(
              e.target.value,
              "participation",
              defaultProgram,
              setDefaultProgram
            )
          }
          name="no_locations"
          inputProps={{ "aria-label": "No" }}
        />
      </td>
    </tr>
  );
};

export default DefaultPrograms;
