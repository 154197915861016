import React, { useState } from "react";
import {
  AppBar,
  Button,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Styled from "./styles";
import Sidebar from "../Sidebar";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import "../../../index.css";

const Header = ({ handleSideMenuSave, isHelpFile, isLogout }) => {
  const [open, setOpen] = useState(false);
  const { data: styleProps } = useSelector(
    ({ getStyleProps }) => getStyleProps
  );

  const navigate = useNavigate();

  const logout = () => {
    navigate("/logoff");
  }

  const siteLogo = () => {
    return (
      <a
        href="https://www.ny.gov/"
        target="_blank"
        rel="noreferrer"
        title="New York State Home Page"
        aria-label="logo-link"
      >
        <img
          src="/nygov-logo.png"
          className="logo-link"
          alt="New York State Home Page"
        />
      </a>
    );
  };

  /*
  function that is being called every time the drawer should open or close,
  the keys tab and shift are excluded so the user can focus between
  the elements with the keys
  */
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    //changes the function state according to the value of open
    setOpen(open);
  };

  if (!styleProps) return null; // Needed so app does not crash on first render
  return (
    <Styled styleProps={styleProps} isHelpFile={isHelpFile}>
      <AppBar position="static" className="nav">
        <Toolbar className="nav-toolbar responsive-nav-toolbar">
          <div className="sections">{siteLogo()}</div>
          {!isLogout ? (
            <IconButton
              className="responsive-nav-btn"
              aria-label="open-drawer"
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
          ) : null}

          <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
            <Box className="responsive-side-btn">
              <IconButton
                className="responsive-close-btn"
                aria-label="close-drawer"
                onClick={toggleDrawer(false)}
              >
                <CloseIcon className="close-icon" />
              </IconButton>
              <Sidebar
                handleSideMenuSave={handleSideMenuSave}
                isHelpFile={isHelpFile}
                toggleMobileMenu={toggleDrawer(false)}
              />
              <Button className="logout-button" onClick={logout}>
                Physician log out
              </Button>
              <div className="header-text-container">
                <Typography className="header-text">
                  New York State <strong>Physician Profile</strong>
                  <br />
                  New York State Department of Health
                  <br />
                  PO Box 5007
                  <br />
                  New York, NY 10274-5007 <br />
                  Physician Helpline:{" "}
                  <strong className="help-desk-number">1-888-338-6998</strong>.
                  To view the Online Help File,{" "}
                  <a href="/help/welcome" target="_blank" rel="noreferrer">
                    <strong>click here</strong>
                  </a>
                  .
                </Typography>
              </div>
            </Box>
          </Drawer>
        </Toolbar>
        <Toolbar className="nav-toolbar">
          <div className="sections">
            <a href="#main" className="skip-to-main">
              Skip to main content
            </a>
            {siteLogo()}
          </div>
          <div className="sections">
            <Typography className="header-text">
              New York State <strong>Physician Profile</strong>
              <br />
              New York State Department of Health, PO Box 5007, New York, NY
              10274-5007 <br />
              Physician Helpline:{" "}
              <strong className="help-desk-number">1-888-338-6998</strong>. To
              view the Online Help File,{" "}
              <a href="/help/welcome" aria-label = "click here for help file link" target="_blank" rel="noreferrer">
                <strong>click here</strong>
              </a>
              .
            </Typography>
          </div>

          <Button className="sections" onClick={logout}>
            Physician log out
          </Button>
        </Toolbar>
      </AppBar>
    </Styled>
  );
};
export default Header;
