import { useState, useEffect } from "react";
import Styled from "./styles";
import TextField from "@mui/material/TextField";
import { Grid } from "@mui/material";
import { handleObjectChange } from "../../../../utils/Utils";
import CustomRadio from "../../../Common/CustomRadio";

export default function Question({
  fieldNumber = 0,
  questionLabel = "",
  textboxLabel = "",
  onDataChange = () => {},
  questionData = undefined,
}) {
  const MAX_CHAR_LENGTH = 254;
  const [charCount, setCharCount] = useState(MAX_CHAR_LENGTH);
  const [questionObj, setQuestionObj] = useState({
    radioValue: "Y",
    textValue: "",
    fieldNumber: fieldNumber,
  });

  /**
   * This function is used to load existed form data in the form
   */
  const setQuestionData = () => {
    if (questionData?.questionKey) {
      // setQuestionObj({
      //   ...questionObj,
      //   ...questionData,
      // });
      // setCharCount(MAX_CHAR_LENGTH - questionData?.organization?.length);
    }
  };

  useEffect(() => {
    if (questionData) setQuestionData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionData]);

  useEffect(() => {
    if (questionObj) onDataChange(questionObj);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionObj]);

  return (
    <Styled>
      <div className={`question-item`}>
        <Grid
          className={`question-body`}
          container
          columnSpacing={3}
          rowSpacing={0}
        >
          <Grid item xs={12} sm={12}>
            <CustomRadio
              value={questionObj?.radioValue}
              formLabel={`${fieldNumber}. ${questionLabel}`}
              onChangeCustom={(e) => {
                handleObjectChange(
                  e.target?.value,
                  "radioValue",
                  questionObj,
                  setQuestionObj
                );
              }}
              ariaLabel={`questionnaire-question-${fieldNumber}`}
            />
          </Grid>
          {textboxLabel ?
            <>
              <Grid item xs={12} sm={12}>
                <TextField
                  label={textboxLabel}
                  className="multiline-textbox"
                  fullWidth
                  multiline
                  rows={4}
                  inputProps={{ maxLength: MAX_CHAR_LENGTH }}
                  value={questionObj?.textValue}
                  onChange={(e) => {
                    setCharCount(MAX_CHAR_LENGTH - e.target?.value?.length);
                    handleObjectChange(
                      e.target?.value,
                      "textValue",
                      questionObj,
                      setQuestionObj
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} container justifyContent="flex-end">
                <p>{charCount} characters</p>
              </Grid>
            </>
            : null}
        </Grid>
      </div>
    </Styled>
  );
}
