const callAction =
  (
    actionsData = {},
    formData = {},
    headers = {},
    requestBody = {},
    method = "GET"
  ) =>
  async (dispatch, getState, api) => {
    dispatch(actionRequest(actionsData?.request));
    return api
      .fetchDataCommon(
        actionsData?.path,
        formData,
        headers,
        requestBody,
        method
      )
      .then((data) => {
        dispatch(actionSuccess(actionsData?.success, data));
        return data;
      })
      .catch((err) => {
        if (err?.status === 401)
          dispatch(actionError("UNAUTHORIZED_USER", 401));
        else dispatch(actionError(actionsData?.error, err));
      });
  };
export default callAction;

const actionRequest = (action) => ({
  type: action,
});

const actionSuccess = (action, response) => ({
  type: action,
  data: response,
  lastUpdated: Date.now(),
});

const actionError = (action, error) => ({
  type: action,
  error,
  lastUpdated: Date.now(),
});
