import styled from "styled-components/macro";

export default styled.div`
  .space-between-text h4 {
    margin-bottom: 0px;
  }

  .add-remove-buttons-container {
    margin-top: 20px;
  }

  .form-body-container hr {
    margin-top: 20px;
  }
`;
