import { useEffect, useState } from "react";
import Styled from "./styles";
import ScrollToTop from "../../Common/HOC/ScrollToTop";
import SubPage from "../../Common/SubPage";
import SubBoxInfo from "../../Common/SubBoxInfo";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../redux/actions";
import { getPhysicianInfo } from "../../../utils/ModuleActions";

const Welcome = () => {
  const [profileInformation, setProfileInformation] = useState([]);

  const properties = {
    titlePage: "WELCOME",
  };
  const dispatch = useDispatch();
  const { data: styleProps } = useSelector(
    ({ getStyleProps }) => getStyleProps
  );
  const { data: physicianInfo } = useSelector(
    ({ physicianInformation }) => physicianInformation
  );

  useEffect(() => {
    dispatch(actions.commonAction(getPhysicianInfo));
  }, [dispatch]);

  useEffect(() => {
    if (physicianInfo) {
      const name = physicianInfo?.data?.name;
      setProfileInformation([
        {
          displayName: "Last name",
          fieldValue: name?.lastName,
          isDate: false,
        },
        {
          displayName: "First name",
          fieldValue: name?.firstName,
          isDate: false,
        },
        {
          displayName: "Middle name (or initial)",
          fieldValue: name?.middleName,
          isDate: false,
        },
        {
          displayName: "Suffix",
          fieldValue: name?.suffix,
          isDate: false,
        },
        {
          displayName: "Last update to profile",
          fieldValue: physicianInfo?.data?.lastProfileUpdate,
          isDate: true,
        },
      ]);
    }
  }, [physicianInfo]);

  if (physicianInfo?.data === {}) return null; // To prevent empty data from loading on startup
  return (
    <Styled styleProps={styleProps}>
      <SubPage properties={properties}>
        <SubBoxInfo data={profileInformation} tableName="Welcome Page Physician Information"/>
        <div id="instruction-guide" className="space-between-text">
          <h4>Getting started</h4>
          <p>
          <strong>
          To begin this process please use the Side Navigation menu on the left side of the screen to access the profile pages.
          </strong>
          </p>
          <p>
            Complete this survey by filling in the fields as directed. Note that the survey is
            not complete until the <strong>Finish</strong> button is clicked on
            the Finish page and all required sections have been reviewed and
            corrected or added to as needed.
          </p>
          <p>
            If any prefilled information is incomplete or incorrect, write in
            the correct, complete information. You'll notice that the prefilled
            information remains displayed above. Because this information comes
            from other sources, we will not display disputed information on the
            public website until we receive a revision from the source or
            otherwise resolve the difference.
          </p>
          <p>
            As indicated on the <strong>Save</strong> buttons, changes you make
            will be recorded in the database automatically as you move to the
            next section or back to a previous section. You can also use the{" "}
            <strong>Save & Exit</strong> for now button in case you wish to
            leave the Site before completing your Profile. If you do not finish
            the survey, you may pick up where you left off at a later time. You
            can use the <strong>Side Navigation menu</strong> along the left side
            of the screen to get you back to the section where you stopped, to
            go directly to a particular section or to browse through the entire
            survey.
          </p>
          <p>
            Please do not use your Browser's Back button while accessing the
            Physician Profile Website as this can cause unpredictable results on
            a website that allows you to update information. Instead use the
            Navigation buttons mentioned in the preceding paragraph to complete
            all tasks in filling out your Profile.
          </p>
          <p>If you have questions:</p>
          <p>
            <strong>
              Call the Physician Helpline{" "}
              <span className="help-desk-number">1-888-338-6998</span>
            </strong>
          </p>
        </div>
      </SubPage>
    </Styled>
  );
};
export default ScrollToTop(Welcome);
