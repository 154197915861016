//import { generateAuthToken } from "../../utils/Utils";
import * as client from "../axios";
import endpoint from "../endpoint";

async function fetchDataCommon(
  path,
  formData = {},
  headers = {},
  requestBody = {},
  method = "GET"
) {
  let token = localStorage.getItem("authToken");

  const options = {
    params: {
      ...formData,
    },
    headers: {
      Authorization: token ? "Bearer " + token : undefined,
      "content-type": "application/json",
      ...headers,
    },
  };
  let response;
  if (method === "GET") {
    response = await client.get(`${path}`, options);
  } else if (method === "PUT") {
    response = await client.put(`${path}`, requestBody, options);
  } else {
    response = await client.post(`${path}`, requestBody, options);
  }
  if (response?.headers?.token) {
    localStorage.setItem("authToken", response?.headers?.token);
  }
  return response.data;
}

export default endpoint(fetchDataCommon, "fetchDataCommon");
