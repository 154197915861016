import styled from "styled-components/macro";

export default styled.div`
  #main {
    ul {
      padding-left: 50px;
    }
    div {
      margin-bottom: 20px;
    }
    h4 {
      color: var(--black_1);
    }
    img {
      max-width: 100%;
    }
  }

  #page-nav-arrows {
    display: flex;
    align-items: center;
    column-gap: 30px;
  }
`;
