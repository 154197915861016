import React, { useState, useEffect, useRef } from "react";
import Styled from "./styles";
import * as actions from "../../../redux/actions";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ScrollToTop from "../../Common/HOC/ScrollToTop";
import SubPage from "../../Common/SubPage";
import AddRemoveFieldButtons from "../../Common/AddRemoveField";
import ShowResponse from "../../Common/Messages";
import {
  getInsuranceData,
  postInsuranceData,
} from "../../../utils/ModuleActions";
import DefaultProgram from "./DefaultProgram";
import AdditionalProgram from "./AdditionalProgram";
import SaveButtons from "../../Common/SaveButtons";
import {
  addBtnName,
  sendPayload,
  handleCancelPopUpBtn,
  handleOkayPopUpBtn,
} from "../../../utils/Utils";

const Insurance = () => {
  /* ********** STATE VARIABLES ********** */
  const [additionalFields, setAdditionalFields] = useState([]);
  const [defaultFields, setDefaultFields] = useState([]);
  const [additionalProgramsCount, setAdditionalProgramsCount] = useState(1);
  const [response, setResponse] = useState({
    headerText: "Error message",
    message: "",
    show: false,
  });

  /* ********** REF VARIABLES ********** */
  const initialDefaultPrograms = useRef([]);
  const initialAdditionalPrograms = useRef([]);
  const defaultPrograms = useRef([]);
  const additionalPrograms = useRef([]);
  const navDataObj = useRef({});

  const properties = {
    titlePage: "MEDICARE, ETC.",
    showInstructions: true,
  };
  const { data: styleProps } = useSelector(
    ({ getStyleProps }) => getStyleProps
  );
  const { data: navigationList } = useSelector(({ navigate }) => navigate);
  const { data: insuranceInfo } = useSelector(({ insurance }) => insurance);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const MIN_FIELDS = 1;
  const MAX_FIELDS = 20;
  const DEFAULT_PROGRAM_CODES = ["MA", "MC", "CHP", "TRI"];

  useEffect(() => {
    dispatch(actions.commonAction(getInsuranceData));

    return () => {
      dispatch(
        actions.updateWithoutPost({
          type: "CLEAR_INSURANCE_DATA",
          data: {},
        })
      );
    };
  }, [dispatch]);

  /**
   * Used to set up additionalFields when the Insurance page first renders.
   * Handles the use cases of data and no data returning from the server.
   */
  const loadInitialAdditionalFields = () => {
    let additionalFieldsClone = [];
    initialAdditionalPrograms.current = insuranceInfo?.data?.insurance.filter(
      (program) => program?.programCode === "OT"
    );
    if (initialAdditionalPrograms.current.length > 0) {
      initialAdditionalPrograms.current.forEach((data, index) => {
        additionalFieldsClone.push(
          <AdditionalProgram
            key={data?.programKey}
            fieldNumber={index + 1}
            onAdditionalProgramChange={updateAdditionalProgramsData}
            additionalProgramData={data}
          />
        );
      });
      setAdditionalProgramsCount(initialAdditionalPrograms.current.length);
    } else {
      additionalFieldsClone.push(
        <AdditionalProgram
          fieldNumber={1}
          key={1}
          onAdditionalProgramChange={updateAdditionalProgramsData}
        />
      );
    }
    setAdditionalFields(additionalFieldsClone);
  };

  /**
   * Sets the programName property for the default programs since the reponse leaves the programName property empty
   * @param {*} code The current default program code
   * @returns A string of the current default program
   */
  const setProgramName = (code) => {
    switch (code) {
      case "MA":
        return "Medicaid";
      case "MC":
        return "Medicare";
      case "CHP":
        return "Child Health Plus";
      case "TRI":
        return "Tricare";
      default:
        return "";
    }
  };

  /**
   * Used to seperate the default/static fields that are displayed on the page into it's own array.
   * The data coming in for these radio buttons is an array of objects which includes the additional fields too.
   * Other radio buttons are in one object which is simpler and does not require this function. Since this
   * page is different, the seperation is needed.
   */
  const loadInitialDefaultFields = () => {
    initialDefaultPrograms.current = insuranceInfo?.data?.insurance.filter(
      (program) => {
        let index = DEFAULT_PROGRAM_CODES.findIndex(
          (code) => code === program?.programCode
        );
        if (index === -1) {
          return false;
        }
        program.programName = setProgramName(program?.programCode);
        return true;
      }
    );

    let defaultProgramsClone = [];
    if (initialDefaultPrograms.current.length > 0) {
      initialDefaultPrograms.current.forEach((data, index) => {
        defaultProgramsClone.push(
          <DefaultProgram
            key={data?.programKey}
            onDefaultProgramChange={updateDefaultProgramsData}
            defaultProgramData={data}
          />
        );
      });
    }
    setDefaultFields(defaultProgramsClone);
  };

  useEffect(() => {
    // If we call this when there are errorMessages, then all Additional Programs fields reset to empty
    if (insuranceInfo && !insuranceInfo?.errorMessages) {
      loadInitialAdditionalFields();
      loadInitialDefaultFields();
    }
    // Adding loadInitialAdditionalFields to the dependency array will cause infinite loop and make Add/Remove stop working
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insuranceInfo]);

  /**
   * This function is used to update the Additional Programs data when the user clicks on
   * the Add Field button and changes a field value
   * @param {*} additionalProgramObject Updated Additional Programs object
   */
  const updateAdditionalProgramsData = (additionalProgramObject) => {
    if (additionalProgramObject?.fieldNumber) {
      let index = additionalPrograms.current.findIndex(
        (data) => data?.fieldNumber === additionalProgramObject?.fieldNumber
      );
      if (index === -1) {
        additionalPrograms.current.push(additionalProgramObject);
      } else {
        additionalPrograms.current[index] = additionalProgramObject;
      }
      setAdditionalProgramsCount(additionalPrograms.current.length);
    }
  };

  /**
   * This function is used to update the Default Programs data when the user changes a field value.
   * Comparing against programCode instead of fieldNumber since this is not an additional field section
   * and does not need to keep  track of the fieldNumber. These fields always display.
   * @param {*} defaultProgramObject Updated values for the default programs displayed, like Medicaid, Medicare, etc.
   */
  const updateDefaultProgramsData = (defaultProgramObject) => {
    if (defaultProgramObject?.programCode) {
      let index = defaultPrograms.current.findIndex(
        (data) => data?.programCode === defaultProgramObject?.programCode
      );
      if (index === -1) {
        defaultPrograms.current.push(defaultProgramObject);
      } else {
        defaultPrograms.current[index] = defaultProgramObject;
      }
    }
  };

  /**
   * This function is called when the user clicks on the Remove Field button and updates the Additional Programs data
   */
  const handleRemoveField = () => {
    if (additionalProgramsCount === MIN_FIELDS) return;

    setAdditionalProgramsCount(additionalProgramsCount - 1);
    additionalPrograms.current.pop(); // remove last item
    setAdditionalFields(additionalFields.slice(0, additionalFields.length - 1));
  };

  /**
   * This function is called when the user clicks on the Add Field button and updates the Additional Programs data
   */
  const handleAddField = () => {
    if (additionalProgramsCount === MAX_FIELDS) return;

    setAdditionalProgramsCount(additionalProgramsCount + 1);
    setAdditionalFields([
      ...additionalFields,
      <AdditionalProgram
        fieldNumber={additionalProgramsCount + 1}
        key={additionalProgramsCount + 1}
        onAdditionalProgramChange={updateAdditionalProgramsData}
      />,
    ]);
  };

  /**
   * This function is called when the user tries to save, either by clicking on a side menu item
   * or by clicking one of the three save buttons. Calls addBtnName(). Read that function description for more details.
   * Calls the sendPayload() function afterwards.
   * @param {*} navObject An object from navigate.json or an event object from clicking the save buttons
   * @param {*} location Location object from useLocation(), either from Sidebar or SaveButtons component
   */
  const handleSubmit = (navObject, location) => {
    let payload = {
      insurance: [],
    };
    payload.insurance = [
      ...defaultPrograms.current,
      ...additionalPrograms.current,
    ];

    navDataObj.current = addBtnName(navObject, navigationList, location);

    let params = {
      dispatch: dispatch,
      actionData: postInsuranceData,
      requestBody: payload,
      response: response,
      setResponse: setResponse,
      navObject: navDataObj.current,
      navigate: navigate,
    };

    sendPayload(params);
  };

  /* ********** FUNCTION COMPONENT RETURN ********** */
  if (!styleProps) return null; // Needed so app does not crash on first render
  return (
    <Styled styleProps={styleProps}>
      {response?.show && (
        <ShowResponse
          headerText={response?.headerText}
          message={response?.message}
          show={response?.show}
          handleCancel={() =>
            handleCancelPopUpBtn(
              response,
              setResponse,
              navDataObj.current,
              navigate
            )
          }
          handleOkay={() => handleOkayPopUpBtn(response, setResponse)}
        />
      )}
      <SubPage properties={properties} handleSideMenuSave={handleSubmit}>
        <div
          id="insurance-form-body"
          className="form-body-container space-between-text"
        >
          <div id="insurance-programs-container">
            <div id="insurance-programs-header">
              <h4>
                Participation in State or Federal Health Insurance Programs
              </h4>
              <p>
                Indicate your application in these programs (including through
                managed care programs). Any change to previously entered
                information will be effective immediately.
              </p>
              <p>
                Click the <strong>Add</strong> button below if you need to enter
                additional Programs.
              </p>
            </div>
            <div
              id="insurance-progams-body"
              className="md-space-between-containers"
            >
              <table aria-label="Insurance Programs">
                <tbody>
                  <tr>
                    <th scope="col">&nbsp;</th>
                    <th scope="col">Yes, at all practice locations</th>
                    <th scope="col">Yes, at some locations</th>
                    <th scope="col">No</th>
                  </tr>
                  {defaultFields}
                  <tr>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td colSpan={4} className="td-padding">
                      List other Federal or State Programs below:
                    </td>
                  </tr>
                  {additionalFields}
                </tbody>
              </table>
              <AddRemoveFieldButtons
                handleAddField={handleAddField}
                handleRemoveField={handleRemoveField}
                disableAddButton={additionalProgramsCount === MAX_FIELDS}
                disableRemoveButton={additionalProgramsCount === MIN_FIELDS}
              />
            </div>
          </div>

          <SaveButtons
            saveGoBack={handleSubmit}
            saveExit={handleSubmit}
            saveContinue={handleSubmit}
          />
        </div>
      </SubPage>
    </Styled>
  );
};

export default ScrollToTop(Insurance);
