import styled from "styled-components/macro";

export default styled.div`
  #hospital-privileges-form-body p {
    margin-top: 15px;
  }
  #hospital-privileges-header h4 {
    margin-bottom: 0;
  }
`;
