import React, { useState, useEffect, useCallback } from "react";
import { Radio, TextField } from "@mui/material";
import { handleObjectChange } from "../../../../utils/Utils";

const AdditionalPrograms = ({
  additionalProgramData = undefined,
  fieldNumber,
  onAdditionalProgramChange,
}) => {
  const [additionalProgram, setAdditionalProgram] = useState({
    programCode: "OT",
    participation: "",
    programName: "",
    fieldNumber: fieldNumber,
  });

  /**
   * Used to set the initial local state for the current Additional Program being rendered.
   * It uses the data passed in from the parent and adds one more prop called fieldNumber.
   * fieldNumber is needed for the logic of adding, removing, and updating of that data in the field.
   * Note: Initial fields will have all the column attributes from the initial GET request. New fields will have filtered list since that is all that is needed.
   */
  const setDefaultData = useCallback(() => {
    setAdditionalProgram({
      ...additionalProgramData,
      fieldNumber: fieldNumber,
    });
  }, [additionalProgramData, fieldNumber]);

  useEffect(() => {
    if (additionalProgram) {
      onAdditionalProgramChange(additionalProgram);
    }
  }, [additionalProgram, onAdditionalProgramChange]);

  useEffect(() => {
    if (additionalProgramData) setDefaultData();
  }, [additionalProgramData, setDefaultData]);

  return (
    <tr>
      <td>
        <TextField
          id={`additional-program-${fieldNumber}`}
          name={`additional_program_${fieldNumber}`}
          size="small"
          fullWidth
          inputProps={{
            maxLength: 40,
            "aria-label": `Additional Program ${fieldNumber}`,
          }}
          value={additionalProgram?.programName}
          onChange={(e) =>
            handleObjectChange(
              e.target.value,
              "programName",
              additionalProgram,
              setAdditionalProgram
            )
          }
        />
      </td>
      <td align="CENTER">
        <Radio
          checked={additionalProgram?.participation === "Y"}
          id={`yes-all-locations-${fieldNumber}`}
          name={`yes_all_locations_${fieldNumber}`}
          value="Y"
          onChange={(e) =>
            handleObjectChange(
              e.target.value,
              "participation",
              additionalProgram,
              setAdditionalProgram
            )
          }
          inputProps={{ "aria-label": "Y" }}
        />
      </td>
      <td align="CENTER">
        <Radio
          checked={additionalProgram?.participation === "S"}
          id={`yes-some-locations-${fieldNumber}`}
          name={`yes_some_locations_${fieldNumber}`}
          value="S"
          onChange={(e) =>
            handleObjectChange(
              e.target.value,
              "participation",
              additionalProgram,
              setAdditionalProgram
            )
          }
          inputProps={{ "aria-label": "S" }}
        />
      </td>
      <td align="CENTER">
        <Radio
          checked={additionalProgram?.participation === "N"}
          id={`no-locations-${fieldNumber}`}
          name={`no_locations_${fieldNumber}`}
          value="N"
          onChange={(e) =>
            handleObjectChange(
              e.target.value,
              "participation",
              additionalProgram,
              setAdditionalProgram
            )
          }
          inputProps={{ "aria-label": "N" }}
        />
      </td>
    </tr>
  );
};

export default AdditionalPrograms;
