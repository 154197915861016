import React, { useState, useRef, useEffect } from "react";
import Styled from "./styles";
import * as actions from "../../../redux/actions";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ScrollToTop from "../../Common/HOC/ScrollToTop";
import SubPage from "../../Common/SubPage";
import ShowResponse from "../../Common/Messages";
import CustomRadio from "../../Common/CustomRadio";
import {
  getMalpracticeData,
  postMalpracticeData,
} from "../../../utils/ModuleActions";
import {
  addBtnName,
  sendPayload,
  handleCancelPopUpBtn,
  handleOkayPopUpBtn,
} from "../../../utils/Utils";
import MalpracticeItem from "./MalpracticeItem";
import SaveButtons from "../../Common/SaveButtons";

const Malpractice = () => {
  /* ********** STATE VARIABLES ********** */
  const [malpracticeItems, setMalpracticeItems] = useState([]);
  const [response, setResponse] = useState({
    headerText: "Error message",
    message: "",
    show: false,
  });

  /* ********** REF VARIABLES ********** */
  const malpracticePaymentIndc = useRef(""); // Not a state var because we do not need to refresh the entire page when this changes like other indicators
  const navDataObj = useRef({});

  const properties = {
    titlePage: "MALPRACTICE",
    showInstructions: true,
  };
  const { data: styleProps } = useSelector(
    ({ getStyleProps }) => getStyleProps
  );
  const { data: malpracticeInfo } = useSelector(
    ({ malpractice }) => malpractice
  );
  const { data: navigationList } = useSelector(({ navigate }) => navigate);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(actions.commonAction(getMalpracticeData));

    return () => {
      dispatch(
        actions.updateWithoutPost({
          type: "CLEAR_MALPRACTICE_DATA",
          data: {},
        })
      );
    };
  }, [dispatch]);

  /**
   * This function is used to update the malpractice payment indicator when the user changes a field value
   * @param {*} malPaymentIndc Updated malpractice payment indicator value
   */
  const updateMalpracticePaymentIndc = (malPaymentIndc) => {
    malpracticePaymentIndc.current = malPaymentIndc;
  };

  /**
   * Used to set up Malpractice Items when the Malpractice page first renders.
   * Handles the use cases of data and no data returning from the server.
   */
  const loadMalpracticeItems = () => {
    let malpracticeItemsClone = [];
    if (malpracticeInfo?.data?.malpractices?.length > 0) {
      malpracticeInfo?.data?.malpractices.forEach((data, index) => {
        malpracticeItemsClone.push(
          <MalpracticeItem
            key={data?.malpracticeKey}
            fieldNumber={index + 1}
            malpracticeItemData={data}
          />
        );
      });
    } else {
      malpracticeItemsClone.push(
        <MalpracticeItem
          key={1}
          noMalpracticeItemData="No record of Malpractice found"
        />
      );
    }
    setMalpracticeItems(malpracticeItemsClone);
  };

  useEffect(() => {
    // If we call this when there are errorMessages, then all Malpractice fields reset to empty
    if (malpracticeInfo && !malpracticeInfo?.errorMessages) {
      loadMalpracticeItems();
      malpracticePaymentIndc.current = malpracticeInfo?.data?.phyResp;
    }
    // Adding loadMalpracticeItems to the dependency array will cause infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [malpracticeInfo]);

  /**
   * This function is called when the user tries to save, either by clicking on a side menu item
   * or by clicking one of the three save buttons. Calls addBtnName(). Read that function description for more details.
   * Calls the sendPayload() function afterwards.
   * @param {*} navObject An object from navigate.json or an event object from clicking the save buttons
   * @param {*} location Location object from useLocation(), either from Sidebar or SaveButtons component
   */
  const handleSubmit = (navObject, location) => {
    let payload = {
      phyResp: "",
    };

    payload.phyResp = malpracticePaymentIndc.current;

    navDataObj.current = addBtnName(navObject, navigationList, location);

    let params = {
      dispatch: dispatch,
      actionData: postMalpracticeData,
      requestBody: payload,
      response: response,
      setResponse: setResponse,
      navObject: navDataObj.current,
      navigate: navigate,
    };

    sendPayload(params);
  };

  /* ********** FUNCTION COMPONENT RETURN ********** */
  if (!styleProps) return null; // Needed so app does not crash on first render
  return (
    <Styled styleProps={styleProps}>
      {response?.show && (
        <ShowResponse
          headerText={response?.headerText}
          message={response?.message}
          show={response?.show}
          handleCancel={() =>
            handleCancelPopUpBtn(
              response,
              setResponse,
              navDataObj.current,
              navigate
            )
          }
          handleOkay={() => handleOkayPopUpBtn(response, setResponse)}
        />
      )}
      <SubPage properties={properties} handleSideMenuSave={handleSubmit}>
        <div
          id="malpractice-form-body"
          className="form-body-container space-between-text"
        >
          <div id="malpractice-header">
            <h4>Malpractice</h4>
          </div>
          <div id="malpractice-body" className="md-space-between-containers">
            <CustomRadio
              value={malpracticePaymentIndc.current}
              formLabel="Have there been any malpractice award payments made on your behalf
                  during the past 10 years?"
              onValueChange={updateMalpracticePaymentIndc}
              ariaLabel="malpractice-award-payments"
            />
            <p>
              If <strong>Yes</strong>, please call{" "}
              <strong className="help-desk-number">1-888-338-6998</strong>
            </p>
            <p>
              If <strong>No</strong>, check "No", Save and Continue to the next
              page
            </p>
            <p>
              Listed, please find the medical malpractice court judgments,
              arbitration awards and/or settlements made by malpractice
              insurance companies on your behalf within the past 10 years in
              which a payment was awarded to a complaining party. Listed you
              will find:
            </p>
            <ul>
              <li>
                Indication if the award was the result of a judgment,
                arbitration or settlement,
              </li>
              <li>The disposition date,</li>
              <li>
                The insurer's payment in settlement of the malpractice action or
                claim,
              </li>
              <li>The location where the malpractice event took place,</li>
              <li>
                The zip code or county of the location where the event took
                place (for example, hospital name),
              </li>
              <li>The claim number,</li>
              <li>The name of the Medical Malpractice carrier and</li>
              <li>The carrier's phone number.</li>
            </ul>
            <p>
              Physicians or groups who are self-insured, or who were
              self-insured for anytime within the past 10 years, must list any
              self-insured malpractice payments made within the past 10 years.
            </p>
            <p>
              The detail involving the specific dollar amount of the insurer's
              payment in settlement of the malpractice action or claim, the
              claim number, and the name of the carrier will not be made public.
              We are, however, providing this information to you so that you may
              review the information for accuracy. If the facts as you see them
              here are not accurate, please contact Physician Help Desk at{" "}
              <strong className="help-desk-number">1-888-338-6998</strong> and
              the insurance carrier at the phone number provided. If the list is
              incomplete, you must provide the above detail for any missing
              malpractice event within the past 10 years by contacting the
              Physician Help Desk at{" "}
              <strong className="help-desk-number">1-888-338-6998</strong>.
            </p>
            <p>
              <strong>NOTE</strong>: While all settlements within the past 10
              years must be listed, in the case where a physician has two or
              fewer settlements during that time period, the Commissioner of
              Health must determine if a settlement is relevant to patient
              decision making for purposes of public dissemination.
            </p>
            <hr />
            <div id="malpractice-items">{malpracticeItems}</div>
          </div>

          <SaveButtons
            saveGoBack={handleSubmit}
            saveExit={handleSubmit}
            saveContinue={handleSubmit}
          />
        </div>
      </SubPage>
    </Styled>
  );
};

export default ScrollToTop(Malpractice);
