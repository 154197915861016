import React, { useEffect, useRef, useState } from "react";
import Styled from "./styles";
import { useDispatch, useSelector } from "react-redux";
import ScrollToTop from "../../Common/HOC/ScrollToTop";
import SubPage from "../../Common/SubPage";
import SaveButtons from "../../Common/SaveButtons";
import * as actions from "../../../redux/actions";
import {
  getTranslationServicesData,
  postTranslationServicesData,
} from "../../../utils/ModuleActions";
import Services from "./Services";
import ShowResponse from "../../Common/Messages";
import { useNavigate } from "react-router";
import {
  addBtnName,
  sendPayload,
  handleCancelPopUpBtn,
  handleOkayPopUpBtn,
} from "../../../utils/Utils";

const TranslationServices = () => {
  const properties = {
    titlePage: "TRANSLATION OR INTERPRETATION SERVICES",
    showInstructions: true,
  };

  const [response, setResponse] = useState({
    headerText: "Error message",
    message: "",
    show: false,
  });

  const servicesData = useRef([]);
  const navDataObj = useRef({});

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { data: navigationList } = useSelector(({ navigate }) => navigate);
  const { data: styleProps } = useSelector(
    ({ getStyleProps }) => getStyleProps
  );

  useEffect(() => {
    dispatch(actions.commonAction(getTranslationServicesData));

    return () => {
      dispatch(
        actions.updateWithoutPost({
          type: "CLEAR_TRANSLATION_SERVICES_DATA",
          data: {},
        })
      );
    };
  }, [dispatch]);

  /**
   * This function is used to update the translation service data when user click on add field button
   * or change field value
   * @param {*} servicesObj Updated object
   */
  const updateServiceData = (servicesObj) => {
    servicesData.current = servicesObj;
  };

  /**
   * This function is called when the user tries to save, either by clicking on a side menu item
   * or by clicking one of the three save buttons. Calls addBtnName(). Read that function description for more details.
   * Calls the sendPayload() function afterwards.
   * @param {*} navObject An object from navigate.json or an event object from clicking the save buttons
   * @param {*} location Location object from useLocation(), either from Sidebar or SaveButtons component
   */
  const handleSubmit = (navObject, location) => {
    let payload = servicesData.current;

    navDataObj.current = addBtnName(navObject, navigationList, location);

    let params = {
      dispatch: dispatch,
      actionData: postTranslationServicesData,
      requestBody: payload,
      response: response,
      setResponse: setResponse,
      navObject: navDataObj.current,
      navigate: navigate,
    };

    sendPayload(params);
  };

  if (!styleProps) return null; // Needed so app does not crash on first render
  return (
    <Styled styleProps={styleProps}>
      {response?.show && (
        <ShowResponse
          headerText={response?.headerText}
          message={response?.message}
          show={response?.show}
          handleCancel={() =>
            handleCancelPopUpBtn(
              response,
              setResponse,
              navDataObj.current,
              navigate
            )
          }
          handleOkay={() => handleOkayPopUpBtn(response, setResponse)}
        />
      )}
      <SubPage properties={properties} handleSideMenuSave={handleSubmit}>
        <div
          id="translation-services-body"
          className="form-body-container space-between-text"
        >
          <Services onServiceChange={updateServiceData} />

          <SaveButtons
            saveGoBack={handleSubmit}
            saveExit={handleSubmit}
            saveContinue={handleSubmit}
          />
        </div>
      </SubPage>
    </Styled>
  );
};

export default ScrollToTop(TranslationServices);
