import React, { useState, useEffect, useCallback } from "react";
import Styled from "./styles";
import { useSelector } from "react-redux";
import { TextField } from "@mui/material";
import { handleObjectChange } from "../../../../../utils/Utils";

const PhysicianName = ({
  physicianNameData = undefined,
  fieldNumber,
  physicianNumber,
  onPhysicianNameChange,
}) => {
  const [physicianName, setPhysicianName] = useState({
    name: "",
    fieldNumber: fieldNumber,
    physicianNumber: physicianNumber,
  });

  const { data: styleProps } = useSelector(
    ({ getStyleProps }) => getStyleProps
  );

  /**
   * Used to set the initial local state for the current Field of Practice being rendered.
   * It uses the data passed in from the parent and adds one more prop called fieldNumber.
   * fieldNumber is needed for the logic of adding, removing, and updating of that data in the field.
   * Note: Initial fields will have all the column attributes from the initial GET request. New fields will have filtered list since that is all that is needed.
   */
  const setDefaultData = useCallback(() => {
    setPhysicianName({
      ...physicianNameData,
      fieldNumber: fieldNumber,
      physicianNumber: physicianNumber,
    });
  }, [physicianNameData, fieldNumber, physicianNumber]);

  useEffect(() => {
    if (physicianName) {
      onPhysicianNameChange(physicianName);
    }
  }, [physicianName, onPhysicianNameChange]);

  useEffect(() => {
    if (physicianNameData) setDefaultData();
  }, [physicianNameData, setDefaultData]);

  return (
    <Styled styleProps={styleProps}>
      <TextField
        id={`physician-name-${fieldNumber}-${physicianNumber}`}
        className="physician-name-spacing"
        label="Physician Name"
        placeholder="List the names of the physcians in your practice group:"
        fullWidth
        InputLabelProps={{ shrink: true }}
        value={physicianName?.name}
        onChange={(e) => {
          handleObjectChange(
            e.target.value,
            "name",
            physicianName,
            setPhysicianName
          );
        }}
      />
    </Styled>
  );
};

export default PhysicianName;
