import styled from "styled-components/macro";

export default styled.div`
  .header-text {
    color: var(--black_1);
  }

  .sections .MuiTypography-body1 {
    line-height: var(--line_height_26px);
  }

  .responsive-nav-btn.MuiIconButton-root {
    display: none;
  }

  a {
    color: var(--blue_1);
  }

  .nav {
    box-shadow: ${({ isHelpFile }) => {
      if (isHelpFile) return "none";
    }};
  }

  @media only screen and (max-width: ${({ styleProps }) =>
      styleProps?.screenSize?.tablet}) {
    .responsive-nav-btn.MuiIconButton-root {
      display: block;
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 1000;
    }
    .MuiSvgIcon-root {
      height: 35px;
      width: 35px;
    }
    .MuiToolbar-regular.MuiToolbar-root.nav-toolbar {
      display: none;
    }
    .MuiToolbar-regular.MuiToolbar-root.responsive-nav-toolbar.nav-toolbar {
      display: block;
    }
  }

  .nav-toolbar {
    display: block;
  }

  .MuiToolbar-regular.MuiToolbar-root.responsive-nav-toolbar {
    display: none;
  }

  .nav {
    background-color: var(--white_1);
  }

  .logo-link {
    margin: 8px;
    margin-left: 50px;
    width: 109px;
    height: auto;
  }
  .MuiToolbar-root.nav-toolbar {
    padding: 0 !important;
    display: flex;
    align-items: stretch;
    width: 100%;
    justify-content: space-between;
  }

  .nav-toolbar > .sections:first-of-type {
    padding: 10px 0;
    flex-basis: 20%;
    min-width: 200px;
    border-right: 1px solid #d9d9d7;
  }

  .nav-toolbar > .sections:nth-of-type(2) {
    /* flex-basis: 62%; */
    display: flex;
    align-items: center;
    padding: 10px;
  }

  .nav-toolbar > button.sections:last-of-type {
    flex-basis: 16%;
    min-width: 200px;
    max-width: 205px;
    padding: 10px;
    background-color: var(--yellow_1);
    border: 0;
    border-radius: 0;
    outline: 0;
    font-size: var(--font_size_20px);
    color: var(--black_1);
    text-align: center;
    font-weight: 800;
    line-height: var(--line_height_22_5px);
    text-transform: none;
  }
  .skip-to-main {
    color: transparent;
    font-size: var(--font_size_10px);
    position: absolute;
  }
  .skip-to-main:focus {
    color: var(--white_1);
    background-color: var(--blue_1);
  }
`;
