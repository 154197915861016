import React from "react";
import Styled from "./styles";
import { useSelector } from "react-redux";
import ScrollToTop from "../../Common/HOC/ScrollToTop";
import SubPage from "../../Common/SubPage";
import SaveButtons from "../../Common/SaveButtons";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

const ThankYou = () => {
  const properties = {
    titlePage: "THANK YOU",
    showInstructions: false,
  };

  const navigate = useNavigate();

  const { data: styleProps } = useSelector(
    ({ getStyleProps }) => getStyleProps
  );

  const {data: mailingKey}  = useSelector(
    ({ getMailingKey }) => getMailingKey
  );

  if (!styleProps) return null; // Needed so app does not crash on first render
  return (
    <Styled styleProps={styleProps}>
      <SubPage properties={properties}>
        <div
          id="thankyou-form-body"
          className="form-body-container space-between-text"
        >
          <div id="thankyou-container">
            <div id="thankyou-header">
              <h4>Thank you</h4>
              {

                mailingKey == null ? (
                  <p>
                    You did not sign your profile and pressed the cancel option on
                    the signature section of the finish page. You must sign your
                    profile for it to be considered complete. Please return to the
                    profile and complete the signature section and the finish page.
                  </p>
                ) :
                  (
                    <>
                      <p> Your physician Profile is now complete.</p>
                      <p> Your confirmation number is: {mailingKey}</p>
                      <p> Please continue to carefully review your profile information and enter changes and updates as needed. Updates to the mandatory information are required to be made within 30 days.</p>
                      <p>Thank you very much for helping make New York's Physician Profile as accurate and complete as possible.</p>
                    </>
                  )
              }
              <p>
                To access your profile again you must log in to HPN using the
                following Web Address:
              </p>
              <p>
                <a
                  href="http://commerce.health.state.ny.us/hpn"
                  rel="noreferrer"
                  target="_blank"
                >
                  http://commerce.health.state.ny.us/hpn
                </a>
              </p>
              <p>
                We would appreciate it very much if you would please take a few
                moments of your time and <Link to="/questionnaire"> fill out a brief questionnaire</Link>. The
                information you provide will help us determine what areas if any
                need improvement. Click on the <strong>Questionnaire</strong>{" "}
                button below to proceed to the questionnaire. If you do not wish
                to complete the questionnaire you may exit the system by using
                the <strong>Exit</strong> button below.
              </p>
              <SaveButtons
                saveGoBack={() => navigate("/logoff")}
                saveContinue={() => navigate("/questionnaire")}
                buttonsProp={{
                  saveGoBack: {
                    show: true,
                    class: "min-width-200 margin-right-30",
                  },
                  saveContinue: { show: true, class: "min-width-200" },
                }}
                goBackButtonName="EXIT"
                continueButtonName="QUESTIONNAIRE"
              />
            </div>
          </div>
        </div>
      </SubPage>
    </Styled>
  );
};

export default ScrollToTop(ThankYou);
