import styled from "styled-components/macro";

export default styled.div`
  .sub-page-header-container {
    background-color: var(--white_2);
    letter-spacing: 0;
  }

  .sub-headers {
    padding: 25px 83px 25px 83px;
    @media only screen and (max-width: ${({ styleProps }) =>
        styleProps?.screenSize?.mobile}) {
      padding: 25px 35px 25px 35px;
    }
  }

  .title-blue {
    color: var(--blue_1);
    font-size: var(--font_size_25px);
    font-weight: 800;
    font-family: "Oswald" !important;
  }

  .title-black {
    font-size: var(--font_size_50px);
    color: var(--black_1);
    font-weight: 800;
    line-height: var(--line_height_45px);
  }
`;
