import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAnalyticsUrl } from "../../../utils/ModuleActions";
import * as actions from "../../../redux/actions";

export default function Analytics()
{
    const dispatch = useDispatch();
    const { data: analyticsUrl } = useSelector(
        ({ analytics }) => analytics
      );
    
      useEffect(() => {
        if (analyticsUrl === undefined || analyticsUrl === null) {  
            dispatch(actions.commonAction(getAnalyticsUrl));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      },[]);
    
      useEffect(() => {
        let finalAnalyticsUrl = analyticsUrl?.data?.analyticsUrl;
       
        if (finalAnalyticsUrl !== undefined && analyticsUrl !== null){
            let script_component = document.getElementById("analyticsScript");
            if(script_component === null){
                script_component = document.createElement('script');
                script_component.setAttribute('src',finalAnalyticsUrl);
                script_component.setAttribute('id','analyticsScript');
                script_component.setAttribute('async',true);
                document.head.appendChild(script_component);
            }
        }

      }, [analyticsUrl]);
}