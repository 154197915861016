import { useEffect } from "react";
import Styled from "./styles";
import ScrollToTop from "../../Common/HOC/ScrollToTop";
import SubPage from "../../Common/SubPage";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../redux/actions";
import { login } from "../../../utils/ModuleActions";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";

const Login = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const properties = {
    titlePage: "Loading",
  };

  const dispatch = useDispatch();
  const { data: styleProps } = useSelector(
    ({ getStyleProps }) => getStyleProps
  );

  const { data: loginToWelcome } = useSelector(({ login }) => login);

  useEffect(() => {
    if (loginToWelcome) {
      let token = loginToWelcome?.data?.token;
      if (token) {
        navigate({ pathname: "/welcome" });
        return;
      }
    }
  }, [loginToWelcome, navigate]);

  useEffect(() => {
    let token = localStorage.getItem("authToken");
    if (token) {
      navigate({ pathname: "/welcome" });
      return;
    }

    const code = searchParams.get("code");
    let code_verifier = localStorage.getItem("code_verifier");

    let reqData = {
      code: code,
      code_verifier: code_verifier,
    };
    let attemptCount = sessionStorage.getItem("attemptCount");
    if (attemptCount === "1") {
      dispatch(actions.commonAction(login, reqData));
      sessionStorage.setItem("attemptCount", "2");
    } else {
      console.warn("This is a second attempt. Let's stop it.");
    }
  }, [dispatch, searchParams, navigate]);

  return (
    <Styled styleProps={styleProps}>
      <SubPage properties={properties}>
        <div id="instruction-guide" className="space-between-text">
          <h4>Please wait!</h4>
          <CircularProgress />
          <p>
            Please wait a moment, the application is loading.
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </p>
        </div>
      </SubPage>
    </Styled>
  );
};
export default ScrollToTop(Login);
