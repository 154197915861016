import styled from "styled-components/macro";

export default styled.div`
  .space-between-text {
    margin-top: 50px;
    h4 {
      line-height: var(--line_height_26px);
    }
    a {
      word-break: break-all;
    }
  }
  .sub-box-info-container table tr td {
    white-space: nowrap;
  }
`;
