import React from "react";
import Styled from "./styles";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import CustomRadio from "../../../Common/CustomRadio";

const Actions = ({ onActionsChange, oldAction, OPMCActions = [] }) => {
  const [isAction, setIsAction] = useState("");

  useEffect(() => {
    onActionsChange(isAction);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAction]);

  useEffect(() => {
    if (oldAction) setIsAction(oldAction);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oldAction]);

  const { data: styleProps } = useSelector(
    ({ getStyleProps }) => getStyleProps
  );

  return (
    <Styled styleProps={styleProps}>
      <div id="actions-container" className="space-between-text">
        <div id="new-york-actions-container" className="action">
          <div id="new-york-actions-header">
            <h4>A. New York Licensee Actions</h4>
            <p>
              Any action taken by the New York State Board of Professional
              Medical Conduct against your license within the past 10 years must
              be available on your profile.
            </p>
            {OPMCActions?.length > 0 && (
              <>
                <p>
                  Please review the below information which reports the date of
                  Board action, the nature of the action and a summary of the
                  misconduct.
                </p>
                <p>
                  Please contact the Physician Help Desk at{" "}
                  <strong className="help-desk-number">1-888-338-6998</strong>{" "}
                  to modify or dispute a New York State action.
                </p>
              </>
            )}
            {OPMCActions?.length === 0 && (
              <p className="instruction-text">
                There is no record of any action taken against your license by
                the NY Board for Professional Misconduct.
              </p>
            )}
          </div>
          {OPMCActions?.length > 0 &&
            OPMCActions.map((action, index) => {
              return (
                <div
                  id="new-york-actions-body"
                  className="sub-box-info-container md-space-between-containers"
                  key={index}
                >
                  <table>
                    <thead>
                      <tr>
                        <th colSpan={2}>
                          <p>New York Licensee Action {index + 1}</p>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Date of action:</td>
                        <td>
                          {dayjs(action?.actionDate).format("MM-DD-YYYY")}
                        </td>
                      </tr>
                      <tr>
                        <td>Action taken:</td>
                        <td> {action?.finalActionText}</td>
                      </tr>
                      <tr>
                        <td>Summary of misconduct:</td>
                        <td>{action?.actionSummary}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              );
            })}
        </div>

        <div
          id="out-of-state-actions-container"
          className="md-space-between-containers"
        >
          <div id="out-of-state-actions-header">
            <h4>B. Out-of-State License Actions/Restrictions</h4>
            <p>
              Any change to previously entered information will be effective
              immediately.
            </p>
          </div>
          <div
            id="out-of-state-actions-body"
            className="md-space-between-containers"
          >
            <CustomRadio
              value={isAction}
              formLabel="Have any actions been taken against you as a result of
                professional misconduct proceedings by any other state or
                licensing entity within the past 10 years?"
              onChangeCustom={(e) => setIsAction(e?.target?.value)}
              ariaLabel="any-actions-taken"
            />
            <p>
              If yes, list the state, date, action taken, and summary of
              misconduct. Please contact the Physician Help Desk at{" "}
              <strong className="help-desk-number">1-888-338-6998</strong> to
              modify or dispute an Out of State action.
            </p>
            <p>
              Click the <strong>Add</strong> button below if you need to enter
              additional Out-of-State Licensee Actions.
            </p>
          </div>
        </div>
      </div>
    </Styled>
  );
};

export default Actions;
