import { useState, useEffect } from "react";
import Styled from "./styles";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { Checkbox, FormControlLabel, FormGroup, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import {
  handleDropdownList,
  handleObjectChange,
} from "../../../../utils/Utils";
import CustomPopper from "../../../Common/CustomPopper";

export default function Subspeciality({
  fieldNumber,
  onSubspecialityChange = () => {},
  subspecialityData = undefined,
}) {
  const [loading, setLoading] = useState(true);
  const [newSubspeciality, setNewSubspeciality] = useState({
    subCertificationCode: null,
    certification_Description: null,
    certificationDate: null,
    expirationDate: null,
    deleteIndc: null,
    fieldNumber: fieldNumber,
  });

  const [subspecialityOptions, setSubspecialityOptions] = useState([]);
  const { data: styleProps } = useSelector(
    ({ getStyleProps }) => getStyleProps
  );
  const { data: certificationDataList } = useSelector(
    ({ getCertification }) => getCertification
  );

  useEffect(() => {
    if (newSubspeciality) onSubspecialityChange(newSubspeciality);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newSubspeciality]);

  useEffect(() => {
    if (subspecialityData) {
      let subspecialityOptions =
        certificationDataList?.data?.subCertifications || [];
      const defaultSubspeciality = subspecialityOptions.find(
        (option) => option?.value === subspecialityData?.subCertificationCode
      );
      let subspecialityClone = { ...subspecialityData };
      if (defaultSubspeciality?.value) {
        subspecialityClone = {
          ...subspecialityClone,
          certification_Description: defaultSubspeciality?.description,
        };
      }
      setNewSubspeciality({ ...newSubspeciality, ...subspecialityClone });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subspecialityData]);

  useEffect(() => {
    if (certificationDataList) {
      const { subCertifications: subspecialityOptions } =
        certificationDataList?.data;
      if (Array.isArray(subspecialityOptions)) {
        setSubspecialityOptions(subspecialityOptions);
      }
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [certificationDataList]);

  return (
    <Styled styleProps={styleProps}>
      {fieldNumber > 1 && <hr />}
      <div id={`sub-item-${fieldNumber}`}>
        <div id={`sub-item-header-${fieldNumber}`}>
          <h4>Subspeciality Item {fieldNumber}</h4>
        </div>
        <Grid
          id={`sub-item-body-${fieldNumber}`}
          container
          columnSpacing={3}
          rowSpacing={0}
        >
          <Grid item xs={12} sm={12}>
            <FormGroup>
              <FormControlLabel
                style={{ pointerEvents: "none" }}
                control={
                  <Checkbox
                    className="checkbox"
                    style={{ pointerEvents: "auto" }}
                    value="Y"
                    onChange={(e) =>
                      handleObjectChange(
                        e?.target?.value,
                        "deleteIndc",
                        newSubspeciality,
                        setNewSubspeciality
                      )
                    }
                  />
                }
                className="checkbox-label error-label "
                label="Erroneous Report, please call 1-888-338-6998"
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Autocomplete
              id={`subspeciality-${fieldNumber}`}
              name={`subspeciality-${fieldNumber}`}
              options={subspecialityOptions}
              getOptionLabel={(option) => option?.description}
              renderOption={(props, option) =>
                handleDropdownList(props, option.value, option.description)
              }
              isOptionEqualToValue={(option, value) =>
                option?.value === value?.value
              }
              PopperComponent={(props) => <CustomPopper {...props} />}
              value={
                newSubspeciality?.subCertificationCode &&
                subspecialityOptions?.length > 0
                  ? subspecialityOptions.find(
                      (option) =>
                        option.value === newSubspeciality?.subCertificationCode
                    )
                  : null
              }
              onChange={(e, value) =>
                setNewSubspeciality({
                  ...newSubspeciality,
                  subCertificationCode: value?.value,
                  certification_Description: value?.description,
                })
              }
              loading={loading}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select a subspeciality"
                  label="Subspeciality"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Certification Date (MM-DD-YYYY)"
                inputFormat="MM-DD-YYYY"
                name={`certi-date-${fieldNumber}`}
                value={
                  newSubspeciality?.certificationDate
                    ? dayjs(newSubspeciality?.certificationDate)
                    : null
                }
                onChange={(date) =>
                  handleObjectChange(
                    date ? dayjs(date).format("YYYY-MM-DD") : null,
                    "certificationDate",
                    newSubspeciality,
                    setNewSubspeciality
                  )
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: "MM-DD-YYYY",
                    }}
                  />
                )}
                className="datepicker"
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Expiration Date (MM-DD-YYYY)"
                inputFormat="MM-DD-YYYY"
                name={`expiryDate-date-${fieldNumber}`}
                value={
                  newSubspeciality?.expirationDate
                    ? dayjs(newSubspeciality?.expirationDate)
                    : null
                }
                onChange={(date) =>
                  handleObjectChange(
                    date ? dayjs(date).format("YYYY-MM-DD") : null,
                    "expirationDate",
                    newSubspeciality,
                    setNewSubspeciality
                  )
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: "MM-DD-YYYY",
                    }}
                  />
                )}
                className="datepicker"
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </div>
    </Styled>
  );
}
