import styled from "styled-components/macro";

export default styled.div`
  div.services-body .MuiButtonBase-root.MuiCheckbox-root {
    padding: 5px;
  }

  .space-between-text {
    // Need to override here since structure is unique on this page
    p:last-of-type {
      margin-top: 15px;
    }

    // Needed to override p:last-of-type margin-bottom of 0 since this <p> tag needed 8px while the other last p tag did not. This is an exception
    .before-checkboxes {
      margin-bottom: 8px;
    }
  }
`;
